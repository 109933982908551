import { styled } from '@mui/material'

export const Bold = styled('span')({
  fontWeight: 700,
})

export const Uppercase = styled('span')({
  textTransform: 'uppercase',
})

export const HrStyled = styled('hr')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTopWidth: '0px',
}))
