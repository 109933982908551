import React from 'react'
import { Box, Card, CardActionArea, CardContent, Radio, Stack, Typography, useRadioGroup } from '@mui/material'

type RadioTileProps = {
  value: string
  label: string
  description?: string
  checked?: boolean
  disabled?: boolean
  onClick?: () => void
  Icon?: React.ElementType
}

const RadioTile = ({ value, label, description, checked, disabled, onClick, Icon }: RadioTileProps) => {
  const radioGroup = useRadioGroup()
  const isChecked = checked ?? radioGroup?.value === value

  return (
    <Card
      variant='outlined'
      sx={{
        boxShadow: 'none',
        height: '100%',
        width: '100%',
        ...(isChecked && {
          border: ({ palette }) => `2px solid ${palette.text.primary}`,
          background: ({ palette }) => palette.background.default,
        }),
      }}
    >
      <CardActionArea onClick={onClick} disabled={disabled} sx={{ height: '100%' }}>
        <CardContent sx={{ height: '100%' }}>
          <Stack direction='row' useFlexGap flex='1 0 0'>
            <Stack direction='row' spacing={1} useFlexGap flex='1 0 0'>
              {Icon ? (
                <Box pt={0.25}>
                  <Icon sx={{ fontSize: 24 }} />
                </Box>
              ) : null}
              <Stack>
                <Typography
                  variant='subtitle2'
                  fontWeight='medium'
                  sx={{
                    ...(disabled && {
                      opacity: ({ palette }) => palette.action.disabledOpacity,
                    }),
                  }}
                >
                  {label}
                </Typography>
                {description && (
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{
                      ...(disabled && {
                        opacity: ({ palette }) => palette.action.disabledOpacity,
                      }),
                    }}
                  >
                    {description}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <div>
              <Radio
                checked={isChecked}
                onChange={(e) => radioGroup?.onChange(e, value)}
                inputProps={{
                  'aria-label': label,
                }}
                disabled={disabled}
              />
            </div>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default RadioTile
