import { ReactNode } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { SelectChangeEvent } from '@mui/material'

import Select, { type SelectProps } from 'Components/Select'

import type { FormField } from './types'

type OptionValue = string | number
export type FormSelectProps<T extends OptionValue> = FormField & Omit<SelectProps<T>, 'value'>

export const FormSelect = <T extends OptionValue>(props: FormSelectProps<T>) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ name: props.name, control })

  return (
    <Select
      {...props}
      value={value}
      onChange={(event: SelectChangeEvent<T>, child: ReactNode) => {
        onChange(event)
        props.onChange?.(event, child)
      }}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
    />
  )
}
