export const OrderStatuses = {
  NotStarted: 'NotStarted',
  Started: 'Started',
  Stopped: 'Stopped',
  Ended: 'Ended',
} as const

type OrderStatusesKeys = keyof typeof OrderStatuses
export type OrderStatus = (typeof OrderStatuses)[OrderStatusesKeys]

export const OrderStatusesTranslationKeys = {
  [OrderStatuses.NotStarted as string]: 'values.notStarted',
  [OrderStatuses.Started as string]: 'values.started',
  [OrderStatuses.Stopped as string]: 'values.stopped',
  [OrderStatuses.Ended as string]: 'values.ended',
} as const
