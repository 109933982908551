import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const WorkstationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d='M11.9 9.19977C12.404 9.19977 12.8 8.80377 12.8 8.29977V8.17377L15.374 10.7478C15.644 11.0178 16.04 11.0898 16.382 10.9278L21.278 8.64177C21.728 8.42577 21.926 7.90377 21.71 7.45377C21.494 7.00377 20.972 6.80577 20.522 7.02177L16.202 9.03777L12.8 5.61777V5.58177L16.202 2.17977L20.522 4.19577C20.972 4.41177 21.512 4.21377 21.71 3.76377C21.926 3.31377 21.728 2.77377 21.278 2.57577L16.382 0.289773C16.04 0.127773 15.644 0.199773 15.374 0.469773L12.8 3.02577V2.89977C12.8 2.39577 12.404 1.99977 11.9 1.99977C11.396 1.99977 11 2.39577 11 2.89977V4.69977H7.27396C6.80596 2.89977 4.98796 1.62177 2.95396 2.08977C1.63996 2.39577 0.577959 3.47577 0.289959 4.77177C-0.106041 6.60777 0.865959 8.24577 2.37796 8.91177L4.69996 18.1998H3.79996C2.80996 18.1998 1.99996 19.0098 1.99996 19.9998V21.7998H16.4V19.9998C16.4 19.0098 15.59 18.1998 14.6 18.1998H12.548L6.60796 7.81377C6.91396 7.43577 7.14796 6.98577 7.27396 6.49977H11V8.29977C11 8.80377 11.396 9.19977 11.9 9.19977ZM3.79996 7.39977C2.80996 7.39977 1.99996 6.58977 1.99996 5.59977C1.99996 4.60977 2.80996 3.79977 3.79996 3.79977C4.78996 3.79977 5.59996 4.60977 5.59996 5.59977C5.59996 6.58977 4.78996 7.39977 3.79996 7.39977ZM9.88396 18.1998H7.07596L4.96996 9.70377H5.04196L9.88396 18.1998Z' />
    </SvgIcon>
  )
}

export default WorkstationIcon
