import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Dialog } from '@mui/material'

import AuthManager from 'Auth/AuthManager'
import { DialogActions, DialogTitle } from 'Components/Dialog'
import HubMethods from 'Consts/HubMethods'
import { LocalStorageKeys } from 'Consts/LocalStorageKeys'
import { useMachineContext } from 'Context/MachineContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'
import { RfidCardAppliedMessage } from 'Types'

interface Props {
  open: boolean
  onClose: () => void
}

const EndWorkDialog: React.FC<Props> = ({ open, onClose }) => {
  const history = useHistory()
  const { hubConnection } = useSignalRContext()
  const { t } = useTranslation()
  const { user } = useUserContext()
  const { machine } = useMachineContext()
  const machineId = machine?.id

  useEffect(() => {
    const handleRfidCardApplied = ({ rfid, isValid, workstationId }: RfidCardAppliedMessage) => {
      if (open && isValid && user && user.rfid === rfid && machineId === workstationId) {
        AuthManager.logout().then(() => {
          localStorage.removeItem(LocalStorageKeys.REDIRECT_PATH)
          history.push(paths.rfidLogin)
          onClose()
        })
      }
    }

    hubConnection?.on(HubMethods.RfidCardApplied, handleRfidCardApplied)

    return () => {
      hubConnection?.off(HubMethods.RfidCardApplied, handleRfidCardApplied)
    }
  }, [hubConnection, open, user, machineId])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <Trans i18nKey='messages.removeYourCardFromTheReader'>
          Remove your card from reader,
          <div style={{ fontWeight: 400 }}>to finish your work</div>
        </Trans>
      </DialogTitle>
      <DialogActions actionsVariant='center'>
        <Button variant='text' onClick={onClose}>
          {t('labels.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EndWorkDialog
