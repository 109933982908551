import { useController, useFormContext } from 'react-hook-form'
import { Box, Typography } from '@mui/material'

import ManualNumericControl, { ManualNumericControlProps } from 'Components/NumericControl/ManualNumericControl'

import { FieldLabelInfoWrapper } from '../form-layout'
import type { FormField } from './types'

export type FormManualNumericControl = FormField & Omit<ManualNumericControlProps, 'name' | 'defaultValue'>

export const FormManualNumericControl = (
  props: FormManualNumericControl & { label?: string; helperText?: string; tooltipInfoText?: string },
) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ name: props.name, control })

  return (
    <Box sx={{ width: '100%' }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        {props.label && (
          <FieldLabelInfoWrapper tooltip={props.tooltipInfoText}>
            <Typography sx={{ pr: 1 }}>{props.label}</Typography>
          </FieldLabelInfoWrapper>
        )}
        <ManualNumericControl
          {...props}
          defaultValue={value}
          onChange={(value) => {
            onChange(value)
            props.onChange?.(value)
          }}
        />
      </Box>
      <Typography color={fieldState.invalid ? 'error' : 'text.secondary'} variant='caption'>
        {fieldState.invalid ? fieldState.error?.message : props.helperText}
      </Typography>
    </Box>
  )
}
