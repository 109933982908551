import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const MaintenanceDashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M76 12H20C15.6 12 12 15.6 12 20V76C12 80.4 15.6 84 20 84H76C80.4 84 84 80.4 84 76V20C84 15.6 80.4 12 76 12ZM20 76V20H44V76H20ZM76 76H52V48H76V76ZM76 40H52V20H76V40Z' />
    </SvgIcon>
  )
}

export default MaintenanceDashboardIcon
