import { useCallback } from 'react'

const useFocus = () => {
  const focusRef = useCallback((node) => {
    if (node !== null) {
      node.focus()
    }
  }, [])

  return focusRef
}

export default useFocus
