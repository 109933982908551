import React from 'react'
import { useTranslation } from 'react-i18next'
import { Error as ErrorIcon } from '@mui/icons-material'
import { Box, Chip, Divider, Grid, Stack, Typography } from '@mui/material'
import { getIsTechnologyComponentCompletable } from 'Features/technologies'

type Props = {
  components: {
    name: string
    amount: number
    machines: {
      name: string
      isActive: boolean
      isDeleted: boolean
    }[]
  }[]
}

const TechnologyPreview = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      rowGap={2}
      sx={{
        padding: 1,
        paddingY: 2,
        backgroundColor: ({ palette }) => palette.background.default,
      }}
    >
      <Grid item xs={6}>
        <Typography textTransform='uppercase' fontWeight='medium' variant='body2'>
          {t('labels.technologyComponents')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography textTransform='uppercase' fontWeight='medium' variant='body2'>
          {t('labels.workstations')}
        </Typography>
      </Grid>
      {props.components.map((component, index) => {
        const isLastItem = index === props.components.length - 1
        const isCompletable = getIsTechnologyComponentCompletable({ machines: component.machines })

        return (
          <>
            <Grid item xs={6}>
              <Box display='flex' alignItems='center' height='100%'>
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  {component.amount}x{' '}
                  <Typography component='span' fontWeight='bold' sx={{ marginLeft: 2 }}>
                    {component.name}
                  </Typography>
                  {!isCompletable && <ErrorIcon color='error' fontSize='inherit' sx={{ ml: 1 }} />}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} direction='row' flexWrap='wrap' useFlexGap>
                {component.machines.map(({ name, isDeleted, isActive }) => {
                  if (isDeleted) {
                    return (
                      <Chip
                        key={name}
                        label={`${name} (${t('labels.deleted', { context: 'workstation' })})`}
                        variant='outlined'
                        color={isCompletable ? 'default' : 'error'}
                      />
                    )
                  }
                  if (!isActive) {
                    return (
                      <Chip
                        key={name}
                        label={`${name} (${t('labels.inactive', { context: 'workstation' })})`}
                        variant='outlined'
                        color={isCompletable ? 'default' : 'error'}
                      />
                    )
                  }

                  return <Chip key={name} label={name} />
                })}
              </Stack>
            </Grid>
            {!isLastItem && (
              <Grid item xs={12}>
                <Divider component='div' />
              </Grid>
            )}
          </>
        )
      })}
    </Grid>
  )
}

export default TechnologyPreview
