import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import AppNames from 'Consts/AppNames'
import { useMachineContext } from 'Context/MachineContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'

const PairedMachineWithDeviceHandler = () => {
  const { machine } = useMachineContext()
  const { user, selectedApp } = useUserContext()
  const { location } = useHistory()

  if (selectedApp === AppNames.OperatorPanel && location.pathname !== paths.operatorPairWithMachine) {
    if (user || location.pathname === paths.rfidLogin || location.pathname === paths.root) {
      if (machine?.removed && location.pathname !== paths.machineRemoved) {
        return <Redirect to={paths.machineRemoved} />
      }

      if (machine && !machine?.active && location.pathname !== paths.machineDeactivated) {
        return <Redirect to={paths.machineDeactivated} />
      }
    }

    if (!machine && ![paths.setMachine, paths.rfidLogin].includes(location.pathname)) {
      return <Redirect to={paths.setMachine} />
    }
  }

  return null
}

export default PairedMachineWithDeviceHandler
