import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material'
import {
  Box,
  Button as MaterialButton,
  Dialog,
  DialogContent,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { usePinch } from '@use-gesture/react'
import { format } from 'date-fns'

import { DialogActions, DialogTitle } from 'Components/Dialog'
import PdfDocument from 'Components/PdfDocument/PdfDocument'

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  fontFamily: 'Roboto',
  justifyContent: 'space-around',
})

const DialogContentGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(10, 1fr)',
  gridTemplateRows: 'repeat(10, 1fr)',
  height: '100%',
  width: '100%',
})

const FileContent = styled('div')({
  touchAction: 'none',
  gridColumn: 'span 9 / span 8',
  gridRow: 'span 10 / span 10',
  gridColumnStart: 2,
  gridRowStart: 1,
  '--tw-bg-opacity': 0.05,
  backgroundColor: 'rgba(22, 22, 21, var(--tw-bg-opacity))',
  '& canvas': {
    margin: 'auto',
  },
})

const ZoomStyled = styled('div')(({ row }: { row: number }) => ({
  gridRowStart: row,
  gridColumnStart: 10,
  alignSelf: 'flex-end',
  textAlign: 'center',
}))

const ZoomInStyled = styled(ZoomInIcon)(({ theme }) => ({
  width: '1em',
  height: '1em',
  [theme.breakpoints.up('xxl')]: {
    width: '2em',
    height: '2em',
  },
}))

const ZoomOutStyled = styled(ZoomOutIcon)(({ theme }) => ({
  width: '1em',
  height: '1em',
  [theme.breakpoints.up('xxl')]: {
    width: '2em',
    height: '2em',
  },
}))

const Button = styled(MaterialButton)(({ theme }) => ({
  fontSize: '1.25rem',
  height: '52px',
  width: '308px',
  borderRadius: '16px',
  [theme.breakpoints.up('xxl')]: {
    fontSize: '2.5rem',
    borderRadius: '32px',
    height: '104px',
    width: '616px',
  },
}))

type File = {
  id: string
  name: string
  fileUrl: string | null
  expirationDate?: string | null
  typeId?: number
}

interface Props {
  open?: boolean
  file?: File | null
  disableConfirm?: boolean
  confirmButtonLabel?: string
  onClose?: () => void
  onConfirm?: () => void
  width?: string | number
}

const PdfFileDialog: React.FC<Props> = ({
  open = false,
  file = null,
  disableConfirm = false,
  onClose,
  onConfirm,
  confirmButtonLabel,
  width,
}) => {
  const [scale, setScale] = useState(1)
  const [isDocumentLoading, setDocumentLoading] = useState(false)
  const fileContentRef = useRef<HTMLDivElement | null>(null)
  const {
    breakpoints: { up },
  } = useTheme()
  const matchesXxl = useMediaQuery(up('xxl'))
  const { t } = useTranslation()

  usePinch(
    // @ts-ignore
    ({ offset: [distance] }) => {
      setScale(distance)
    },
    {
      target: fileContentRef,
      eventOptions: { passive: false },
    },
  )

  useEffect(() => {
    if (matchesXxl) {
      setScale(2)
    } else {
      setScale(1)
    }
  }, [matchesXxl])

  useEffect(() => {
    if (file) {
      setDocumentLoading(true)
    }
  }, [file?.fileUrl])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }
  }

  return (
    <Container>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            borderRadius: '20px',
            height: '83vh',
            minWidth: width ?? '83.203125vw',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle disableTypography>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex'>
              <Typography variant='h5' fontWeight='medium' pr={1}>
                {t('labels.file')}:
              </Typography>
              <Typography variant='h5' noWrap>
                {file?.name}
              </Typography>
            </Box>
            {file?.expirationDate && (
              <Box display='flex'>
                <Typography variant='h6' fontWeight='medium' pr={1}>
                  {t('labels.expirationDate')}:
                </Typography>
                <Typography variant='h6' noWrap>
                  {format(new Date(file?.expirationDate), 'dd-MM-yyyy')}
                </Typography>
              </Box>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentGrid>
            <FileContent ref={fileContentRef}>
              {file?.fileUrl && (
                <PdfDocument
                  file={file.fileUrl}
                  scale={scale}
                  onLoadSuccess={() => {
                    setDocumentLoading(false)
                  }}
                  onLoadError={() => {
                    setDocumentLoading(false)
                  }}
                />
              )}
            </FileContent>
            <ZoomStyled row={1}>
              <IconButton onClick={() => setScale((prev) => prev + 0.25)} disabled={scale > (matchesXxl ? 4 : 2)}>
                <ZoomInStyled />
              </IconButton>
            </ZoomStyled>
            <ZoomStyled row={2}>
              <IconButton onClick={() => setScale((prev) => prev - 0.25)} disabled={scale < (matchesXxl ? 1.5 : 0.75)}>
                <ZoomOutStyled />
              </IconButton>
            </ZoomStyled>
          </DialogContentGrid>
        </DialogContent>
        <DialogActions>
          <Box />
          <Button onClick={handleConfirm} disabled={isDocumentLoading || disableConfirm}>
            {confirmButtonLabel ?? t('labels.iConfirmTheReading')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default PdfFileDialog
