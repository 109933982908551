import { Buffer } from 'buffer'
import EventEmitter from 'events'
import Cookies from 'js-cookie'

import { authApiClient as apiClient } from 'Api/AuthApiClient'
import { DEFAULT_LANGUAGE_CODE } from 'Consts/LocaleLanguageCodes'
import { LocalStorageKeys } from 'Consts/LocalStorageKeys'
import { LoginMethods } from 'Consts/LoginMethods'
import Analytics from 'Lib/Analytics'
import ErrorTracker from 'Lib/error-tracker'
import paths from 'Routes/paths'
import { AppUser, PermissionName } from 'Types'

const SID = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'

type UserTokenData = {
  [SID]: string
  permissions: PermissionName[] | PermissionName
  loginMethod: 'rfid' | 'credentials'
  nbf: number
  exp: number
  iat: number
  iss: string
  aud: string
  userName: string
  rfid: string
  defaultLanguageCode?: string
  role?: 'SuperAdmin'
  nameid?: 'Operator'
}

export const AuthEvents = {
  UserLoaded: 'auth:userLoaded',
  UserUnloaded: 'auth:userUnloaded',
  UserSignedIn: 'auth:userSignedIn',
  UserSignedOut: 'auth:userSignedOut',
}
const USER_COOKIE_NAME = 'user'

export const AuthManagerErrors = {
  REQUEST_IN_PROGRESS: 'Request is already in progress.',
}

const mapTokenPermissions = (permissions: PermissionName[] | PermissionName) => {
  let result: PermissionName[] = []
  if (Array.isArray(permissions)) {
    result = permissions
  }
  if (typeof permissions === 'string') {
    result = [permissions]
  }

  return new Set(result)
}

const mapTokenToUser = (token: string) => {
  const tokenData: UserTokenData = JSON.parse(Buffer.from(token, 'base64').toString())

  const user = {
    defaultLanguageCode: tokenData.defaultLanguageCode ?? DEFAULT_LANGUAGE_CODE,
    expiring: tokenData.exp,
    permissions: mapTokenPermissions(tokenData.permissions),
    loginMethod: tokenData.loginMethod,
    rfid: tokenData.rfid,
    role: tokenData.role,
    uniqueId: tokenData[SID],
    userName: tokenData.userName,
  }

  return user
}

const mapUserToCookie = (user: AppUser) => {
  const userCookie = {
    ...user,
    permissions: Array.from(user.permissions),
  }

  return JSON.stringify(userCookie)
}

const mapCookieToUser = (cookie: string) => {
  const user = JSON.parse(cookie)

  return { ...user, permissions: mapTokenPermissions(user.permissions) }
}

const AuthManager = () => {
  let user: AppUser | null = null
  let isRefreshingToken = false
  let isLoggingIn = false
  let isLoggingOut = false
  let broadcastChannel: BroadcastChannel | null = null
  const events = new EventEmitter()

  const broadcastUserChangedMessage = () => {
    if (broadcastChannel) {
      broadcastChannel.postMessage({ type: 'user_changed' })
    }
  }

  const handleUserSignedIn = ({ data }: { data: string }) => {
    user = mapTokenToUser(data)
    Cookies.set(USER_COOKIE_NAME, mapUserToCookie(user))
    events.emit(AuthEvents.UserSignedIn, user)
    events.emit(AuthEvents.UserLoaded, user)
    Analytics.track.signIn({ loginMethod: user.loginMethod })
    Analytics.identify(user.uniqueId)
  }

  const handleUserSignedOut = () => {
    Analytics.track.signOut({ loginMethod: user?.loginMethod })
    Analytics.reset()
    Cookies.remove(USER_COOKIE_NAME)
    user = null
    events.emit(AuthEvents.UserSignedOut)
    events.emit(AuthEvents.UserUnloaded)
    broadcastUserChangedMessage()
  }

  const handleUserReplaced = ({ data }: { data: string }) => {
    Analytics.track.signOut({ loginMethod: user?.loginMethod })
    Analytics.reset()

    handleUserSignedIn({ data })
  }

  const handleTokenRefreshed = ({ data }: { data: string }) => {
    user = mapTokenToUser(data)
    Cookies.set(USER_COOKIE_NAME, mapUserToCookie(user))
    events.emit(AuthEvents.UserLoaded, user)
    broadcastUserChangedMessage()
  }

  const isTokenExpired = (timestamp: number) => {
    const now = new Date().getTime()
    const expiring = new Date(timestamp * 1000).getTime()

    return expiring < now
  }

  const getUserFromCookie = () => {
    const userCookie = Cookies.get(USER_COOKIE_NAME)

    if (userCookie) {
      const token = JSON.parse(userCookie)
      if (isTokenExpired(token.expiring)) {
        Cookies.remove(USER_COOKIE_NAME)

        return null
      }
      user = mapCookieToUser(userCookie)

      return user
    }

    return null
  }

  const handleUserCookieChanged = () => {
    user = getUserFromCookie()
    if (user) {
      events.emit(AuthEvents.UserLoaded, user)
    } else {
      events.emit(AuthEvents.UserUnloaded)
    }
  }

  const getUser = () => {
    if (user) {
      return user
    }

    return getUserFromCookie()
  }

  const credentialsLogin = async (userLogin: string, password: string, clientId: string) => {
    if (isLoggingIn) {
      throw new Error(AuthManagerErrors.REQUEST_IN_PROGRESS)
    }

    isLoggingIn = true

    await apiClient
      .credentialsLogin(userLogin, password, clientId)
      .then(handleUserSignedIn)
      .catch((error) => {
        ErrorTracker.captureMessage(error, {
          tags: {
            type: 'Authentication Error',
          },
          extra: {
            title: 'Error on login',
            method: 'AuthManager.login()',
          },
        })
        user = null
        events.emit(AuthEvents.UserUnloaded)
        throw error
      })
      .finally(() => {
        isLoggingIn = false
      })

    return user
  }

  const rfidLogin = async (workstationId: string, clientId: string, rfid: string) => {
    if (isLoggingIn) {
      throw new Error(AuthManagerErrors.REQUEST_IN_PROGRESS)
    }

    isLoggingIn = true

    await apiClient
      .rfidLogin(workstationId, clientId, rfid)
      .then(handleUserSignedIn)
      .catch((error) => {
        ErrorTracker.captureMessage(error, {
          tags: {
            type: 'Authentication Error',
          },
          extra: {
            title: 'Error on operatorLogin',
            method: 'AuthManager.operatorLogin()',
            workstation: localStorage.getItem('selectedMachine'),
          },
        })
        user = null
        events.emit(AuthEvents.UserUnloaded)
        throw error
      })
      .finally(() => {
        isLoggingIn = false
      })
  }

  const credentialsUserSwitch = async (data: { workstationId: string; login: string; password: string }) => {
    if (isLoggingIn) {
      throw new Error(AuthManagerErrors.REQUEST_IN_PROGRESS)
    }
    isLoggingIn = true

    await apiClient
      .reloginCredentialsMultiOperator(data)
      .then(handleUserReplaced)
      .finally(() => {
        isLoggingIn = false
      })

    return user
  }

  const userSwitch = async (data: { workstationId: string; userId: string }) => {
    if (isLoggingIn) {
      throw new Error(AuthManagerErrors.REQUEST_IN_PROGRESS)
    }

    isLoggingIn = true

    await apiClient
      .reloginMultiOperator(data)
      .then(handleUserReplaced)
      .finally(() => {
        isLoggingIn = false
      })

    return user
  }

  const logout = async () => {
    if (!user || isLoggingOut) {
      return Promise.resolve()
    }
    isLoggingOut = true

    return apiClient
      .logout()
      .then(handleUserSignedOut)
      .finally(() => {
        isLoggingOut = false
      })
  }

  const logoutAndRedirectToDefault = async () => {
    if (isLoggingOut) {
      return
    }
    const loginPreference = localStorage.getItem(LocalStorageKeys.LOGIN_PREFERENCE)
    const loginPath = loginPreference === LoginMethods.RFID ? paths.rfidLogin : paths.login

    try {
      await logout()
    } finally {
      window.location.assign(loginPath)
    }
  }

  const refreshToken = () => {
    if (!user || isRefreshingToken) {
      return Promise.resolve()
    }
    isRefreshingToken = true

    return apiClient
      .refresToken()
      .then(handleTokenRefreshed)
      .catch((error) => {
        ErrorTracker.captureMessage(error, {
          tags: {
            type: 'Authentication Error',
          },
          extra: {
            title: 'Error on refreshToken',
            method: 'AuthManager.refreshToken()',
          },
        })
        throw error
      })
      .finally(() => {
        isRefreshingToken = false
      })
  }

  const isAuthenticated = () => !!user

  const attachEvents = () => {
    if (broadcastChannel) {
      broadcastChannel.onmessage = (ev) => {
        if (ev.data.type === 'user_changed') {
          handleUserCookieChanged()
        }
      }
    }
  }

  const init = () => {
    if (typeof BroadcastChannel === 'function') {
      broadcastChannel = new BroadcastChannel('auth_manager_events')
    } else {
      ErrorTracker.captureMessage(
        `BroadcastChannel is not supported. AuthManager won't be able to sync 'user' between other tabs`,
      )
    }

    attachEvents()
  }

  return {
    init,
    logout,
    logoutAndRedirectToDefault,
    credentialsLogin,
    rfidLogin,
    refreshToken,
    credentialsUserSwitch,
    userSwitch,
    getUser,
    isAuthenticated,
    events,
  }
}

export default AuthManager()
