export const DEFAULT_LANGUAGE_CODE = 'en-default'

const LocaleLanguageCodes = {
  ['th-th' as string]: 'th',
  ['ko-kr' as string]: 'ko',
  ['id-id' as string]: 'id',
  ['ms-my' as string]: 'ms',
  ['bs-latn-ba' as string]: 'bs',
  ['ca-es' as string]: 'ca',
  ['cs-cz' as string]: 'cs',
  ['da-dk' as string]: 'da',
  ['de-de' as string]: 'de',
  ['de-at' as string]: 'de-AT',
  ['de-ch' as string]: 'de',
  ['et-ee' as string]: 'et',
  ['en-au' as string]: 'en-AU',
  ['en-ca' as string]: 'en-CA',
  ['en-in' as string]: 'en-IN',
  ['en-ie' as string]: 'en-IE',
  ['en-my' as string]: 'en-GB',
  ['en-nz' as string]: 'en-NZ',
  ['en-sg' as string]: 'en-GB',
  ['en-za' as string]: 'en-ZA',
  ['en-gb' as string]: 'en-GB',
  ['en-us' as string]: 'en-US',
  ['es-es' as string]: 'es',
  ['es-mx' as string]: 'es',
  ['eu-es' as string]: 'eu',
  ['fil-ph' as string]: 'fi',
  ['fr-fr' as string]: 'fr',
  ['fr-be' as string]: 'fr',
  ['fr-ca' as string]: 'fr-CA',
  ['fr-ch' as string]: 'fr-CH',
  ['ga-ie' as string]: 'en-GB',
  ['gl-es' as string]: 'gl',
  ['hr-hr' as string]: 'hr',
  ['is-is' as string]: 'is',
  ['it-it' as string]: 'it',
  ['it-ch' as string]: 'it-CH',
  ['lv-lv' as string]: 'lv',
  ['lb-lu' as string]: 'lb',
  ['lt-lt' as string]: 'lt',
  ['hu-hu' as string]: 'hu',
  ['mt-mt' as string]: 'mt',
  ['nl-nl' as string]: 'nl',
  ['nl-be' as string]: 'nl-BE',
  ['nb-no' as string]: 'nb',
  ['pl-pl' as string]: 'pl',
  ['pt-br' as string]: 'pt-BR',
  ['pt-pt' as string]: 'pt',
  ['ro-ro' as string]: 'rp',
  ['sk-sk' as string]: 'sk',
  ['sl-si' as string]: 'sl',
  ['sr-latn-rs' as string]: 'sr-Latn',
  ['fi-fi' as string]: 'fi',
  ['sv-se' as string]: 'sv',
  ['vi-vn' as string]: 'vi',
  ['tr-tr' as string]: 'tr',
  ['ar-sa' as string]: 'ar-SA',
  ['he-il' as string]: 'he',
  ['el-gr' as string]: 'el',
  ['bg-bg' as string]: 'bg',
  ['kk-kz' as string]: 'kk',
  ['ru-ru' as string]: 'ru',
  ['sr-cyrl-rs' as string]: 'sr',
  ['uk-ua' as string]: 'uk',
  ['ka-ge' as string]: 'ka',
  ['hi-in' as string]: 'hi',
  ['zh-cn' as string]: 'zh-CN',
  ['zh-tw' as string]: 'zh-TW',
  ['zh-hk' as string]: 'zh-HK',
  ['ja-jp' as string]: 'ja',
} as const

export default LocaleLanguageCodes
