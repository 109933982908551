import React from 'react'

import { useMachineContext } from 'Context/MachineContext'
import { useUser as useUserContext } from 'Context/UserContext'

import GlobalPopupsHandler from './GlobalPopupsHandler'
import NotificationsHandler from './NotificationsHandler'
import OperatorWorkHandler from './OperatorWorkHandler'
import PairedMachineHandler from './PairedMachineHandler'
import ShiftsConfigurationHandler from './ShiftsConfigurationHandler/ShiftsConfigurationHandler'
import SnackbarHandler from './SnackbarHandler'

const SharedHandlers = () => {
  const { machine } = useMachineContext()
  const { user } = useUserContext()

  return (
    <>
      <GlobalPopupsHandler />
      <SnackbarHandler />
      {user && <NotificationsHandler />}
      {user && <ShiftsConfigurationHandler />}
      {user && machine && <OperatorWorkHandler />}
      {machine && <PairedMachineHandler />}
    </>
  )
}

export default SharedHandlers
