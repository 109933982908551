import { useEffect, useState } from 'react'

import { apiClient } from 'Api/ApiClient'
import AuthManager from 'Auth/AuthManager'
import HubMethods from 'Consts/HubMethods'
import { useMachineContext } from 'Context/MachineContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { useUser as useUserContext } from 'Context/UserContext'
import { useWorkstationAbilityToLogin } from 'Hooks/workstations/useWorkstationAbilityToLogin'
import type { OperatorWorkEndedMessage, OperatorWorkStartedMessage } from 'Types'

const OperatorWorkHandler = () => {
  const { machine, isAvailable, setIsAvailable } = useMachineContext()
  const { selectedApp, isOperatorWorkInProgress, setIsOperatorWorkInProgress } = useUserContext()
  const { connectedClientId, hubConnection } = useSignalRContext()
  const [isForceLogout, setIsForceLogout] = useState(false)
  const machineId = machine?.id

  const { isLoading } = useWorkstationAbilityToLogin(
    machineId!,
    { clientId: connectedClientId! },
    {
      enabled: !!machineId && !!connectedClientId,
      onSuccess: ({ isOccupied, isAvailable }) => {
        setIsAvailable(isAvailable)
        setIsOperatorWorkInProgress(isOccupied)
      },
    },
  )

  useEffect(() => {
    const isMachineAvailableForUser = machineId && isAvailable && !isLoading && !isForceLogout
    if (isMachineAvailableForUser && !isOperatorWorkInProgress && selectedApp === 'OperatorPanel') {
      apiClient.postOperatorWorkStart(machineId).then(() => {
        setIsOperatorWorkInProgress(true)
      })
    }
  }, [selectedApp, isOperatorWorkInProgress, machineId, isLoading, isAvailable, isForceLogout])

  useEffect(() => {
    const handleOperatorWorkStarted = ({ workstationId }: OperatorWorkStartedMessage) => {
      if (machineId === workstationId) {
        setIsOperatorWorkInProgress(true)
      }
    }

    const handleOperatorWorkEnded = ({ workstationId, isForced }: OperatorWorkEndedMessage) => {
      if (machineId === workstationId) {
        if (isForced) {
          setIsForceLogout(true)
          AuthManager.logoutAndRedirectToDefault()
        }
        setIsOperatorWorkInProgress(false)
      }
    }

    hubConnection?.on(HubMethods.OperatorWorkStarted, handleOperatorWorkStarted)
    hubConnection?.on(HubMethods.OperatorWorkEnded, handleOperatorWorkEnded)

    return () => {
      hubConnection?.off(HubMethods.OperatorWorkStarted, handleOperatorWorkStarted)
      hubConnection?.off(HubMethods.OperatorWorkEnded, handleOperatorWorkEnded)
    }
  }, [hubConnection, machineId])

  return null
}

export default OperatorWorkHandler
