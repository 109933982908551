import { useController, useFormContext } from 'react-hook-form'

import MultiSelectVirtualized, { type MultiSelectVirtualizedProps } from 'Components/MultiSelectVirtualized'

import type { FormField } from './types'

type OptionValue = string | number
export type FormMultiSelectVirtualized<T extends OptionValue> = FormField &
  Omit<MultiSelectVirtualizedProps<T>, 'value'>

export const FormMultiSelectVirtualized = <T extends OptionValue>(props: FormMultiSelectVirtualized<T>) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange, ...fieldProps },
    fieldState,
  } = useController({ name: props.name, control })

  return (
    <MultiSelectVirtualized
      {...fieldProps}
      {...props}
      value={value}
      onChange={(value) => {
        onChange(value)
        props.onChange?.(value)
      }}
      error={fieldState.invalid}
      helperText={fieldState.error?.message ?? props.helperText}
    />
  )
}
