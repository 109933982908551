import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ConstructionIcon from '@mui/icons-material/Construction'
import LogoutIcon from '@mui/icons-material/Logout'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { Box, Button, Dialog, Stack } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import RadioTile from 'Components/RadioTile'
import { useMachineContext } from 'Context/MachineContext'
import { useOrdersForMachineQuery } from 'Hooks/orders/useOrdersForMachine'
import useLogout from 'Hooks/useLogout'
import paths from 'Routes/paths'
import { Bold } from 'Styles/utils'

type Props = {
  open: boolean
  onClose: () => void
}

const Options = {
  RETOOL: 'retool',
  LOGOUT: 'logout',
  REPORT_IDLE: 'report-idle',
  CONTINUE_WORK: 'continue-work',
} as const

type Option = (typeof Options)[keyof typeof Options]

const EnforceRetoolFromOrderDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectedOption, setSelectedOption] = useState<Option>(Options.RETOOL)
  const { order, variant, machine } = useMachineContext()
  const machineId = machine?.id
  const orderName = order?.name
  const componentName = variant?.name
  const { data: orders, isLoading } = useOrdersForMachineQuery(machineId!, {
    enabled: !!machineId,
    onSuccess: (data) => {
      if (data.length === 1) {
        setSelectedOption(Options.LOGOUT)
      }
    },
  })
  const { handleLogout } = useLogout()

  const handleConfirm = () => {
    switch (selectedOption) {
      case Options.RETOOL:
        history.push(paths.operatorRetool)
        break
      case Options.LOGOUT:
        handleLogout()
        break
      case Options.REPORT_IDLE:
        history.push(paths.operatorReportIdleReason)
        break
      default:
        break
    }

    onClose()
  }

  const isRetoolEnabled = orders && orders.length > 1
  const isOrderEndedByOperator = order?.finishExecutor === 'Operator'

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minWidth: '632px' },
      }}
    >
      <DialogTitle onClose={onClose}>{t('messages.workAtTheWorkstationHasBeenEnded')}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Box>
            {isOrderEndedByOperator ? (
              <Trans i18nKey={'messages.workOnTheComponentonOnTheOrderHasBeenEndedOnYourWorkstation'}>
                Work on the <Bold>{{ componentName }}</Bold> component on the
                <Bold> {{ orderName }}</Bold> order has been ended on your workstation.
              </Trans>
            ) : (
              <Trans i18nKey={'messages.thePlannerHasEndedWorkOnTheComponentInTheOrderAtYourWorkstation'}>
                The planner has ended work on the <Bold>{{ componentName }}</Bold> component in the
                <Bold> {{ orderName }}</Bold> order at your workstation.
              </Trans>
            )}{' '}
            {t('messages.chooseWhatYouWantToDoNow')}:
          </Box>
          <Stack spacing={1} useFlexGap>
            <RadioTile
              label={t('labels.startRetool')}
              description={t('messages.startWorkingOnANewOrder')}
              value={Options.RETOOL}
              checked={selectedOption === Options.RETOOL}
              onClick={() => setSelectedOption(Options.RETOOL)}
              disabled={!isRetoolEnabled || isLoading}
              Icon={ConstructionIcon}
            />
            <RadioTile
              label={t('labels.logout')}
              description={t('messages.endWorkAtTheWorkstation')}
              value={Options.LOGOUT}
              checked={selectedOption === Options.LOGOUT}
              onClick={() => setSelectedOption(Options.LOGOUT)}
              disabled={isLoading}
              Icon={LogoutIcon}
            />
            <Stack spacing={1} direction='row' flex='1 0 0'>
              <RadioTile
                label={t('labels.reportAnIdle')}
                description={t('labels.stopWork')}
                value={Options.REPORT_IDLE}
                checked={selectedOption === Options.REPORT_IDLE}
                onClick={() => setSelectedOption(Options.REPORT_IDLE)}
                disabled={isLoading}
                Icon={PauseIcon}
              />
              {!isOrderEndedByOperator && (
                <RadioTile
                  label={t('labels.finishTheBunch')}
                  description={t('labels.continueWork')}
                  value={Options.CONTINUE_WORK}
                  checked={selectedOption === Options.CONTINUE_WORK}
                  onClick={() => setSelectedOption(Options.CONTINUE_WORK)}
                  disabled={isLoading}
                  Icon={PlayArrowIcon}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='text'>
          {t('labels.cancel')}
        </Button>
        <Button onClick={handleConfirm}>{t('labels.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EnforceRetoolFromOrderDialog
