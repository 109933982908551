import { Components, Theme } from '@mui/material'
import type {} from '@mui/x-date-pickers/themeAugmentation'

import { palette } from './colors'
import ButtonOverride from './Components/Button'
import customShadows from './shadows'

const ComponentsOverride: Components<Omit<Theme, 'components'>> = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h5: 'p',
        h6: 'p',
        h4: 'p',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'p',
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: 14,
        backgroundColor: palette.background.paper,
        height: '3.5rem',
        borderRadius: 20,
        padding: '0 25px',
        boxShadow: '0px 4px 8px rgba(22, 22, 21, 0.24)',
      },
      anchorOriginBottomLeft: {
        left: '16px !important',
        bottom: '12px !important',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiBackdrop-root': {
          backgroundColor: palette.background.backdrop,
        },
      },
      paper: {
        borderRadius: 20,
      },
    },
  },
  MuiButton: ButtonOverride,
  MuiButtonBase: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
    },
    defaultProps: {
      color: 'inherit',
    },
  },
  MuiInputLabel: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.paper,
      },
    },
  },
  MuiLink: {
    defaultProps: {
      fontWeight: 'medium',
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 16,
        boxShadow: customShadows[6],
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        boxShadow: customShadows[0],
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        boxShadow: customShadows[6],
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
      },
    },
  },
  MuiDatePicker: {
    defaultProps: {
      desktopModeMediaQuery: '',
    },
  },
}

export default ComponentsOverride
