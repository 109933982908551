import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const VariantsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d='M18 0H2C1 0 0 0.9 0 2V5.01C0 5.73 0.43 6.35 1 6.7V18C1 19.1 2.1 20 3 20H17C17.9 20 19 19.1 19 18V6.7C19.57 6.35 20 5.73 20 5.01V2C20 0.9 19 0 18 0ZM17 18H3V7H17V18ZM18 5H2V2H18V5Z' />
      <path d='M13 10H7V12H13V10Z' />
    </SvgIcon>
  )
}

export default VariantsIcon
