import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle, Box, Grid, Stack, Typography } from '@mui/material'

import {
  FormDatePicker,
  FormManualNumericControl,
  FormSelectVirtualized,
  FormTextField,
} from 'Components/form-components'
import { Option } from 'Types'

import { type OrderFormValues } from './validation'

type Props = {
  technologyOptions: Option[]
  disableTechnology?: boolean
  nameHelperText?: string
  technologyHelperText?: string
  startDateHelperText?: string
  minAmount?: number
  disableName?: boolean
  disableStartDate?: boolean
  isTechnologyComplete?: boolean
  amountHelperText?: string
}

const OrderForm = ({
  technologyOptions,
  disableTechnology,
  nameHelperText,
  technologyHelperText,
  startDateHelperText,
  minAmount,
  disableName,
  disableStartDate,
  isTechnologyComplete,
  amountHelperText,
}: Props) => {
  const { t } = useTranslation()
  const { watch, trigger } = useFormContext<OrderFormValues>()
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const technologyId = watch('technologyId')

  useEffect(() => {
    if (startDate && endDate) {
      trigger('startDate')
      trigger('endDate')
    }
  }, [endDate, startDate])

  return (
    <Stack spacing={3} sx={{ paddingTop: 2 }}>
      <FormTextField
        name='name'
        label={t('labels.name')}
        placeholder={t('labels.name')}
        fullWidth
        disabled={disableName}
        helperText={nameHelperText}
      />
      <Stack>
        <Typography variant='h6' fontWeight='medium' gutterBottom>
          {t('labels.dates')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormDatePicker
              name='startDate'
              label={t('labels.startDate')}
              disabled={disableStartDate}
              helperText={startDateHelperText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDatePicker name='endDate' label={t('labels.endDate')} />
          </Grid>
        </Grid>
      </Stack>
      <Stack spacing={2}>
        <Stack>
          <Typography variant='h6' fontWeight='medium'>
            {t('labels.technology')}
          </Typography>
          <Typography>{t('labels.defineTheTechnologyAndNumberOfProductsForThisOrder')}:</Typography>
        </Stack>
        <Stack
          direction='row'
          sx={{
            background: ({ palette }) => palette.background.default,
            width: 1,
            paddingX: 1,
            paddingY: 2,
            display: 'flex',
          }}
          spacing={2}
        >
          <FormSelectVirtualized
            name='technologyId'
            options={technologyOptions}
            label={t('labels.technology')}
            disabled={disableTechnology}
            fullWidth
            helperText={technologyHelperText}
          />
          <Box>
            <FormManualNumericControl
              name='amount'
              minNumber={minAmount}
              maxNumber={100000}
              height='54px'
              helperText={amountHelperText}
            />
          </Box>
        </Stack>
        {technologyId && !isTechnologyComplete && (
          <Alert severity='error'>
            <AlertTitle>{t('messages.selectedTechnologyIsIncomplete')}</AlertTitle>
            {t('messages.atLeastOneComponentOfTheTechnologyDoesNotHaveAnAssignedActiveWorkstationToProduceIt')}{' '}
            {t(
              'messages.contactThePersonResponsibleForDefiningTheTechnologyAndAskForTheAssignmentOfAnActiveWorkstationInOrderToSaveTheChanges',
            )}
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}

export default OrderForm
