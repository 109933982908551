import React, { useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { Alert, Box, Button, Dialog, Stack, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { errorCodes } from 'Consts/Errors'
import getErrorCode from 'Helpers/ApiErrorCodeGetter'
import useTechnology from 'Hooks/technologies/useTechnology'
import useUpdateTechnology from 'Hooks/technologies/useUpdateTechnology'
import { useVariantsWithMachinesQuery } from 'Hooks/variants/useVariantsWithMachinesQuery'
import { Bold } from 'Styles/utils'

import TechnologyForm, {
  getTechnologFormSchema,
  mapTechnologyDtoToTechnologyFormValues,
  mapTechnologyFormValuesToPutTechnologyDto,
  TechnologyFormValues,
} from './TechnologyForm'

type Props = {
  open: boolean
  onClose: () => void
  id: string
}

const EditDialog: React.FC<Props> = ({ open, onClose, id }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')

  const { data: variantsWithMachines, isLoading: isVariantsWithMachinesLoading } = useVariantsWithMachinesQuery({
    ids: [],
  })
  const variantsWithoutMachinesIds = variantsWithMachines?.filter((v) => !v.machines.length).map((v) => v.id) ?? []
  const technologyFormSchema = useMemo(
    () => getTechnologFormSchema({ variantsWithoutMachinesIds }),
    [variantsWithoutMachinesIds],
  )

  const formMethods = useForm<TechnologyFormValues>({
    mode: 'all',
    resolver: zodResolver(technologyFormSchema),
  })
  const {
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = formMethods

  const {
    data: technology,
    isLoading: isLoadingTechnology,
    isError: isTechnologyError,
  } = useTechnology(id, {
    onSuccess: ({ name, components }) => {
      reset(mapTechnologyDtoToTechnologyFormValues({ name, components }))
    },
  })
  const { mutateAsync: updateTechnology } = useUpdateTechnology()

  const onSubmit = async (data: TechnologyFormValues) => {
    await updateTechnology(
      { id, technology: mapTechnologyFormValuesToPutTechnologyDto(data) },
      {
        onSuccess: onClose,
        onError: (error: unknown) => {
          const errorCode = getErrorCode(error)
          if (errorCode === errorCodes.nameNotUnique) {
            setError('name', { message: t('messages.valueMustBeUnique') })
          } else if (errorCode === errorCodes.entityNotFound) {
            setSubmitErrorMessage(t('messages.technologyHasBeenDeleted'))
          } else {
            setSubmitErrorMessage(t('messages.sorryThereWasAProblemWithYourRequest'))
          }
        },
      },
    )
  }

  if (isLoadingTechnology || isVariantsWithMachinesLoading) return <div />

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{ sx: { minWidth: 'min(60rem, 90vw)', height: 'min(45rem, 85vh)' } }}
          disablePortal
        >
          <DialogTitle onClose={onClose}>
            {t('labels.editTechnology')}: <Bold>{`${technology?.name}`}</Bold>
            <Typography fontSize='inherit' component='span'>{` (v${technology?.revision})`}</Typography>
          </DialogTitle>
          <DialogContent ref={contentRef}>
            {isTechnologyError ? (
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography color='error'>{t('messages.technologyHasBeenDeleted')}</Typography>
              </Box>
            ) : (
              <Stack spacing={2}>
                <Alert severity='info'>
                  {t('messages.changesToComponentsOrTheirNumberAffectTheVersionNumberOfTheTechnology')}{' '}
                  {t('messages.ordersCurrentlyUsingThisTechnologyWillNotBeUpdated')}{' '}
                  {t('messages.toUseTheLatestVersionOfTechnologyYouNeedYoCreateANewOrder')}
                </Alert>
                <TechnologyForm variantsWithMachines={variantsWithMachines ?? []} containerRef={contentRef} />
              </Stack>
            )}
          </DialogContent>
          <DialogActions actionsVariant='center' errorMessage={submitErrorMessage}>
            <Button onClick={onClose} variant='text'>
              {t('labels.cancel')}
            </Button>
            <Button type='submit' disabled={!isValid || isSubmitting || !!submitErrorMessage}>
              {t('labels.save')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  )
}

export default EditDialog
