import React from 'react'
import { useTranslation } from 'react-i18next'
import { Close as CloseIcon, Error as ErrorIcon } from '@mui/icons-material'
import { Box, IconButton, Snackbar, Typography } from '@mui/material'

interface Props {
  open: boolean
  onClose: () => void
}

const DataLoggerDisconnected: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <ErrorIcon color='error' sx={{ mr: 1 }} />
        <Typography variant='body2' fontWeight='medium'>
          {t('messages.dataLoggerConnectionLost')}
        </Typography>
        <IconButton
          onClick={onClose}
          style={{ maxHeight: '30px', maxWidth: '30px', display: 'flex', justifyContent: 'center' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Snackbar>
  )
}

export default DataLoggerDisconnected
