import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const YamazumiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d='M2.57495 21.4242V18.9242L4.84995 16.6492V21.4242H2.57495ZM6.72495 21.4242V14.8992L8.99995 12.6242V21.4242H6.72495ZM10.875 21.4242V12.6242L13.15 14.8992V21.4242H10.875ZM15.025 21.4242V14.8492L17.3 12.5992V21.4242H15.025ZM19.1749 21.4242V10.8242L21.4249 8.57422V21.4242H19.1749ZM2.57495 15.9992V12.6492L9.99995 5.27422L14 9.27422L21.4249 1.82422V5.14922L14 12.6242L9.99995 8.62422L2.57495 15.9992Z' />
    </SvgIcon>
  )
}

export default YamazumiIcon
