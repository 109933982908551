import React, { useCallback, useEffect, useRef } from 'react'

import { apiClient } from 'Api/ApiClient'
import HubMethods from 'Consts/HubMethods'
import { useShiftsConfigurationContext } from 'Context/ShiftsContext/ShiftsContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { getCurrentShift, getTimeToShiftEndInSeconds } from 'Helpers/Shifts'
import { ShiftConfigurationEffectiveToChangedMessage } from 'Types'

const calculateDateForNextUpdate = (effectiveTo: Date) => {
  const DAY_IN_MILISECONDS = 24 * 60 * 60 * 1000
  const TIMEOUT_OFFSET = 1000
  const timeTillNextShiftConfiguration = +new Date(effectiveTo) - Date.now() + TIMEOUT_OFFSET
  const timeTillNextUpdate = Math.min(timeTillNextShiftConfiguration, DAY_IN_MILISECONDS)

  return new Date(Date.now() + timeTillNextUpdate)
}

const ShiftsConfigurationHandler: React.FC = () => {
  const { shiftsConfiguration, currentShift, setShiftsConfiguration, setCurrentShift } = useShiftsConfigurationContext()
  const fetchShiftTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const setNextShiftTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const { hubConnection } = useSignalRContext()

  const fetchShiftConfiguration = useCallback((date: Date) => {
    apiClient.getShiftConfigurationByDate(date.toISOString()).then(({ data }) => {
      setShiftsConfiguration(data)
    })
  }, [])

  useEffect(() => {
    fetchShiftConfiguration(new Date())
  }, [])

  useEffect(() => {
    const handleShiftConfigurationEffectiveToChanged = ({
      shiftConfigurationId,
      shiftConfigurationEffectiveTo,
    }: ShiftConfigurationEffectiveToChangedMessage) => {
      setShiftsConfiguration((prev) => {
        if (!prev || prev.id !== shiftConfigurationId) {
          return prev
        }

        return { ...prev, effectiveTo: shiftConfigurationEffectiveTo }
      })
    }
    hubConnection?.on(HubMethods.ShiftConfigurationEffectiveToChanged, handleShiftConfigurationEffectiveToChanged)

    return () => {
      hubConnection?.off(HubMethods.ShiftConfigurationEffectiveToChanged, handleShiftConfigurationEffectiveToChanged)
    }
  }, [hubConnection])

  useEffect(() => {
    if (shiftsConfiguration?.effectiveTo) {
      const dateOfNextUpdate = calculateDateForNextUpdate(new Date(shiftsConfiguration.effectiveTo))
      const msTillShiftConfigurationUpdate = +dateOfNextUpdate - Date.now()

      fetchShiftTimeoutRef.current = setTimeout(() => {
        fetchShiftConfiguration(dateOfNextUpdate)
      }, msTillShiftConfigurationUpdate)
    }

    return () => {
      if (fetchShiftTimeoutRef.current) {
        clearTimeout(fetchShiftTimeoutRef.current)
      }
    }
  }, [shiftsConfiguration, fetchShiftConfiguration])

  useEffect(() => {
    const newCurrentShift = getCurrentShift(shiftsConfiguration)
    const isNewCurrentShiftReady = (!currentShift && newCurrentShift) || (currentShift && !newCurrentShift)
    const hasCurrentShiftChanged =
      currentShift && newCurrentShift && JSON.stringify(currentShift) !== JSON.stringify(newCurrentShift)

    if (isNewCurrentShiftReady || hasCurrentShiftChanged) {
      setCurrentShift(newCurrentShift)
    }
  }, [shiftsConfiguration, currentShift])

  useEffect(() => {
    if (currentShift) {
      const timeOffsetForNextNewShift = 1000
      const timeToShiftEnd = getTimeToShiftEndInSeconds(currentShift) * 1000 + timeOffsetForNextNewShift

      setNextShiftTimeoutRef.current = setTimeout(() => {
        setCurrentShift(getCurrentShift(shiftsConfiguration))
      }, timeToShiftEnd)
    }

    return () => {
      if (setNextShiftTimeoutRef.current) {
        clearTimeout(setNextShiftTimeoutRef.current)
      }
    }
  }, [currentShift, shiftsConfiguration])

  return null
}

export default ShiftsConfigurationHandler
