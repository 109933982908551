import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { GetOrdersForMachineApiResponse } from 'Types'

export const useOrdersForMachineQuery = (
  id: string,
  options?: UseQueryOptions<
    GetOrdersForMachineApiResponse,
    unknown,
    GetOrdersForMachineApiResponse,
    ['orders-for-machine', string]
  >,
) =>
  useQuery(
    ['orders-for-machine', id],
    async () => {
      const { data } = await apiClient.getOrdersForMachine(id)

      return data
    },
    options,
  )
