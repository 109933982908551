import React from 'react'
import { useTranslation } from 'react-i18next'
import CancelIcon from '@mui/icons-material/CancelOutlined'

import ErrorPageTemplate from 'Layout/ErrorPageTemplate'

const Unauthorized = () => {
  const { t } = useTranslation()

  return (
    <ErrorPageTemplate Icon={CancelIcon} header={t('labels.noAccess')} description={t('messages.youDontHaveAccess')} />
  )
}

export default Unauthorized
