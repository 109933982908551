import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { GetOrderStatusTypesApiResponse } from 'Types'

const useOrderStatusType = (
  options?: UseQueryOptions<
    GetOrderStatusTypesApiResponse,
    unknown,
    GetOrderStatusTypesApiResponse,
    ['order-status-types']
  >,
) =>
  useQuery(
    ['order-status-types'],
    async () => {
      const { data } = await apiClient.getOrderStatusTypes()

      return data
    },
    {
      ...options,
    },
  )

export default useOrderStatusType
