import { getI18n } from 'react-i18next'
import { z } from 'zod'

const technologyFormSchema = z.object({
  name: z
    .string({
      required_error: getI18n().t('messages.thisFieldIsRequired'),
    })
    .trim()
    .min(1, getI18n().t('messages.theMinimumNumberOfCharactersIs', { length: 1 }))
    .max(50, getI18n().t('messages.theMaximumNumberOfCharactersIs', { length: 50 })),
  components: z
    .object({
      id: z.number().optional(),
      variantId: z
        .string({ required_error: getI18n().t('messages.thisFieldIsRequired') })
        .min(1, getI18n().t('messages.thisFieldIsRequired')),
      amount: z.number().min(1).max(31556926),
      machines: z
        .object({ id: z.string() })
        .array()
        .min(1, getI18n().t('messages.selectAtLeastX', { min: 1 })),
      tempId: z.string().optional(),
    })
    .array()
    .min(1, getI18n().t('messages.thisFieldIsRequired'))
    .superRefine((componenets, { addIssue }) => {
      const hasDuplicateComponentsMap = new Map<string, boolean>()
      componenets.forEach(({ variantId }) => {
        if (hasDuplicateComponentsMap.has(variantId)) {
          hasDuplicateComponentsMap.set(variantId, true)
        } else {
          hasDuplicateComponentsMap.set(variantId, false)
        }
      })

      componenets.forEach(({ variantId }, index) => {
        if (variantId && hasDuplicateComponentsMap.get(variantId)) {
          addIssue({
            code: 'custom',
            message: getI18n().t('messages.duplicatedComponents'),
            path: [index, 'variantId'],
          })
        }
      })
    }),
})

export const getTechnologFormSchema = ({
  variantsWithoutMachinesIds = [],
}: {
  variantsWithoutMachinesIds: string[]
}) => {
  return technologyFormSchema.superRefine(({ components }, { addIssue }) => {
    components.forEach(({ variantId }, index) => {
      if (variantsWithoutMachinesIds.includes(variantId)) {
        addIssue({
          code: 'custom',
          message: getI18n().t('messages.componentHasNotWorkstations'),
          path: ['components', index, 'variantId'],
        })
      }
    })
  })
}

export type TechnologyFormValues = z.infer<typeof technologyFormSchema>
