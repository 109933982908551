import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Dialog } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { useOrderElementCompletion } from 'Hooks/orders/useCompleteOrderElement'
import { Bold } from 'Styles/utils'

type Props = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  order: {
    orderId: number
    orderComponentId: number
    orderName: string
    componentName: string
  }
  machineId: string
}

const FinishWorkByWorkstationDialog = ({ open, onClose, onSuccess, order, machineId }: Props) => {
  const { t } = useTranslation()
  const { mutate: finishOrderElement, isLoading: isSubmitting, isError } = useOrderElementCompletion()
  const { orderId, orderName, componentName, orderComponentId } = order

  const handleComplete = () => {
    finishOrderElement(
      {
        orderId,
        orderComponentId,
        machineId,
      },
      {
        onSuccess,
      },
    )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{t('labels.confirmTheCompletionOfWorkOnTheOrder')}</DialogTitle>
      <DialogContent>
        <Trans t={t} i18nKey='messages.theProductionGoalOfTheComponentInTheOrderHasBeenFulfilled'>
          The production goal of the <Bold>{{ componentName }}</Bold> component in the <Bold>{{ orderName }}</Bold>{' '}
          order has been fulfilled.
        </Trans>
      </DialogContent>
      <DialogActions errorMessage={isError ? t('messages.sorryThereWasAProblemWithYourRequest') : undefined}>
        <Button onClick={onClose} variant='text'>
          {t('labels.cancel')}
        </Button>
        <Button onClick={handleComplete} disabled={isSubmitting}>
          {t('labels.completeOrder')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FinishWorkByWorkstationDialog
