import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Alert, Button, Dialog, Stack, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { useOrderElementCompletion } from 'Hooks/orders/useCompleteOrderElement'
import { Bold } from 'Styles/utils'
import { OrderDto } from 'Types/Orders'

type Props = {
  open: boolean
  onClose: () => void
  order: OrderDto
  orderComponentId?: OrderDto['orderComponents'][0]['id'] | null
  orderComponentMachineId?: string | null
}

const FinishWorkDialog = ({ open, onClose, order, orderComponentId, orderComponentMachineId }: Props) => {
  const { t } = useTranslation()
  const { mutate: finishOrderElement, isLoading: isSubmitting, isError } = useOrderElementCompletion()
  const orderComponent = order.orderComponents.find(({ id }) => id === orderComponentId)
  const machine = orderComponent?.orderComponentMachines?.find(({ id }) => id === orderComponentMachineId)
  const machineId = machine?.machineId

  const handleComplete = () => {
    finishOrderElement(
      {
        orderId: order.id,
        orderComponentId,
        machineId,
      },
      {
        onSuccess: onClose,
      },
    )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {t(getFinishWorkTitleTranslationKey({ orderComponentId, orderComponentMachineId }))}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FinishWorkMessage
            orderName={order.name}
            componentName={orderComponent?.name}
            machineName={machine?.machineName}
          />
        </Stack>
      </DialogContent>
      <DialogActions errorMessage={isError ? t('messages.sorryThereWasAProblemWithYourRequest') : undefined}>
        <Button variant='text' onClick={onClose}>
          {t('labels.cancel')}
        </Button>
        <Button onClick={handleComplete} disabled={isSubmitting}>
          {t('labels.finish')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const getFinishWorkTitleTranslationKey = ({
  orderComponentId,
  orderComponentMachineId,
}: Pick<Props, 'orderComponentId' | 'orderComponentMachineId'>) => {
  if (orderComponentMachineId) {
    return 'labels.finishWorkAtTheWorkstation'
  }

  if (orderComponentId) {
    return 'labels.finishWorkingOnTheComponent'
  }

  return 'labels.finishWorkingOnTheOrder'
}

const FinishWorkMessage = ({
  orderName,
  componentName,
  machineName,
}: {
  orderName: string
  componentName?: string
  machineName?: string
}) => {
  const { t } = useTranslation()

  if (machineName && componentName) {
    return (
      <>
        <Typography>
          <Trans
            t={t}
            i18nKey='messages.confirmTheCompletionOfWorkAtTheWorkstationAssignedToTheProductionOfTheComponentInTheOrder'
          >
            Confirm the completion of work at the <Bold>{{ machineName }}</Bold> workstaton assigned to the production
            of the <Bold>{{ componentName }}</Bold> component in the <Bold>{{ orderName }}</Bold> order.
          </Trans>
        </Typography>
        <Alert severity='info'>
          <Trans t={t} i18nKey='messages.workWillBeFinishedOnlyOnThisWorkstation'>
            Work will be finished only on <Bold>this workstation</Bold>.
          </Trans>{' '}
          {t(
            'messages.theComponentWillNotBeVisibleOnTheRetoolListAndOperatorsWorkingOnItWillImmediatelyReceiveInformationAboutTheCompletionOfWork',
          )}
        </Alert>
      </>
    )
  }

  if (componentName) {
    return (
      <>
        <Typography>
          <Trans t={t} i18nKey='messages.confirmThatYouHaveFinishedWorkingOnTheComponentInTheOrder'>
            Confirm that you have finished working on the <Bold>{{ componentName }}</Bold> component in the
            <Bold>{{ orderName }}</Bold> order.
          </Trans>
        </Typography>
        <Alert severity='info'>
          <Trans t={t} i18nKey='messages.workWillBeFinishedOnAllWorkstationsAssignedToProductionOfThisComponent'>
            Work will be finished on <Bold>all workstations</Bold> assigned to production of this component.
          </Trans>{' '}
          {t(
            'messages.theComponentWillNotBeVisibleOnTheRetoolListAndOperatorsWorkingOnItWillImmediatelyReceiveInformationAboutTheCompletionOfWork',
          )}
        </Alert>
      </>
    )
  }

  return (
    <>
      <Typography>
        <Trans t={t} i18nKey='messages.confirmThatWorkOnTheOrderIsComplete'>
          Confirm that work on the <Bold>{{ orderName }}</Bold> order is complete.
        </Trans>
      </Typography>
      <Alert severity='info'>
        <Trans t={t} i18nKey='messages.workWillBeFinishedForAllComponentsAndWorkstationsAssignedToTheirProduction'>
          Work will be finished for <Bold>all components and workstations</Bold> assigned to their production.
        </Trans>{' '}
        {t(
          'messages.theOrderWillNoLongerBeVisibleOnTheRetoolListAndTheOperatorsWorkingOnItWillImmediatelyReceiveInformationAboutItsCompletion',
        )}
      </Alert>
    </>
  )
}

export default FinishWorkDialog
