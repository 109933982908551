import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'

import { apiClient } from 'Api/ApiClient'
import HubMethods from 'Consts/HubMethods'
import { useReportsGeneratingContext } from 'Context/ReportsGeneratingContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { ReportPreparedMessage } from 'Types'

import ReportGeneratingSnackbar from './ReportGeneratingSnackbar'

const ReportGeneratingHandler = () => {
  const [reportFileData, setReportFileData] = useState<ReportPreparedMessage | null>(null)
  const { hubConnection } = useSignalRContext()
  const { setJobId, setIsGenerating, isGenerating, jobId, isError, setIsError } = useReportsGeneratingContext()
  const isReportPrepared = !!reportFileData
  const isSnackbarOpen = isReportPrepared || isGenerating || isError

  useEffect(() => {
    const handleReportPrepared = (data: ReportPreparedMessage) => {
      setReportFileData(data)
      setIsError(false)
      setIsGenerating(false)
      setJobId(null)
    }
    const handleReportNotPrepared = () => {
      setReportFileData(null)
      setIsError(true)
      setIsGenerating(false)
      setJobId(null)
    }

    hubConnection?.on(HubMethods.ReportPrepared, handleReportPrepared)
    hubConnection?.on(HubMethods.ReportNotPrepared, handleReportNotPrepared)

    return () => {
      hubConnection?.off(HubMethods.ReportPrepared, handleReportPrepared)
      hubConnection?.off(HubMethods.ReportNotPrepared, handleReportNotPrepared)
    }
  }, [hubConnection])

  const handleReportDownload = () => {
    if (reportFileData) {
      saveAs(reportFileData.fullFilePath, reportFileData.friendlyFileName)
    }
  }

  const handleCancel = () => {
    if (jobId) {
      apiClient.cancelReportGeneratingJob(jobId)
      setJobId(null)
      setIsGenerating(false)
      setIsError(false)
    }
  }

  const handleClose = () => {
    setReportFileData(null)
    setIsError(false)
  }

  return (
    <ReportGeneratingSnackbar
      open={isSnackbarOpen}
      onDownload={handleReportDownload}
      onCancel={handleCancel}
      onClose={handleClose}
      isPrepared={isReportPrepared}
      isGenerating={isGenerating}
      isError={isError}
    />
  )
}

export default ReportGeneratingHandler
