import React from 'react'
import { useHistory } from 'react-router-dom'

import AppNames from 'Consts/AppNames'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'

import AccidentsHandler from './AccidentsHandler'
import AutoRefreshHandler from './AutoRefreshHandler'
import FilesToAcknowledgeHandler from './FilesToAcknowledgeHandler'
import { MachineHandler } from './MachineHandler'
import OperatorAlertsHandler from './OperatorAlertsHandler'
import OperatorPanelDialogsHandler from './OperatorPanelDialogsHandler'
import PairedMachineWithDeviceHandler from './PairedMachineWithDeviceHandler'
import ProductionHandler from './ProductionHandler'
import ReportsGeneratingHandler from './ReportsGeneratingHandler'
import WorkingWithFailureHandler from './WorkingWithFailureHandler'

const SelectedAppHandlers = () => {
  const { user, selectedApp } = useUserContext()
  const history = useHistory()

  switch (selectedApp) {
    case AppNames.OperatorPanel:
      return (
        <>
          <PairedMachineWithDeviceHandler />
          <FilesToAcknowledgeHandler />
          <MachineHandler />
          <ProductionHandler />
          <WorkingWithFailureHandler />
          <AutoRefreshHandler />
          <OperatorPanelDialogsHandler />
          <OperatorAlertsHandler />
        </>
      )

    case AppNames.AdminPanel:
      if (user?.permissions.has('AdminPanel')) {
        return (
          <>
            <ReportsGeneratingHandler />
          </>
        )
      }

      return null

    case AppNames.ForemanPanel:
      if (user?.permissions.has('ForemanPanel')) {
        return (
          <>
            <AccidentsHandler
              onAccidentConfirmation={() => {
                history.push(paths.foremanNotifications)
              }}
            />
            <ReportsGeneratingHandler />
          </>
        )
      }

      return null

    case AppNames.ForemanDashboard:
      if (user?.permissions.has('ForemanDashboard')) {
        return (
          <>
            <ReportsGeneratingHandler />
          </>
        )
      }

      return null

    default:
      return null
  }
}

export default SelectedAppHandlers
