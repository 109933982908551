import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AccessTime as AccessTimeIcon,
  Link as LinkIcon,
  List as ListIcon,
  Notifications as NotificationsIcon,
  Translate as TranslateIcon,
} from '@mui/icons-material'

import { useNotificationContext } from 'Context/NotificationContext'
import paths from 'Routes/paths'

import DataLoggersIcon from '../Icons/DataLoggersIcon'
import GroupAddIcon from '../Icons/GroupAddIcon'
import GroupSettingsIcon from '../Icons/GroupSettingsIcon'
import NotificationAddIcon from '../Icons/NotificationAddIcon'
import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const AdminPanelLinks = ({ collapsed }: Props) => {
  const { notificationsCount } = useNotificationContext()
  const { t } = useTranslation()

  const navItems: NavLinkItem[] = [
    {
      link: paths.adminNotifications,
      Icon: NotificationsIcon,
      label: t('labels.notifications'),
      badge: notificationsCount,
      border: true,
    },
    {
      link: paths.adminUsers,
      Icon: GroupSettingsIcon,
      label: t('labels.users'),
    },
    {
      link: paths.adminRoles,
      Icon: GroupAddIcon,
      label: t('labels.roles'),
    },
    {
      link: paths.adminCatalogs,
      Icon: ListIcon,
      label: t('labels.catalogs'),
    },
    {
      link: paths.adminDataLoggers,
      Icon: DataLoggersIcon,
      label: t('labels.dataLoggers'),
    },
    {
      link: paths.adminPairedPanels,
      Icon: LinkIcon,
      label: t('labels.pairedPanels'),
    },
    {
      link: paths.adminShiftEditor,
      Icon: AccessTimeIcon,
      label: t('labels.shiftEditor'),
    },
    {
      link: paths.adminNotificationManager,
      Icon: NotificationAddIcon,
      label: t('labels.notificationManager'),
    },
    {
      link: paths.adminTranslations,
      Icon: TranslateIcon,
      label: t('labels.translations'),
    },
  ]

  return <NavLinks items={navItems} collapsed={collapsed} />
}

export default AdminPanelLinks
