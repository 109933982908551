/* eslint-disable class-methods-use-this */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Buffer } from 'buffer'

import { ApiHeaders, createAxiosInstance } from 'Api/AxiosInstance'
import AuthManager from 'Auth/AuthManager'
import AppNames from 'Consts/AppNames'
import paths from 'Routes/paths'
import * as Types from 'Types'

const A = createAxiosInstance()

const handleUnauthorizedError = async () => {
  try {
    await AuthManager.logoutAndRedirectToDefault()
  } catch {
    // error already logged in AuthManager.ts
  }
}

const handleForbiddenError = () => {
  window.location.assign(paths.forbidden)
}

A.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      handleUnauthorizedError()
    } else if (error?.response?.status === 403) {
      handleForbiddenError()
    }
    throw error
  },
)

export class ApiClient {
  // #region BackgroundJobs
  cancelReportGeneratingJob = (id: number) => A.delete(`production/BackgroundJobs/${id}`)
  // #endregion

  // #region Calendars
  getMechanicCalendars = (params: Types.GetMechanicCalendarsApiParams) =>
    A.get<Types.GetMechanicCalendarsApiResponse>(`/tasking/Calendars`, { params })

  getMechanicsCalendars = (params: Types.GetMechanicsCalendarsApiParams) =>
    A.get<Types.GetMechanicsCalendarsApiResponse>('/tasking/Calendars/mechanics', { params })
  // #endregion

  // #region Catalogs
  getCatalogs = (params: Types.GetCatalogsApiParams) => {
    return A.get<Types.GetCatalogsApiResponse>('production/Catalogs', {
      params,
    })
  }

  postCatalog = (data: FormData) => A.post(`production/Catalogs`, data)

  deleteCatalog = (id: string) => A.delete(`production/Catalogs/${id}`)

  updateCatalog = (id: string, data: FormData) => A.put(`production/Catalogs/${id}`, data)

  getCatalog = (id: string) => A.get<Types.GetCatalogApiResponse>(`production/Catalogs/${id}`)

  getCatalogTypes = () => A.get<Types.GetCatalogTypesApiResponse>('production/Catalogs/types')

  getWorkstationsWithoutCatalogOfType = (catalogTypeId: number) =>
    A.get<Types.GetWorkstationsWithoutCatalogOfTypeApiResponse>(
      `/production/Catalogs/machines-without-type/${catalogTypeId}`,
    )
  // #endregion

  // #region Components

  postComponentScraps = (workstationId: string, data: Types.PostComponentScrapsDto) =>
    A.post(`production/Components/${workstationId}/scrap`, data)

  // #endregion

  // #region Configuration
  getFeatureFlags = (config?: AxiosRequestConfig) =>
    A.get<Types.GetFeatureFlagsApiResponse>('configuration/feature-flags', config)
  // #endregion

  // #region DataLoggerParams
  getDataLoggerParams = () => A.get<Types.GetDataLoggerParamsApiResponse>('production/DataLoggerParams')

  postDataLoggerParams = (data: Types.PostDataLoggerParamsDto) => A.post('production/DataLoggerParams', data)
  // #endregion

  // #region CheckLists
  postFailureCheckLists = (data: Types.PostFailureCheckListDto) => A.post('production/CheckLists/failure', data)

  getFailureCheckLists = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetFailureCheckListsApiResponse>('production/CheckLists/failure', { params })

  postCheckLists = (data: Types.PostCheckListDto) => A.post('production/CheckLists/maintenance', data)

  getCheckLists = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetFailureCheckListsApiResponse>('production/CheckLists/maintenance', { params })

  getFailureCheckList = (id: string) =>
    A.get<Types.GetFailureCheckListApiResponse>(`production/CheckLists/failure/${id}`)

  updateFailureCheckList = (id: string, data: Types.PutFailureCheckListDto) =>
    A.put(`production/CheckLists/failure/${id}`, data)

  getCheckList = (id: string) => A.get<Types.GetCheckListApiResponse>(`production/CheckLists/maintenance/${id}`)

  updateCheckList = (id: string, data: Types.PutCheckListDto) => A.put(`production/CheckLists/maintenance/${id}`, data)

  getMachinesWithoutFailureCheckLists = () =>
    A.get<Types.GetMachinesWithoutFailureCheckListsApiResponse>('production/CheckLists/machines-without-check-list')

  deleteCheckList = (id: string) => A.delete(`production/CheckLists/${id}`)
  // #endregion

  // #region Files
  postFile = (data: FormData) => A.post('production/Files', data)

  getFiles = (params: Types.GetFilesApiParams) =>
    A.get<Types.GetFilesApiResponse>('production/Files', {
      params,
    })

  getFile = (id: string) => A.get<Types.ForemanFileDto>(`production/Files/${id}`)

  deleteFile = (id: string) => A.delete(`production/Files/${id}`)

  updateFile = (id: string, data: Types.PutFileDto) => A.put(`production/Files/${id}`, data)

  postFileAsAcknowledged = (fileId: string, workstationId: string) =>
    A.post(`production/Files/${fileId}/acknowledge/${workstationId}`)

  postMechanicFileAsAcknowledged = (fileId: string, workstationId: string) =>
    A.post(`production/Files/${fileId}/acknowledge-ur/${workstationId}`)

  getOperatorActiveComplaints = (machineId: string) =>
    A.get<Types.GetOperatorActiveComplaintsApiResponse>(`production/Files/operator-complaints/${machineId}`)

  getActiveComplaints = (lineId: string) =>
    A.get<Types.GetActiveComplaintsApiResponse>(`production/Files/complaints/${lineId}`)

  getOperatorFileTypes = () => A.get<Types.GetOperatorFileTypesApiResponse>('production/Files/operator-types')

  getFileTypes = () => A.get<Types.GetFileTypesApiResponse>('production/Files/types')
  // #endregion

  // #region ForemanArrivalRequests
  postAcceptForemanArrivalRequest = (foremanArrivalRequestId: string, data: Types.PostForemanArrivalRequestDto) =>
    A.post(`production/ForemanArrivalRequests/${foremanArrivalRequestId}/accept`, data)
  // #endregion

  // #region ForemanReports
  getIdleReasonsReportForLine = (params: Types.GetIdleReasonsReportForLineApiParams) =>
    A.get<Types.ReasonsForLineDto>('production/ForemanReports/idle-reasons-for-line', {
      params,
    })

  getIdleReasonsReportForWorkstation = (params: Types.GetIdleReasonsReportForWorkstationApiParams) =>
    A.get<Types.GetIdleReasonsReportForWorkstationApiResponse>(
      'production/ForemanReports/idle-reasons-for-workstation',
      {
        params,
      },
    )

  getFailureReasonsReportForLine = (params: Types.FailureReasonsReportForLineApiParams) =>
    A.get<Types.GetFailureReasonsReportForLineApiResponse>('production/ForemanReports/failure-reasons-for-line', {
      params,
    })

  getFailureReasonsReportForWorkstation = (params: Types.GetFailureReasonsReportForWorkstationApiParams) =>
    A.get<Types.GetFailureReasonsReportForWorkstationApiResponse>(
      'production/ForemanReports/failure-reasons-for-workstation',
      {
        params,
      },
    )

  getWorkWithFailureReportForLine = (params: Types.GetWorkWithFailureReportForLineApiParams) =>
    A.get<Types.ReasonsForLineDto>('production/ForemanReports/work-with-failure-reasons-for-line', {
      params,
    })

  getWorkWithFailureReportForWorkstation = (params: Types.GetWorkWithFailureReportForWorkstationApiParams) =>
    A.get<Types.GetWorkWithFailureReasonsReportForWorkstationApiResponse>(
      'production/ForemanReports/work-with-failure-reasons-for-workstation',
      {
        params,
      },
    )

  getPerformanceDropsChartForLine = (params: Types.GetProductionDropsChartDataApiParams) =>
    A.get<Types.PerformanceDropsChartDataDto>('production/ForemanReports/performance-drops', { params })

  getStatusesTimelineForLine = (params: Types.GetStatusesTimelineChartForLineApiParams) =>
    A.get<Types.GetStatusesTimelineForLineApiResponse>(`production/ForemanReports/statuses-timeline-for-line`, {
      params,
    })

  getStatusesTimelineForWorkstation = (params: Types.GetStatusesTimelineChartForWorkstationApiParams) =>
    A.get<Types.GetStatusesTimelineForWorkstationApiResponse>(
      `production/ForemanReports/statuses-timeline-for-workstation`,
      {
        params,
      },
    )

  getOeeForLine = (params: Types.GetOeeForLineApiParams) =>
    A.get<Types.GetOeeForLineApiResponse>(`production/ForemanReports/oees`, {
      params,
    })

  getRetoolReasonsReportForLine = (params: Types.GetRetoolReasonsReportForLineApiParams) =>
    A.get<Types.RetoolReasonsDto>('production/ForemanReports/retool-reasons-for-line', {
      params,
    })

  getRetoolReasonsReportForWorkstation = (params: Types.GetRetoolReasonsReportForWorkstationApiParams) =>
    A.get<Types.GetRetoolReasonsReportForWorkstationApiResponse>(
      'production/ForemanReports/retool-reasons-for-workstation',
      {
        params,
      },
    )
  // #endregion

  // #region HeartBeats
  getHeartBeats = () => A.get<Types.GetHeartBeatsApiResponse>('production/HeartBeats')

  getDataLoggerStatusForWorkstation = (params: Types.GetDataLoggerStatusForWorkstationApiParams) =>
    A.get<Types.GetDataLoggerStatusForWorkstationApiResponse>('production/HeartBeats/data-logger-status', { params })
  // #endregion

  // #region Incidents
  postIncidentConfirmation = (id: string) => A.post(`production/Incidents/${id}/acknowled`)

  getUnacknowledgedIncidents = () =>
    A.get<Types.GetUnacknowledgedIncidentsApiReponse>('production/Incidents/unacknowledge')

  getIncidentsMonthOccurrence = (params: Types.GetIncidentsMonthOccurrenceApiParams) =>
    A.get<Types.GetIncidentsMonthOccurrenceApiResponse>(`production/Incidents/month-occurrence`, { params })

  getIncidentsStatistics = (params: Types.GetIncidentsStatisticsApiParams) =>
    A.get<Types.GetIncidentsStatisticsApiResponse>(`production/Incidents/statistics`, { params })
  // #endregion

  // #region KanbanTasks
  postKanbanTask = (data: Types.PostKanbanTaskParams) => A.post('production/KanbanTasks', data)

  getKanbanTasks = (params: Types.PaginatedApiParams & { productionLineId: string }) =>
    A.get<Types.GetKanbanTasksApiResponse>('production/KanbanTasks', { params })

  deleteKanbanTask = (id: string) => A.delete(`production/KanbanTasks/${id}`)

  getKanbanTask = (id: string) => A.get<Types.GetKanbanTaskApiResponse>(`production/KanbanTasks/${id}`)

  updateKanbanTask = (id: string, data: Types.KanbanTaskData) => A.put(`production/KanbanTasks/${id}`, data)

  getKanbanTaskStates = () => A.get<Types.KanbanTaskStatesDto[]>('production/KanbanTasks/states')

  getKanbanTaskExecutors = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetKanbanExecutorsApiResponse>('production/KanbanTasks/executors', { params })

  deleteKanbanTasksRelation = (id: string, relatedTaskId: string) =>
    A.delete(`production/KanbanTasks/${id}/relations/${relatedTaskId}`)

  postKanbanTasksRelation = (id: string, relatedTasksIds: string[]) =>
    A.post(`production/KanbanTasks/${id}/relations`, relatedTasksIds)

  searchKanbanTasks = (params: Types.SearchKanbanParams) =>
    A.get<Types.GetSearchKanbanApiResponse>('production/KanbanTasks/picker', { params })

  postKanbanTaskSubTask = (id: string, data: Types.SubTaskData) => A.post(`production/KanbanTasks/${id}/subTasks`, data)

  putKanbanTaskState = (id: string, state: string) =>
    A.put(`production/KanbanTasks/${id}/state`, state, { headers: { 'Content-Type': 'application/json' } })

  putKanbanTaskPosition = (kanbanTaskKey: string, beforeKey: string | null, afterKey: string | null) =>
    A.put(`production/KanbanTasks/position`, undefined, { params: { kanbanTaskKey, beforeKey, afterKey } })
  // #endregion

  // #region KanbanTasksUR
  postKanbanTaskUR = (data: Types.PostKanbanTaskURParams) => A.post('production/KanbanTasksUR', data)

  getKanbanTasksUR = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetKanbanTasksApiResponse>('production/KanbanTasksUR', { params })

  deleteKanbanTaskUR = (id: string) => A.delete(`production/KanbanTasksUR/${id}`)

  getKanbanTaskUR = (id: string) => A.get<Types.GetKanbanTaskApiResponse>(`production/KanbanTasksUR/${id}`)

  updateKanbanTaskUR = (id: string, data: Types.KanbanTaskData) => A.put(`production/KanbanTasksUR/${id}`, data)

  getKanbanTaskURStates = () => A.get<Types.KanbanTaskStatesDto[]>('production/KanbanTasksUR/states')

  deleteKanbanTasksURRelation = (id: string, relatedTaskId: string) =>
    A.delete(`production/KanbanTasksUR/${id}/relations/${relatedTaskId}`)

  postKanbanTasksURRelation = (id: string, relatedTasksIds: string[]) =>
    A.post(`production/KanbanTasksUR/${id}/relations`, relatedTasksIds)

  searchKanbanTasksUR = (params: Types.SearchKanbanParams) =>
    A.get<Types.GetSearchKanbanApiResponse>('production/KanbanTasksUR/picker', { params })

  postKanbanTaskSubTaskUR = (id: string, data: Types.SubTaskData) =>
    A.post(`production/KanbanTasksUR/${id}/subTasks`, data)

  putKanbanTaskURState = (id: string, state: string) =>
    A.put(`production/KanbanTasksUR/${id}/state`, state, { headers: { 'Content-Type': 'application/json' } })

  putKanbanTaskURPosition = (kanbanTaskKey: string, beforeKey: string | null, afterKey: string | null) =>
    A.put(`production/KanbanTasks/position`, undefined, { params: { kanbanTaskKey, beforeKey, afterKey } })

  // #endregion

  // #region Layouts
  postMaintenanceLineLayout = (data: FormData) => A.post(`production/Layouts/maintenance-layout`, data)

  putMaintenanceLineLayout = (id: string, data: Types.PutLineLayoutDto) =>
    A.put(`production/Layouts/maintenance-layout/${id}`, data)

  deleteMaintenanceLineLayout = (id: string) => A.delete(`production/Layouts/maintenance-layout/${id}`)

  getMaintenanceLineLayout = (lineId: string) =>
    A.get<Types.GetMaintenanceLineLayoutApiResponse>(`production/Layouts/maintenance-layout/${lineId}`)

  postForemanLineLayout = (data: FormData) => A.post(`production/Layouts/foreman-layout`, data)

  putForemanLineLayout = (id: string, data: Types.PutLineLayoutDto) =>
    A.put(`production/Layouts/foreman-layout/${id}`, data)

  deleteForemanLineLayout = (id: string) => A.delete(`production/Layouts/foreman-layout/${id}`)

  getForemanLineLayout = (lineId: string) =>
    A.get<Types.GetForemanLineLayoutApiResponse>(`production/Layouts/foreman-layout/${lineId}`)
  // #endregion

  // #region Machines
  getWorkstations = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetWorkstationsApiResponse>('production/Machines', { params })

  postWorkstation = (data: Types.PostWorkstationDto) => A.post('production/Machines', data)

  getWorkstation = (id: string) => A.get<Types.GetWorkstationApiResponse>(`production/Machines/${id}`)

  updateWorkstation = (id: string, data: Types.PutWorkstationDto) => A.put(`production/Machines/${id}`, data)

  deleteWorkstation = (id: string) => A.delete(`production/Machines/${id}`)

  getActiveMachines = () => A.get<Types.GetActiveMachinesApiResponse>('production/Machines/active-machines')

  getAllWorkstations = (params?: Types.GetAllWorkstationsApiParams) =>
    A.get<Types.GetAllWorkstationsApiResponse>('production/Machines/all-machines', { params })
  // #endregion

  // #region MachineStates

  getMachineStates = (machineId: string, params: Types.GetMachineStatesParams) =>
    A.get<Types.GetMachineStatesApiResponse>(`production/MachineStates/${machineId}`, { params })

  putMachineIdlesReason = (machineId: string, data: Types.PutMachineIdlesReasonsData) =>
    A.put(`production/MachineStates/${machineId}/idle`, data)

  // #endregion

  // #region MaintenancePlanner
  addMaintenancePlanner = (data: Types.PostMaintenancePlannerDto) => A.post('production/MaintenancePlanners', data)

  getMaintenancePlanner = (id: string) =>
    A.get<Types.GetMaintenancePlannerApiResponse>(`production/MaintenancePlanners/${id}`)

  updateMaintenancePlanner = (id: string, data: Types.PostMaintenancePlannerDto) =>
    A.put(`production/MaintenancePlanners/${id}`, data)

  getMaintenancePlanners = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetMaintenancePlannersApiResponse>('production/MaintenancePlanners', { params })

  deleteMaintenancePlanner = (id: string) => A.delete(`production/MaintenancePlanners/${id}`)

  // #endregion

  // #region MaintenanceTasks
  getMaintenanceTasks = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetMaintenanceTasksApiResponse>('production/MaintenanceTasks', { params })

  getMaintenanceTask = (id: string) => A.get<Types.MaintenanceTaskDto>(`production/MaintenanceTasks/${id}`)

  deleteMaintenanceTask = (id: string) => A.delete(`production/MaintenanceTasks/${id}`)
  // #endregion

  // #region Notification
  getUnreadNotificationCountForUser = () => A.get<number>(`Notification/unread`)

  getUserNotifications = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetUserNotificationsApiResponse>(`Notification/notifications-user`, { params })

  getLineNotifications = (params: Types.GetLineNotificationsApiParams) =>
    A.get<Types.GetLineNotificationsApiResponse>(`Notification/notifications-line`, { params })

  getNotificationTypes = () => A.get<Types.GetNotificationDefinitionTypesApiResponse>('Notification/notification-types')

  getNotificationChannels = () =>
    A.get<Types.GetNotificationDefinitionChannelsApiResponse>('Notification/notification-channels')

  postNotificationDefinition = (data: Types.PostNotificationDefinitionDto) => A.post('Notification/definitions', data)

  getAdminNotificationDefinitions = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetNotificationDefinitionsApiResponse>('Notification/definitions', { params })

  updateNotificationDefinition = (id: string, data: Types.PutNotificationDefinitionDto) =>
    A.put(`Notification/definitions/${id}`, data)

  deleteNotificationDefinition = (id: string) => A.delete(`Notification/definitions/${id}`)

  getNotificationDefinition = (id: string) =>
    A.get<Types.GetNotificationDefinitionApiResponse>(`Notification/definitions/${id}`)

  setNotificationsRead = (ids: string[]) => A.put('Notification/deliver', ids)

  setOperatorNotificationsRead = (ids: string[]) => A.put('Notification/operator-deliver', ids)
  // #endregion

  // #region Orders
  postOrder = (data: Types.PostOrderDto) => A.post(`Orders`, data)

  getOrders = (params: Types.GetOrdersApiParams) => A.get<Types.GetOrdersApiResponse>(`Orders`, { params })

  getOrder = (id: number) => A.get<Types.GetOrderApiResponse>(`Orders/${id}`)

  getOrderDetails = (id: number) => A.get<Types.OrderDto>(`Orders/${id}/details`)

  deleteOrder = (id: number) => A.delete(`Orders/${id}`)

  putOrder = (id: number, data: Types.PutOrderDto) => A.put(`Orders/${id}`, data)

  getOrderStatusTypes = () => A.get<Types.GetOrderStatusTypesApiResponse>('Orders/status-types')

  getOrdersForMachine = (id: string) => A.get<Types.GetOrdersForMachineApiResponse>(`Orders/orders-for/${id}`)

  putAddOrderComponentMachines = (orderId: number, orderComponentId: number, data: { machineIds: string[] }) =>
    A.put(`Orders/add-order-component-machines/${orderId}/${orderComponentId}`, data)

  removeOrderComponentMachine = (orderId: number, orderComponentId: number, orderComponentMachineId: string) =>
    A.delete(`Orders/remove-order-component-machine/${orderId}/${orderComponentId}/${orderComponentMachineId}`)

  putOrderElementCompletion = (data: Types.PutOrderElementCompletionDto) => A.put('Orders/complete', data)

  putOrderElementResumption = (data: Types.PutOrderElementResumptionDto) => A.put('Orders/resumption', data)

  getUnclassifiedUnitsForMachine = (id: string) =>
    A.get<Types.GetUnclassifiedUnitsForMachineApiResponse>(`Orders/unclassified-units/${id}`)

  postClassifyUnits = (data: Types.PostClassifyUnitsApiBody) =>
    A.post<Types.PostClassifyUnitsApiResponse>('Orders/classify-units', data)

  importOrders = (data: Types.PostImportOrdersDto) => A.post<Types.PostImportOrdersResponse>('Orders/import', data)

  importOrdersPreValidation = (data: Types.PostOrdersImportPreValidationDto) =>
    A.post<Types.OrdersImportPreValidationDto[]>('Orders/import-pre-validation', data)

  // #endregion

  // #region PairedMachineConnections
  getPairedWorkstations = () => A.get<Types.GetPairedWorkstationsApiResponse>('PairedMachineConnections/connections')

  disconnectPairedWorkstation = (workstationId: string) =>
    A.delete<boolean>(`PairedMachineConnections`, { params: { workstationId } })
  // #endregion

  // #region PermissionDefinitions
  getPermissionDefinitions = () => A.get<Types.GetPermissionDefinitionsApiResponse>('users/PermissionDefinitions')
  // #endregion

  // #region PotentialFailures
  resolvePotentialFailureAsFailure = (workstationId: string, data: Types.PutWorkstationFailureReasonDto) =>
    A.post(`production/PotentialFailure/${workstationId}/resolve-potential-failure`, data)

  resolvePotentialFailureAsWorkWithFailure = (
    workstationId: string,
    data: Types.PutWorkstationWorkWithFailureReasonDto,
  ) => A.post(`production/PotentialFailure/${workstationId}/resolve-potential-failure-as-work-with-failure`, data)

  cancelPotentialFailure = (workstationId: string) =>
    A.put(`production/PotentialFailure/${workstationId}/cancel-potential-failure`)

  reportIdleFromPotentialFailure = (workstationId: string, data: { reasonId?: number; reasonDescription?: string }) =>
    A.put(`production/PotentialFailure/${workstationId}/report-idle-from-potential-failure`, data)
  // #endregion

  // #region ProductionLines
  getLines = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetProductionLinesApiResponse>('production/ProductionLines', { params })

  postLine = (data: Types.PostLineDto) => A.post('production/ProductionLines', data)

  getLine = (id: string) => A.get<Types.GetProductionLineApiResponse>(`production/ProductionLines/${id}`)

  updateLine = (id: string, data: Types.PutLineDto) => A.put(`production/ProductionLines/${id}`, data)

  deleteLine = (id: string) => A.delete(`production/ProductionLines/${id}`)

  getLinesNames = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetProductionLineNamesApiResponse>('production/ProductionLines/names', { params })

  getLineStatus = (id: string) => A.get<Types.GetLineStatusApiResponse>(`production/ProductionLines/${id}/status`)

  getWorkstationsByLine = (lineId: string, params: Types.GetWorkstationsByLineApiParams) =>
    A.get<Types.GetWorkstationsByLineApiResponse>(`production/ProductionLines/${lineId}/machines`, { params })

  getLineMachinesInTime = (id: string, params: Types.GetProductionLineMachinesInTimeApiParams) =>
    A.get<Types.GetProductionLineMachinesInTimeApiResponse>(`production/ProductionLines/${id}/machines-in-time`, {
      params,
    })

  getInvalidUnitsForLine = (lineId: string, params: Types.GetInvalidUnitsApiParams) =>
    A.get<Types.GetInvalidUnitsApiResponse>(`production/ProductionLines/${lineId}/invalid-units`, { params })

  getComponentScrapsForLine = (lineId: string, params: Types.GetComponentScrapsApiParams) =>
    A.get<Types.GetComponentScrapsApiResponse>(`production/ProductionLines/${lineId}/component-scraps`, { params })

  getProducionPlanForLine = (lineId: string, params: Types.GetProductionPlanApiParams) =>
    A.get<Types.GetProductionPlanForLineApiResponse>(`production/ProductionLines/${lineId}/chart-data`, {
      timeout: 60 * 1000,
      params,
    })

  getProductionPlanForIntervalsForLine = (lineId: string, params: Types.GetProductionPlanApiParams) =>
    A.get<Types.GetProductionPlanForIntervalsForLineChartDataApiResponse>(
      `production/ProductionLines/${lineId}/chart-data-for-intervals`,
      {
        timeout: 60 * 1000,
        params,
      },
    )

  getForemanProductivityChartForLine = (lineId: string, params: Types.GetProductivityChartDataApiParams) =>
    A.get<Types.GetProductivityChartDataForLineApiResponse>(
      `production/ProductionLines/${lineId}/productivity-chart-data`,
      {
        timeout: 60 * 1000,
        params,
      },
    )

  getYamazumiChartForLine = (lineId: string, params: Types.GetYamazumiChartDataForLineApiParams) =>
    A.get<Types.GetYamazumiChartDataForLineApiResponse>(`production/ProductionLines/${lineId}/yamazumi-chart`, {
      timeout: 60 * 1000,
      params,
    })

  getFinishedProductsChartData = (lineId: string, params: Types.GetFinishedProductsChartDataApiParams) =>
    A.get<Types.GetFinishedProductsChartDataApiResponse>(
      `production/ProductionLines/${lineId}/finished-products-chart-data`,
      {
        timeout: 60 * 1000,
        params,
      },
    )
  // #endregion

  // #region Reports
  getChartDataReportForLine = (
    params: Types.GetChartDataReportForLineApiParams,
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/production-line-chart-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getChartDataReportForWorkstation = (
    params: {
      workstationId: string
      from: string
      to: string
      theoreticalProductionChecked: boolean
      expectedProductionChecked: boolean
      realizationProductionChecked: boolean
      oeeChecked: boolean
      interval: number
    },
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/workstation-chart-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getWorkstationStatesReport = (
    params: { workstationId: string; from: string; to: string },
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/states-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getWorkstationProductionDetailsReport = (
    params: {
      workstationId: string
      from: string
      to: string
    },
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/production-details-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getInvalidUnitsReportForWorkstation = (
    params: { workstationId: string; from: string; to: string },
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/workstation-invalid-units-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getComponentScrapsReportForWorkstation = (
    params: { workstationId: string; from: string; to: string },
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/workstation-component-scraps-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getInvalidUnitsReportForLine = (
    params: { productionLineId: string; from: string; to: string },
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/production-line-invalid-units-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getComponentScrapsReportForLine = (
    params: { productionLineId: string; from: string; to: string },
    languageCode: string,
    config?: AxiosRequestConfig,
  ) =>
    A.get<number>(`production/Reports/production-line-component-scraps-report`, {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
      params,
    })

  getFilesAcknowledgmentsReport = (languageCode: string, config?: AxiosRequestConfig) =>
    A.get<number>('production/Reports/files-acknowledgments-report', {
      ...config,
      headers: {
        [ApiHeaders.LANGUAGE_CODE]: languageCode,
        ...config?.headers,
      },
    })

  getHeartBeatsReport = (params: Types.GetHeartBeatsHistoryReportApiParams) =>
    A.get<number>('production/Reports/heart-beats-history-report', {
      params,
    })
  // #endregion

  // #region Roles
  postRole = (params: Types.PostRoleDto) => A.post('users/Roles', params)

  deleteRole = (id: string) => A.delete(`users/Roles/${id}`)

  getRole = (id: string) => A.get<Types.GetRoleDto>(`users/Roles/${id}`)

  updateRole = (id: string, data: Types.PutRoleDto) => A.put(`users/Roles/${id}`, data)

  getRoles = (params: Types.PaginatedApiParams) => A.get<Types.GetRolesApiResponse>('users/Roles/roles', { params })
  // #endregion

  // #region ShiftConfiguration
  getShiftConfigurations = () => A.get<Types.ShiftsConfigurationDto[]>('production/ShiftConfiguration')

  postShiftConfiguration = (data: Types.PostShiftsConfigurationDto) => A.post('production/ShiftConfiguration', data)

  getShiftConfiguration = (id: string) => A.get<Types.ShiftsConfigurationDto>(`production/ShiftConfiguration/${id}`)

  getShiftConfigurationByDate = (date: string) =>
    A.get<Types.ShiftsConfigurationDto>(`production/ShiftConfiguration/${date}`)
  // #endregion

  // #region Shifts
  getShiftsByDate = (dateTime: string) => A.get<Types.ShiftDto[]>(`Shifts/day`, { params: { dateTime } })

  getFirstShiftStartDate = () => A.get<string>(`Shifts/first`)
  // #endregion

  // #region ShiftWorkstation
  getWorkstationStatus = (id: string) =>
    A.get<Types.WorkstationStatusApiResponse>(`production/ShiftWorkstation/${id}/status`)

  getWorkstationCurrentState = (id: string) =>
    A.get<Types.WorkstationCurrentStateDto>(`production/ShiftWorkstation/${id}/current-state`)

  putWorkstationStatusNormalWork = (id: string) => A.put(`production/ShiftWorkstation/${id}/normal-work`)

  putWorkstationStatusIdle = (workstationId: string, params: Types.ReasonDto) =>
    A.put(`production/ShiftWorkstation/${workstationId}/idle`, params)

  putWorkstationStatusUnclassifiedIdle = (
    workstationId: string,
    params: Types.ReasonDto,
  ) => A.put(`production/ShiftWorkstation/${workstationId}/idle-reason`, params)

  putWorkstationFailureReason = (workstationId: string, params: Types.PutWorkstationFailureReasonDto) =>
    A.put(`production/ShiftWorkstation/${workstationId}/failure`, params)

  putWorkstationWorkWithFailureReason = (
    workstationId: string,
    params: Types.PutWorkstationWorkWithFailureReasonDto,
  ) => {
    return A.put(`production/ShiftWorkstation/${workstationId}/work-with-failure`, params)
  }

  getWorkstationVariants = (workstationId: string) =>
    A.get<Types.GetWorkstationVariantsApiResponse>(`production/ShiftWorkstation/${workstationId}/variants`)

  putWorkstationRetoolStart = (
    workstationId: string,
    data: { variantId: string; orderName: string; orderId?: number; retoolReason?: string },
  ) => A.put(`production/ShiftWorkstation/${workstationId}/retool-start`, data)

  putWorkstationSuspendedRetoolStart = (
    workstationId: string,
    data: { reasonId?: number; reasonDescription?: string; previousRetoolOKs: number },
  ) => A.put(`production/ShiftWorkstation/${workstationId}/suspended-retool-start`, data)

  putWorkstationSuspendedRetoolEnd = (workstationId: string) =>
    A.put(`production/ShiftWorkstation/${workstationId}/suspended-retool-end`)

  putWorkstationRetoolEnd = (
    workstationId: string,
    data: { endsAt: string; previousRetoolOKs: number; currentRetoolOKs: number },
  ) => A.put(`production/ShiftWorkstation/${workstationId}/retool-end`, data)

  getRetoolBunches = (workstationId: string) =>
    A.get<Types.GetRetoolBunchesApiResponse>(`production/ShiftWorkstation/${workstationId}/retool-bunches`)

  getProductionPlanForWorkstation = (workstationId: string, params: Types.GetProductionPlanApiParams) =>
    A.get<Types.GetProductionPlanForWorkstationApiResponse>(`production/ShiftWorkstation/${workstationId}/chart-data`, {
      timeout: 60 * 1000,
      params,
    })

  getProductionPlanForIntervalsForWorkstation = (lineId: string, params: Types.GetProductionPlanApiParams) =>
    A.get<Types.GetProductionPlanForIntervalsForWorkstationsApiResponse>(
      `production/ShiftWorkstation/${lineId}/chart-data-for-intervals`,
      {
        timeout: 60 * 1000,
        params,
      },
    )

  getUnreadNotificationCount = (workstationId: string) =>
    A.get<number>(`production/ShiftWorkstation/${workstationId}/unread-notifications`)

  postWorkstationForemanArrivalRequest = (
    workstationId: string,
    params: Types.ReasonDto,
  ) => A.post(`production/ShiftWorkstation/${workstationId}/foreman-arrival-request`, params)

  getOperatorNotifications = (workstationId: string, params: Types.PaginatedApiParams) =>
    A.get<Types.GetOperatorNotificationsApiResponse>(`production/ShiftWorkstation/${workstationId}/notifications`, {
      params,
    })

  getWorkstationCatalog = (workstationId: string, params: { catalogId: number }) =>
    A.get<Types.MachineCatalogDto>(`production/ShiftWorkstation/${workstationId}/catalog`, { params })

  postIncident = (workstationId: string) =>
    A.post(`production/ShiftWorkstation/${workstationId}/incidents`, { reasonDescription: 'SOS' })

  getInvalidUnitsForWorkstation = (workstationId: string, params: Types.GetInvalidUnitsApiParams) =>
    A.get<Types.GetInvalidUnitsApiResponse>(`production/ShiftWorkstation/${workstationId}/invalid-units`, { params })

  getComponentScrapsForWorkstation = (workstationId: string, params: Types.GetComponentScrapsApiParams) =>
    A.get<Types.GetComponentScrapsApiResponse>(`production/ShiftWorkstation/${workstationId}/component-scraps`, {
      params,
    })

  getWorkstationFailureCheckList = (workstationId: string) =>
    A.get<Types.GetWorkstationFailureCheckListApiResponse>(`production/ShiftWorkstation/${workstationId}/check-list`)

  /** diffrent response model from same route depends on onlyToAcknowledge param */
  getWorkstationFiles = (
    workstationId: string,
    params: Types.PaginatedApiParams & { onlyToAcknowledge?: boolean },
    config?: Omit<AxiosRequestConfig, 'params'>,
  ) =>
    A.get<Types.GetWorkstationFilesApiResponse>(`production/ShiftWorkstation/${workstationId}/files`, {
      ...config,
      params: { ...params, onlyToAcknowledge: false },
    })

  /** diffrent response model from same route depends on onlyToAcknowledge param */
  getFilesToAcknowledge = (workstationId: string, config?: Omit<AxiosRequestConfig, 'params'>) =>
    A.get<Types.GetFilesToAcknowledgeApiResponse>(`production/ShiftWorkstation/${workstationId}/files`, {
      ...config,
      params: { onlyToAcknowledge: true },
    })

  getMechanicFilesToAcknowledge = (machineId: string) =>
    A.get<Types.GetMechanicFilesToAcknowledgeApiResponse>(
      `production/ShiftWorkstation/${machineId}/files-ur-to-acknowledge`,
    )

  getMechanicFiles = (machineId: string) =>
    A.get<Types.GetMechanicFilesApiResponse>(`production/ShiftWorkstation/${machineId}/files-ur`)

  getWorkstationCurrentOrder = (id: string) =>
    A.get<Types.WorkstationCurrentOrderApiResponse>(`production/ShiftWorkstation/${id}/current-order`)

  getFailureUnitsProducedAmount = (workstationId: string) =>
    A.get<number>(`production/ShiftWorkstation/${workstationId}/failure-units`)

  getWorkstationRetoolUnitsCount = (workstationId: string) =>
    A.get<number>(`production/ShiftWorkstation/${workstationId}/retool-units`)

  postValidFailureUnits = (workstationId: string, data: { unitCount: number }) =>
    A.post(`production/ShiftWorkstation/${workstationId}/transform-failure-bunches`, data)

  postInvalidUnits = (workstationId: string, params: { invalidBunches: Types.InvalidBunch[] }) =>
    A.post(`production/ShiftWorkstation/${workstationId}/invalid`, params)

  postPotentialNOKs = (workstationId: string, params: { potentialNOKs: Types.InvalidBunch[] }) =>
    A.post(`production/ShiftWorkstation/${workstationId}/resolve`, params)

  changeSuperOperatorWorkstation = (workstationId: string, params: { previousWorkstationId: string }) =>
    A.post(`production/ShiftWorkstation/${workstationId}/change-workstation`, params)

  postRework = (workstationId: string, data: Types.ReasonDto) =>
    A.post(`production/ShiftWorkstation/${workstationId}/rework`, data)

  postReworkAgain = (workstationId: string, bunchId: string) =>
    A.post(`production/ShiftWorkstation/${workstationId}/rework-again`, undefined, { params: { bunchId } })

  getProductivityChartDataFroWorkstation = (workstationId: string, params: Types.GetProductivityChartDataApiParams) =>
    A.get<Types.GetProductivityChartDataForWorkstationApiResponse>(
      `production/ShiftWorkstation/${workstationId}/productivity-data`,
      {
        timeout: 60 * 1000,
        params,
      },
    )

  postTransformReworkBunch = (workstationId: string, params: { bunchId: string; isOk: boolean }) =>
    A.post<undefined, AxiosResponse<{ invalidBunchId: string }>>(
      `production/ShiftWorkstation/${workstationId}/transform-rework-bunch`,
      undefined,
      { params },
    )

  getReworkBunches = (workstationId: string) =>
    A.get<Types.GetReworkBunchesApiResponse>(`production/ShiftWorkstation/${workstationId}/rework-bunches`)

  postOperatorWorkStart = (workstationId: string) =>
    A.post(`production/ShiftWorkstation/${workstationId}/operator-work-start`, undefined, {
      headers: { [ApiHeaders.ORIGIN_APP]: AppNames.OperatorPanel },
    })

  postOperatorWorkEnd = (workstationId: string) =>
    A.post(`production/ShiftWorkstation/${workstationId}/operator-work-end`, undefined, {
      headers: { [ApiHeaders.ORIGIN_APP]: AppNames.OperatorPanel },
    })

  postForceOperatorWorkEnd = (workstationId: string) =>
    A.post(`production/ShiftWorkstation/${workstationId}/force-operator-work-end`)

  getWorkstationFinishedProductsChartData = (
    workstationId: string,
    params: Types.GetFinishedProductsChartDataApiParams,
  ) =>
    A.get<Types.GetFinishedProductsChartDataApiResponse>(
      `production/ShiftWorkstation/${workstationId}/finished-products-chart-data`,
      {
        timeout: 60 * 1000,
        params,
      },
    )

  getMultiOperators = (workstationId: string) =>
    A.get<Types.GetMultiOperatorsApiResponse>(`production/ShiftWorkstation/${workstationId}/multi-operators`)
  // #endregion

  // #region Tasks
  postRepairTask = (data: Types.PostRepairTaskDto) => A.post('tasking/Tasks/repair-task', data)

  postMaintenanceTask = (data: Types.PostMaintenanceTaskDto) => A.post('tasking/Tasks/maintenance-task', data)

  postMechanicRepairTask = (data: Types.PostRepairTaskByMechanicDto) =>
    A.post('tasking/Tasks/repair-task-create-by-mechanics', data)

  getMechanicTasks = () => A.get<Types.GetMechanicTasksApiResponse>(`tasking/Tasks/mechanic`)

  setTaskStarted = (id: string) => A.put(`/tasking/Tasks/${id}/start`)

  setTaskFailed = (id: string, params: Types.ReasonDto) =>
    A.put(`/tasking/Tasks/${id}/fail`, undefined, { params })

  setTaskSucceeded = (id: string, params: { reasonDescription?: string }) =>
    A.put(`/tasking/Tasks/${id}/success`, undefined, { params })

  getTaskDetails = (id: string) => A.get<Types.MechanicTaskDetailsDto>(`/tasking/Tasks/${id}`)

  editTask = (id: string, params: { durationInMin?: number; userId?: string; startDate?: string }) =>
    A.put(`tasking/Tasks/${id}`, undefined, { params })

  setTaskStopped = (id: string, params: { reasonDescription?: string }) =>
    A.put(`/tasking/Tasks/${id}/stop`, undefined, { params })

  getTaskMachineRepairHistory = (workstationId: string, params: Types.PaginatedApiParams) =>
    A.get<Types.GetMachineRepairHistoryApiResponse>(`/tasking/Tasks/${workstationId}/machine-tasks`, { params })
  // #endregion

  // #region Technologies
  addTechnology = (data: Types.PostTechnologyDto) => A.post(`production/Technologies`, data)

  getTechnologies = (params: { page: number; pageSize: number }) =>
    A.get<Types.TechnologiesDtoResponse>('production/Technologies', { params })

  updateTechnology = (id: string, data: Types.PutTechnologyDto) => A.put(`production/Technologies/${id}`, data)

  getTechnology = (id: string) => A.get<Types.TechnologyDto>(`production/Technologies/${id}`)

  deleteTechnology = (id: string) => A.delete(`production/Technologies/${id}`)

  getAllTechnologiesList = () => A.get<Types.GetTechnologyOptionDto[]>('production/Technologies/names')
  // #endregion

  // #region Translations
  getDefaultTranslation = () => A.get(`localizations/translations/default`)

  getTranslationsOfLanguages = () =>
    A.get<Types.GetTranslationsOfLanguagesApiResponse>('localizations/Translations/languages')

  deleteTranslation = (id: string) => A.delete(`localizations/Translations/${id}`)

  putTranslation = (id: string, data: Types.PutTranslationDto) => A.put(`localizations/Translations/${id}`, data)

  postTranslation = (data: Types.PostTranslationDto) => A.post(`localizations/Translations`, data)

  getTranslation = (id: string) => A.get<Types.GetTranslationDto>(`localizations/Translations/language/${id}`)

  getTranslationValues = (languageCode: string) =>
    A.get<Types.TranslationValuesDto>(`localizations/Translations/${languageCode}/values`)

  getActiveTranslationsOfLanguages = () =>
    A.get<Types.ActiveTranslationDto[]>('localizations/Translations/languages/true')

  getLanguageOptions = () => A.get<Types.LanguageOptionDto[]>('localizations/Translations/languages/definitions')

  getTranslationIdByLanguageCode = (languageCode: string) => A.get<string>(`localizations/Translations/${languageCode}`)
  // #endregion

  // #region URFiles
  getMaintenanceFile = (id: string) => A.get<Types.MaintenanceFileDto>(`production/URFiles/${id}`)

  updateMaintenanceFile = (id: string, request: Types.PutMaintenanceFileDto) =>
    A.put(`production/URFiles/${id}`, request)

  deleteMaintenanceFile = (id: string) => A.delete(`production/URFiles/${id}`)

  getURFiles = (params: Types.PaginatedURFilesApiParams) =>
    A.get<Types.GetMaintenanceFilesApiResponse>('production/URFiles', {
      params,
    })

  postURFile = (request: FormData) => A.post('production/URFiles', request)

  getMaintenanceFileTypes = () => A.get<Types.GetMaintenanceFileTypesApiResponse>('production/URFiles/types')
  // #endregion

  // #region Users
  postUser = (data: Types.PostUserDto) => A.post('users/Users', data)

  getAdminUsers = (params: Types.PaginatedApiParams) => A.get<Types.GetUsersApiResponse>('users/Users', { params })

  importUsers = (data: Types.PostImportUsersDto) => A.post('users/Users/import', data)

  activateUser = (data: Types.PostActivateUserDto) =>
    A.post('users/Users/activate', { ...data, password: Buffer.from(data.password).toString('base64') })

  resetPassword = (email: string) =>
    A.post('users/Users/reset-password', { email: Buffer.from(email).toString('base64') })

  changePassword = ({ password, ...params }: { token: string; email: string; password: string }) =>
    A.post(
      `users/Users/change-password`,
      {
        password: Buffer.from(password).toString('base64'),
      },
      { params },
    )

  resendActivationLink = (id: string) => A.post(`users/Users/${id}/resend-activation-link`)

  getUser = (id: string) => A.get<Types.GetUserApiResponse>(`users/Users/${id}`)

  deleteUser = (id: string) => A.delete(`users/Users/${id}`)

  updateUser = (id: string, data: Types.PutUserDto) => A.put(`users/Users/${id}`, data)

  checkActivationToken = (params: { token: string; email: string }) =>
    A.get<boolean>('users/Users/check-activation-token', { params })

  checkResetPasswordToken = (params: { token: string; email: string }) =>
    A.get<boolean>('users/Users/check-reset-password-token', { params })
  // #endregion

  // #region Variants
  getVariants = (params: Types.PaginatedApiParams) =>
    A.get<Types.GetVariantsApiResponse>('production/Variants', { params })

  postVariant = (data: Types.PostVariantDto) => A.post('production/Variants', data)

  getVariant = (id: string) => A.get<Types.GetVariantApiResponse>(`production/Variants/${id}`)

  updateVariant = (id: string, data: Types.PutVariantDto) => A.put(`production/Variants/${id}`, data)

  deleteVariant = (id: string) => A.delete(`production/Variants/${id}`)

  getVariantsWithMachines = (params: Types.VariantsWithMachinesApiParams) =>
    A.get<Types.VariantsWithMachinesApiResponse>(`production/Variants/variants-machines`, {
      params,
    })

  importVariants = (data: Types.PostImportVariantsDto) => A.post('production/Variants/import', data)

  getAllVariants = (params?: Types.GetAllVariantsApiParams) =>
    A.get<Types.GetAllVariantsApiResponse>('production/Variants/all', { params })

  getActiveVariantsMachines = () => A.get<Types.MachineVariantDto[]>('production/Variants/active-variants-machines')

  postVariantsMachinesBulkAdd = (data: Types.PostVariantsMachinesDto) =>
    A.post('production/Variants/variants-machines-bulk-add', data)

  putVariantsMachinesBulkEdit = (data: Types.PutVariantsMachinesDto) =>
    A.put('production/Variants/variants-machines-bulk-edit', data)

  deleteVariantsMachines = (data: Types.DeleteMachinesVariantsDto) =>
    A.delete('production/Variants/variants-machines-bulk-delete', { data })
  // #endregion

  // #region WorkstationConfiguration
  putWorkstationProducedUnitsCount = (id: string, value: number) =>
    A.put(`production/WorkstationConfiguration/${id}/actual`, undefined, { params: { value } })
  // #endregion

  // #region WorkstationFailureReports
  getFailureReports = (params?: { lineId?: string }) =>
    A.get<Types.GetFailureReportsApiResponse>('production/WorkstationFailureReports', { params })

  /**
   * Only for feature flag MechanicCanSelfAssignToTask
   */
  getFailureReportsForMechanic = (params?: { lineId?: string }) =>
    A.get<Types.GetFailureReportsApiResponse>('production/WorkstationFailureReports/for-mechanics', { params })

  getFailureReport = (id: string) => A.get<Types.FailureReportDetailsDto>(`production/WorkstationFailureReports/${id}`)
  // #endregion

  // #region WorkstationProduction

  postCancelBunch = (bunchId: string, workstationId: string) =>
    A.post(`production/ShiftWorkstation/${workstationId}/cancel-bunch/${bunchId}`)

  getTimeLineDetails = (workstationId: string, params: Types.GetTimeLineDetailsApiParams) =>
    A.get<Types.GetTimeLineDetailsApiResponse>(`production/ShiftWorkstation/${workstationId}/timeline-details`, {
      params,
    })

  postReportOkBunch = (workstationId: string) => A.post(`production/ShiftWorkstation/${workstationId}/report-ok-bunch`)

  postReportNokBunch = (workstationId: string, data: Types.PostReportNokBunchDto) =>
    A.post(`production/ShiftWorkstation/${workstationId}/report-nok-bunch`, data)

  postClassifyInvalidBunch = (workstationId: string, bunchId: string, data: Types.PostClassifyInvalidBunchDto) =>
    A.post(`production/ShiftWorkstation/${workstationId}/classify-invalid-bunch/${bunchId}`, data)
  // #endregion
}

export const apiClient = new ApiClient()

export { A as ApiAxiosInstance }
