import React, { useEffect, useState } from 'react'
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material'

export type TooltipProps = {
  disableHoverListener?: boolean
} & MuiTooltipProps

const Tooltip: React.FC<TooltipProps> = ({ children, disableHoverListener, ...otherProps }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setShowTooltip(false)
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('touchmove', handleScroll)

    return () => {
      window.removeEventListener('touchmove', handleScroll)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <MuiTooltip
      open={showTooltip && !disableHoverListener}
      onOpen={() => setShowTooltip(true)}
      leaveDelay={0}
      leaveTouchDelay={0}
      {...otherProps}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: ({ palette }) => palette.background.paper,
            color: ({ palette }) => palette.text.primary,
            minWidth: 128,
            width: 'auto',
            boxShadow: ({ shadows }) => (shadows as string[])[3],
            borderRadius: 2,
            height: 'auto',
            textAlign: 'center',
            paddingX: ({ spacing }) => spacing(2),
            paddingY: ({ spacing }) => spacing(1),
            maxHeight: '45vh',
            overflow: 'auto',
          },
        },
      }}
    >
      {React.cloneElement(children, {
        onTouchStart: () => setShowTooltip(true),
        onMouseOver: () => setShowTooltip(true),
        onMouseLeave: () => setShowTooltip(false),
        onTouchEnd: () => setShowTooltip(true),
      })}
    </MuiTooltip>
  )
}

export default Tooltip
