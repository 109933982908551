import React from 'react'
import { useTranslation } from 'react-i18next'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Button, Stack, Typography } from '@mui/material'

type Props = {
  onRetryClick?: () => void
  disabledRetry?: boolean
}

const ErrorLoadingData = ({ onRetryClick, disabledRetry }: Props) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={3} p={4} alignItems='center'>
      <ErrorOutlineIcon fontSize='large' color='error' />
      <Stack spacing={1} alignItems='center'>
        <Typography fontSize={20} fontWeight='medium' textAlign='center'>
          {t('messages.errorLoadingData')}
        </Typography>
        <Typography fontSize={16} color='text.secondary' textAlign='center'>
          {t('messages.pleaseTryToLoadTheDataAgain')} {t('messages.ifThatDoestHelpRefreshTheEntirePage')}
        </Typography>
        {onRetryClick && (
          <Button variant='text' onClick={onRetryClick} disabled={disabledRetry}>
            {t('labels.tryAgain')}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default ErrorLoadingData
