import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { apiClient } from 'Api/ApiClient'
import HubMethods from 'Consts/HubMethods'
import { useDialogsContext } from 'Context/DialogsContext'
import { useMachineContext } from 'Context/MachineContext'
import { useSignalRContext } from 'Context/SignalRContext'

import { MultipleUnitsDialog } from './MultipleUnitsDialog'

const WorkingWithFailureHandler = () => {
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const { machine, setMachine } = useMachineContext()
  const { hubConnection } = useSignalRContext()
  const { multipleUnitsDialogOpen, setMultipleUnitsDialogOpen } = useDialogsContext()
  const { t } = useTranslation()

  useEffect(() => {
    const handleStartedNormalWorkMessage = () => {
      setMachine((prevMachine) => {
        if (prevMachine) {
          return {
            ...prevMachine,
            actualUnitProductionCount: prevMachine.defaultUnitProductionCount,
          }
        }

        return prevMachine
      })
    }

    hubConnection?.on(HubMethods.StartedNormalWork, handleStartedNormalWorkMessage)

    return () => hubConnection?.off(HubMethods.StartedNormalWork, handleStartedNormalWorkMessage)
  }, [hubConnection, machine])

  if (!machine) {
    return null
  }

  return (
    <MultipleUnitsDialog
      open={multipleUnitsDialogOpen}
      onAccept={(count) => {
        setButtonDisabled(true)
        apiClient
          .putWorkstationProducedUnitsCount(machine.id, count)
          .then(() => {
            setMachine((prevMachine) => {
              if (prevMachine) {
                return {
                  ...prevMachine,
                  actualUnitProductionCount: count,
                }
              }

              return prevMachine
            })
            setMultipleUnitsDialogOpen(false)
          })
          .finally(() => setButtonDisabled(false))
      }}
      title={t('messages.theMachineCameIntoSlowedDownMode')}
      actionDescription={t('messages.indicateTheNumberOfItemsProducedPerCycle')}
      actualUnitProductionCount={machine?.defaultUnitProductionCount || 0}
      buttonDisabled={buttonDisabled}
    />
  )
}

export default WorkingWithFailureHandler
