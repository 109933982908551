import { useController, useFormContext } from 'react-hook-form'
import { Box, Checkbox, type CheckboxProps, Typography } from '@mui/material'

import type { FormField } from './types'

export type FormCheckboxProps = FormField & Omit<CheckboxProps, 'name'>

export const FormCheckbox = (props: FormCheckboxProps) => {
  const { control } = useFormContext()
  const { field } = useController({ name: props.name, control })

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        {...props}
        name={field.name}
        checked={!!field.value}
        onChange={field.onChange}
        sx={{
          width: { sx: '1rem', xxl: '3rem' },
          height: { sx: '1rem', xxl: '3rem' },
          transform: { xxl: 'scale(2)' },
          color: 'primary.main',
          marginLeft: '-12px',
          ...props.sx,
        }}
      />
      <Typography variant='body2' pl={{ xxl: 2 }}>
        {props.label}
      </Typography>
    </Box>
  )
}
