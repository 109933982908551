import React, { Suspense } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { styled } from '@mui/material/styles'

import AppContext from 'Context'
import { DataLoggerContextProvider } from 'Context/DataLoggerContext'
import { FeatureFlagsProvider } from 'Context/FeatureFlagsContext'
import LocalizationContext from 'Context/LocalizationContext'
import { ServiceWorkerProvider } from 'Context/ServiceWorkerContext'
import { SignalRContextProvider } from 'Context/SignalRContext'
import { TranslationsContextProvider } from 'Context/TranslationsContext'
import { Sidebar } from 'Layout'
import GlobalStateHandlers from 'Layout/GlobalStateHandlers'
import { ErrorBoundary } from 'Lib/error-tracker'
import { queryClient } from 'Lib/react-query'
import ThemeProvider from 'Theme/Provider'

import AppInstallation from './Layout/AppInstallation'
import ContentContainer from './Layout/ContentContainer'
import ResizeHandler from './Layout/ResizeHandler'
import Routes from './Routes'

const StyledApp = styled('div')(({ theme: muiTheme }) => ({
  backgroundColor: muiTheme.palette.background.default,
}))

const App = () => (
  <ServiceWorkerProvider>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Router>
          <ErrorBoundary>
            <FeatureFlagsProvider>
              <AppContext>
                <TranslationsContextProvider>
                  <DataLoggerContextProvider>
                    <LocalizationContext>
                      <SignalRContextProvider>
                        <ResizeHandler>
                          <AppInstallation>
                            <StyledApp>
                              <Sidebar />
                              <ContentContainer>
                                <Suspense fallback={<div />}>
                                  <Routes />
                                </Suspense>
                                <GlobalStateHandlers />
                              </ContentContainer>
                            </StyledApp>
                          </AppInstallation>
                        </ResizeHandler>
                      </SignalRContextProvider>
                    </LocalizationContext>
                  </DataLoggerContextProvider>
                </TranslationsContextProvider>
              </AppContext>
            </FeatureFlagsProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
    <CssBaseline />
  </ServiceWorkerProvider>
)

export default App
