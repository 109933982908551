import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useMachineContext } from 'Context/MachineContext'
import { useUser as useUserContext } from 'Context/UserContext'
import useDataLoggerStatus from 'Hooks/dataLoggers/useDataLoggerStatus'
import DataLoggerDisconnected from 'Layout/GlobalStateHandlers/SharedHandlers/SnackbarHandler/DataLoggerDisconnected'
import paths from 'Routes/paths'

interface DataLoggerContext {
  loggerDisconnected: boolean
}

const DataLoggerContext = createContext<DataLoggerContext | undefined>(undefined)

export function useDataLoggerContext() {
  const context = useContext(DataLoggerContext)
  if (context === undefined) {
    throw new Error('useDataLoggerContext must be within DataLoggerContextProvider')
  }

  return context
}

export const DataLoggerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loggerDisconnected, setLoggerDisconnected] = useState<boolean>(false)
  const [isClosed, setIsClosed] = useState(false)
  const { machine } = useMachineContext()
  const { user, selectedApp } = useUserContext()
  const { pathname } = useLocation()
  const isRfidLoginPage = pathname === paths.rfidLogin
  const userLoggedInWithRfid = user?.loginMethod === 'rfid'
  const isSelectedOperatorApp = selectedApp === 'OperatorPanel'
  const machineId = machine?.id
  const isDataLoggerStatusEnabled = !!machineId && (userLoggedInWithRfid || isSelectedOperatorApp || isRfidLoginPage)

  useDataLoggerStatus(machineId!, {
    onSuccess: (response) => {
      if (!!response && !response.isOnline) {
        setLoggerDisconnected(true)
        setIsClosed(false)
      } else {
        setLoggerDisconnected(false)
      }
    },
    enabled: isDataLoggerStatusEnabled,
  })

  useEffect(() => {
    if (!isDataLoggerStatusEnabled) {
      setLoggerDisconnected(false)
    }
  }, [isDataLoggerStatusEnabled])

  const value = useMemo(() => ({ loggerDisconnected }), [loggerDisconnected])

  return (
    <DataLoggerContext.Provider value={value}>
      {children}
      {loggerDisconnected && <DataLoggerDisconnected open={!isClosed} onClose={() => setIsClosed(true)} />}
    </DataLoggerContext.Provider>
  )
}

export const DataLoggerContextProviderMock: React.FC<{ value?: DataLoggerContext }> = ({ children, value }) => {
  const defaultValue: DataLoggerContext = useMemo(
    () => ({
      loggerDisconnected: false,
    }),
    [],
  )

  return <DataLoggerContext.Provider value={value ?? defaultValue}>{children}</DataLoggerContext.Provider>
}
