import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import HubMethods from 'Consts/HubMethods'
import { useSignalRContext } from 'Context/SignalRContext'
import { useUser as useUserContext } from 'Context/UserContext'
import useFilesToAcknowledge from 'Hooks/files/useFilesToAcknowledge'
import useMarkAsReadFile from 'Hooks/files/useMarkAsReadFile'
import PdfFileDialog from 'Layout/Modals/PdfFileDialog'
import paths from 'Routes/paths'

interface Props {
  machineId: string
}

const FilesToAcknowledge: React.FC<Props> = ({ machineId }) => {
  const { hubConnection } = useSignalRContext()
  const { selectedApp } = useUserContext()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()

  const isFilesToAcknowledgeEnabled = selectedApp === 'OperatorPanel'

  const {
    data: files,
    refetch,
    isLoading: isLoadingFiles,
    isFetching,
  } = useFilesToAcknowledge(machineId, { enabled: isFilesToAcknowledgeEnabled })
  const { mutate: markAsReadFile, isLoading: isSubmitting } = useMarkAsReadFile(machineId)

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(['files-to-acknowledge', machineId])
    }
  }, [machineId])

  useEffect(() => {
    const handleRefetch = () => {
      if (isFilesToAcknowledgeEnabled) {
        refetch()
      }
    }

    hubConnection?.on(HubMethods.RequiredFileCreated, handleRefetch)
    hubConnection?.on(HubMethods.RequiredFileUpdated, handleRefetch)
    hubConnection?.on(HubMethods.ComplaintFileCreated, handleRefetch)
    hubConnection?.on(HubMethods.ComplaintFileDeleted, handleRefetch)
    hubConnection?.on(HubMethods.MachineAssignedToProductionLine, handleRefetch)
    hubConnection?.on(HubMethods.MachineUnassignedFromProductionLine, handleRefetch)
    hubConnection?.on(HubMethods.ComplaintFileProductionLinesChanged, handleRefetch)
    hubConnection?.on(HubMethods.ComplaintAssignedToProductionLine, handleRefetch)
    hubConnection?.on(HubMethods.ComplaintUnassignedFromProductionLine, handleRefetch)

    return () => {
      hubConnection?.off(HubMethods.RequiredFileCreated, handleRefetch)
      hubConnection?.off(HubMethods.RequiredFileUpdated, handleRefetch)
      hubConnection?.off(HubMethods.ComplaintFileCreated, handleRefetch)
      hubConnection?.off(HubMethods.ComplaintFileDeleted, handleRefetch)
      hubConnection?.off(HubMethods.MachineAssignedToProductionLine, handleRefetch)
      hubConnection?.off(HubMethods.MachineUnassignedFromProductionLine, handleRefetch)
      hubConnection?.off(HubMethods.ComplaintFileProductionLinesChanged, handleRefetch)
      hubConnection?.off(HubMethods.ComplaintAssignedToProductionLine, handleRefetch)
      hubConnection?.off(HubMethods.ComplaintUnassignedFromProductionLine, handleRefetch)
    }
  }, [hubConnection, machineId, isFilesToAcknowledgeEnabled])

  const canShowDialogOnPage = pathname !== paths.root && pathname !== paths.rfidLogin
  const isFileReady = !isFetching && !isLoadingFiles && !!files && !!files.length
  const showFileDialog = canShowDialogOnPage && isFilesToAcknowledgeEnabled && isFileReady

  if (showFileDialog) {
    return (
      <PdfFileDialog
        open={showFileDialog}
        file={files[0]}
        onConfirm={() => {
          markAsReadFile(files[0].id)
        }}
        disableConfirm={isSubmitting}
      />
    )
  }

  return null
}

export default FilesToAcknowledge
