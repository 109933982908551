import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Dialog, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import WorkstationStates, { type WorkstationState } from 'Consts/WorkstationStates'

interface Props {
  open: boolean
  close: () => void
  openFailureUnitsDialog: () => void
  setSelectedStatus: (status: WorkstationState | null) => void
}

const FailureStatusSelect: React.FC<Props> = ({ open, close, openFailureUnitsDialog, setSelectedStatus }) => {
  const { t } = useTranslation()
  const handleNormalWorkClick = () => {
    close()
    setSelectedStatus(WorkstationStates.NORMAL_WORK)
    openFailureUnitsDialog()
  }

  const handleWorkWithFailureClick = () => {
    close()
    setSelectedStatus(WorkstationStates.WORK_WITH_FAILURE)
    openFailureUnitsDialog()
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{t('labels.newUnitProduced')}</DialogTitle>
      <DialogContent>
        <Typography align='center' variant='h6'>
          {t('messages.selectWorkMode')}
        </Typography>
      </DialogContent>
      <DialogActions actionsVariant='center'>
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <Button color='normalWork' sx={{ mb: 2 }} onClick={handleNormalWorkClick}>
            {t('labels.normalWork')}
          </Button>
          <Button
            color='idle'
            sx={{ mb: 2 }}
            onClick={() => {
              close()
            }}
          >
            {t('labels.continueTest')}
          </Button>
          <Button color='workWithFailure' onClick={handleWorkWithFailureClick}>
            {t('labels.continueWorkWithFailure')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default FailureStatusSelect
