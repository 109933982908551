import React from 'react'

import { useSnackbar } from 'Context/SnackbarsContext'

import ServerConnected from './ServerConnected'
import ServerDisconnected from './ServerDisconnected'

const SnackbarHandler = () => {
  const { connectedSnackbarOpen, setConnectedSnackbarOpen, disconnectedSnackbarOpen } = useSnackbar()

  if (connectedSnackbarOpen) {
    return <ServerConnected open={connectedSnackbarOpen} onClose={() => setConnectedSnackbarOpen(false)} />
  }
  if (disconnectedSnackbarOpen) {
    return <ServerDisconnected open={disconnectedSnackbarOpen} />
  }

  return null
}

export default SnackbarHandler
