import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'

import DateRangeFormFilters from 'Components/DateFilters/DateRangeFormFilters'
import { DateValidationError } from 'Components/DateRangePicker'

type OrdersFiltersProps = {
  onDateError: (err: DateValidationError) => void
  dateError: DateValidationError
}

const OrdersFilters: React.FC<OrdersFiltersProps> = ({ dateError, onDateError }) => {
  const { control } = useFormContext()

  return (
    <Box display='flex' width='100%' justifyContent='flex-end' alignItems='center' pb={2}>
      <Controller
        name='dateRange'
        control={control}
        render={({ field: { value, onChange } }) => (
          <DateRangeFormFilters value={value} onChange={onChange} dateError={dateError} onDateError={onDateError} />
        )}
      />
    </Box>
  )
}

export default OrdersFilters
