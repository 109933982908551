import { RefObject, useCallback, useLayoutEffect, useState } from 'react'

export const useResizeObserver = (ref: RefObject<HTMLElement>) => {
  const [width, setWidth] = useState<number | undefined>()
  const [height, setHeight] = useState<number | undefined>()

  const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
    if (!Array.isArray(entries) || !entries.length) {
      return
    }

    const entry = entries[0]
    setWidth(entry.contentRect.width)
    setHeight(entry.contentRect.height)
  }, [])

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver | null = null
    if (ref.current) {
      resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(ref.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
        resizeObserver = null
      }
    }
  }, [ref])

  return { width, height }
}
