import { useController, useFormContext } from 'react-hook-form'

import SwitchSelector, { type SwitchSelectorProps } from 'Components/SwitchSelector'

import type { FormField } from './types'

type OptionValue = number | boolean | string
type Option<T extends OptionValue> = {
  label: string
  value: T
}

export type SwitchSelectorFieldProps<T extends OptionValue> = FormField &
  SwitchSelectorProps & {
    options: [Option<T>, Option<T>]
  }

export const FormSwitchSelector = <T extends OptionValue>(props: SwitchSelectorFieldProps<T>) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
  } = useController({ name: props.name, control })

  const indexSelected = props.options?.findIndex((option) => option.value === value)

  return (
    <SwitchSelector
      indexSelected={indexSelected}
      onChange={onChange}
      wrapperStyle={{
        borderWidth: 1,
        padding: 1,
      }}
      labelStyle={{ paddingTop: 3, paddingBottom: 3 }}
      padding='3px 20px'
      {...props}
    />
  )
}
