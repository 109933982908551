import { useEffect } from 'react'

import { apiClient } from 'Api/ApiClient'
import { AppNamesType } from 'Consts/AppNames'
import HubMethods from 'Consts/HubMethods'
import { useNotificationContext } from 'Context/NotificationContext'
import { useSignalRContext } from 'Context/SignalRContext'

export const useUserNotificationsHandler = ({
  selectedApp,
  enabled,
}: {
  selectedApp: AppNamesType
  enabled: boolean
}) => {
  const { hubConnection } = useSignalRContext()
  const { setNotificationsCount } = useNotificationContext()

  useEffect(() => {
    if (enabled) {
      apiClient
        .getUnreadNotificationCountForUser()
        .then(({ data }) => {
          setNotificationsCount(data)
        })
        .catch(() => {
          setNotificationsCount(0)
        })
    } else {
      setNotificationsCount(0)
    }
  }, [enabled, selectedApp])

  useEffect(() => {
    const handleUserNotificationDeliveryAddedMessage = () => {
      apiClient.getUnreadNotificationCountForUser().then(({ data }) => {
        setNotificationsCount(data)
      })
    }

    hubConnection?.on(HubMethods.UserNotificationDeliveryAdded, handleUserNotificationDeliveryAddedMessage)

    return () => {
      hubConnection?.off(HubMethods.UserNotificationDeliveryAdded, handleUserNotificationDeliveryAddedMessage)
    }
  }, [hubConnection, enabled])

  return null
}
