import React from 'react'
import { Box, styled } from '@mui/material'

import LogoIcon from 'Assets/icons/LogoWithNameIcon'

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const CenteredContentWithLogo = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledContainer>
      <Box
        display='flex'
        flex={1}
        minHeight={0}
        width='100%'
        flexDirection='column'
        justifyContent='space-around'
        alignItems='center'
      >
        {children}
      </Box>
      <div style={{ flex: '0 0 50px', display: 'flex' }}>
        <LogoIcon sx={{ width: 70, height: 34 }} />
      </div>
    </StyledContainer>
  )
}

export default CenteredContentWithLogo
