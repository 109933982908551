import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { WorkstationStatusApiResponse } from 'Types/ShiftWorkstation'

export const useWorkstationStatus = (
  id: string,
  options?: UseQueryOptions<
    WorkstationStatusApiResponse,
    unknown,
    WorkstationStatusApiResponse,
    ['workstation-status', string]
  >,
) =>
  useQuery(
    ['workstation-status', id],
    async () => {
      const { data } = await apiClient.getWorkstationStatus(id)

      return data
    },
    options,
  )
