import React from 'react'
import { Stack } from '@mui/material'

const FieldGroup: React.FC<{ expanded: boolean }> = ({ children, expanded }) => (
  <Stack
    spacing={1}
    useFlexGap
    sx={{
      px: 1,
      ...(expanded && {
        backgroundColor: ({ palette }) => palette.background.default,
        py: 2,
      }),
    }}
  >
    {children}
  </Stack>
)

export default FieldGroup
