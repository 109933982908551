import { useMutation } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { queryClient } from 'Lib/react-query'
import { PutOrderElementCompletionDto } from 'Types'

export const useOrderElementCompletion = () =>
  useMutation(
    async (data: PutOrderElementCompletionDto) => {
      await apiClient.putOrderElementCompletion(data)
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['workstation-current-order', variables.machineId])
        queryClient.invalidateQueries(['orders-for-machine', variables.machineId])
      },
    },
  )
