import { getI18n } from 'react-i18next'
import { SvgIcon } from '@mui/material'
import { type ITileProps } from 'Features/app-selection/Tile'

import AppNames, { AppNamesTranslationKeys } from 'Consts/AppNames'
import Permissions, { PermissionName } from 'Consts/Permissions'
import paths from 'Routes/paths'

import AdminPanelIcon from './Icons/AdminPanelIcon'
import ForemanDashboardIcon from './Icons/ForemanDashboardIcon'
import ForemanPanelIcon from './Icons/ForemanPanelIcon'
import MaintenanceDashboard from './Icons/MaintenanceDashboard'
import MechanicPanelIcon from './Icons/MechanicPanelIcon'
import OperatorIcon from './Icons/OperatorPanelIcon'
import PlannerPanelIcon from './Icons/PlannerPanelIcon'
import TechnologistPanelIcon from './Icons/TechnologistPanelIcon'

interface ApplicationItem extends ITileProps {
  permission?: PermissionName
}

export const getApplications: () => ApplicationItem[] = () => [
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.AdminPanel]),
    description: getI18n().t('messages.panelToManageTheMostImportantFunctionsOfTheSystem'),
    icon: AdminPanelIcon as typeof SvgIcon,
    url: paths.adminUsers,
    permission: Permissions.AdminPanel,
  },
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.ForemanPanel]),
    description: getI18n().t('messages.foremanProductionLinesManagementPanel'),
    icon: ForemanPanelIcon as typeof SvgIcon,
    url: paths.foremanProductionFlow,
    permission: Permissions.ForemanPanel,
  },
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.MechanicPanel]),
    description: getI18n().t('messages.panelForManagingMaintenanceRequests'),
    icon: MechanicPanelIcon as typeof SvgIcon,
    url: paths.mechanicDashboard,
    permission: Permissions.MechanicPanel,
  },
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.OperatorPanel]),
    description: getI18n().t('messages.panelForOperatorWorkOnAGivenMachine'),
    icon: OperatorIcon as typeof SvgIcon,
    url: paths.operatorPairWithMachine,
    permission: Permissions.OperatorPanel,
  },
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.PlannerPanel]),
    description: getI18n().t('labels.productionPlanningPanel'),
    icon: PlannerPanelIcon as typeof SvgIcon,
    url: paths.plannerOrders,
    permission: Permissions.PlannerPanel,
  },
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.ForemanDashboard]),
    description: getI18n().t('messages.panelPresentingKeyKPIsAndMetrics'),
    icon: ForemanDashboardIcon as typeof SvgIcon,
    url: paths.foremanDashboard,
    permission: Permissions.ForemanDashboard,
  },
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.MaintenanceDashboard]),
    description: getI18n().t('messages.panelPresentingKeyKPIsAndMetricsForMaintenance'),
    icon: MaintenanceDashboard as typeof SvgIcon,
    url: paths.maintenanceRepairTasksAssignment,
    permission: Permissions.MaintenanceDashboard,
  },
  {
    title: getI18n().t(AppNamesTranslationKeys[AppNames.TechnologistPanel]),
    description: getI18n().t('messages.panelForDefiningTheProductionTechnology'),
    icon: TechnologistPanelIcon as typeof SvgIcon,
    url: paths.technologistLines,
    permission: Permissions.TechnologistPanel,
  },
]
export default getApplications
