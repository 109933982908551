import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Stack } from '@mui/material'

type Props = {
  children: React.ReactNode
  onRemove?: () => void
  disableRemove?: boolean
  showRemoveButton?: boolean
}

const FieldArrayItem = ({ children, onRemove, disableRemove, showRemoveButton }: Props) => {
  return (
    <Stack
      direction='row'
      sx={{
        background: ({ palette }) => palette.background.default,
        width: 1,
        paddingX: 1,
        paddingY: 2,
        display: 'flex',
      }}
      spacing={1}
    >
      {children}
      <Box
        sx={{
          height: '100%',
          paddingTop: 1,
          visibility: showRemoveButton ? 'visible' : 'hidden',
        }}
      >
        <IconButton onClick={onRemove} disabled={disableRemove}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  )
}

export default FieldArrayItem
