import React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'

import { AppNamesTranslationKeys } from 'Consts/AppNames'
import { useUser as useUserContext } from 'Context/UserContext'

type Props = {
  title: string
}

const PageHeaderTitle = ({ title }: Props) => {
  const { selectedApp } = useUserContext()
  const { t } = useTranslation()

  return (
    <Stack>
      <Typography variant='caption' fontWeight={600} textTransform='uppercase'>
        {t(AppNamesTranslationKeys[selectedApp]) ?? 'Kanri'}
      </Typography>
      <Typography variant='h5' fontWeight={500}>
        {title}
      </Typography>
    </Stack>
  )
}

export default PageHeaderTitle
