import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const MechanicPanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M23.136 38.4H24C24 45.024 29.376 50.4 36 50.4C42.624 50.4 48 45.024 48 38.4H49.536C50.208 38.4 50.736 37.872 50.736 37.2C50.736 36.528 50.16 36 49.536 36H48C48 31.104 45.024 26.88 40.8 25.008V27.6C40.8 28.272 40.272 28.8 39.6 28.8C38.928 28.8 38.4 28.272 38.4 27.6V24.24C37.632 24.096 36.816 24 36 24C35.184 24 34.368 24.096 33.6 24.24V27.6C33.6 28.272 33.072 28.8 32.4 28.8C31.728 28.8 31.2 28.272 31.2 27.6V25.008C26.976 26.88 24 31.104 24 36H23.136C22.464 36 21.936 36.528 21.936 37.2C21.936 37.872 22.464 38.4 23.136 38.4ZM43.2 38.4C43.2 42.384 39.984 45.6 36 45.6C32.016 45.6 28.8 42.384 28.8 38.4H43.2Z' />
      <path d='M82.896 25.4884L86.064 22.6564L83.52 18.1924L79.44 19.5364C78.96 19.1524 78.432 18.8644 77.856 18.6244L76.992 14.4004H71.856L70.992 18.5764C70.416 18.8164 69.84 19.1524 69.36 19.4884L65.28 18.1924L62.688 22.6564L65.856 25.4884C65.808 26.1124 65.808 26.6884 65.856 27.2644L62.688 30.1924L65.28 34.6564L69.408 33.3604C69.888 33.6964 70.368 33.9844 70.896 34.2244L71.808 38.4004H76.944L77.856 34.2244C78.384 33.9844 78.912 33.6964 79.392 33.3604L83.472 34.6564L86.064 30.1924L82.896 27.2644C82.944 26.6404 82.944 26.0644 82.896 25.4884ZM74.4 30.6724C72.048 30.6724 70.128 28.7524 70.128 26.4004C70.128 24.0484 72.048 22.1284 74.4 22.1284C76.752 22.1284 78.672 24.0484 78.672 26.4004C78.672 28.7524 76.752 30.6724 74.4 30.6724Z' />
      <path d='M73.4401 41.8566L70.6081 42.8166C70.2721 42.5766 69.8881 42.3366 69.5041 42.1926L68.8801 39.2646H65.2801L64.6561 42.1926C64.2721 42.3846 63.8401 42.5766 63.5041 42.8646L60.6721 41.9526L58.8481 45.0726L61.0561 47.0406C61.0081 47.4726 61.0081 47.9046 61.0561 48.2886L58.8481 50.3046L60.6721 53.4246L63.5521 52.5126C63.8881 52.7526 64.2241 52.9446 64.6081 53.0886L65.2801 56.0646H68.8801L69.5041 53.1366C69.8881 52.9446 70.2241 52.7526 70.5601 52.5126L73.3921 53.4246L75.2161 50.3046L73.0081 48.2886C73.0561 47.8566 73.0561 47.4726 73.0081 47.0406L75.2641 45.0246L73.4401 41.8566ZM67.0561 50.5926C65.3761 50.5926 64.0801 49.2486 64.0801 47.6166C64.0801 45.9366 65.4241 44.6406 67.0561 44.6406C68.7361 44.6406 70.0321 45.9846 70.0321 47.6166C70.0801 49.2486 68.7361 50.5926 67.0561 50.5926Z' />
      <path d='M36.0001 57.6006C24.0001 57.6006 9.6001 63.5526 9.6001 72.0006V76.6566H62.4001V72.0006C62.4001 63.5526 48.0001 57.6006 36.0001 57.6006ZM14.4001 71.8566C14.6401 67.6806 25.0081 62.4006 36.0001 62.4006C46.9921 62.4006 57.3601 67.6806 57.6001 71.8566H14.4001Z' />
    </SvgIcon>
  )
}

export default MechanicPanelIcon
