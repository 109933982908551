import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Dialog, Stack, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import RadioTile from 'Components/RadioTile'
import { useMachineContext } from 'Context/MachineContext'
import { useProductionContext } from 'Context/ProductionContext'
import { useCancelPotentialFailure } from 'Hooks/potential-failures'
import paths from 'Routes/paths'

type Props = {
  open: boolean
  onClose: () => void
}

const Actions = {
  FAILURE: 'failure',
  IDLE: 'idle',
  CANCEL: 'cancel',
} as const

type Action = (typeof Actions)[keyof typeof Actions]

const PotentialFailureDialog = ({ open, onClose }: Props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [action, setAction] = React.useState<Action | null>(null)
  const { mutate: cancelPotentialFailure, isLoading: isSubmitting, isError } = useCancelPotentialFailure()
  const { machine } = useMachineContext()
  const { setHasUnhandledPotentialFailure } = useProductionContext()
  const workstationId = machine?.id

  const handleConfirm = () => {
    if (!action || !workstationId) return

    switch (action) {
      case Actions.FAILURE:
        history.push(paths.operatorClassifyPotentialFailureAsFailure)
        onClose()
        setAction(null)
        break
      case Actions.IDLE:
        history.push(paths.operatorClassifyPotentialFailureAsIdleReason)
        onClose()
        setAction(null)
        break
      case Actions.CANCEL:
        cancelPotentialFailure({ workstationId }, { onSuccess: onClose })
        setHasUnhandledPotentialFailure(false)
        setAction(null)
        break
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{t('messages.potentialFailureDetected')}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} useFlexGap>
          <Typography>
            {t('messages.yourWorkstationHasDetectedPotentialFailure')}
            {'. '}
            {t('messages.decideWhichActionToTake')}
            {'.'}
          </Typography>
          <Stack spacing={1}>
            <RadioTile
              value={Actions.FAILURE}
              label={t('labels.reportFailure')}
              description={t('messages.reportsFailureStartingFromDetectingTheIssue')}
              onClick={() => setAction(Actions.FAILURE)}
              checked={action === Actions.FAILURE}
            />
            <RadioTile
              value={Actions.IDLE}
              label={t('labels.reportIdle')}
              description={t('messages.reportsIdleStartingFromDetectingTheIssue')}
              onClick={() => setAction(Actions.IDLE)}
              checked={action === Actions.IDLE}
            />
            <RadioTile
              value={Actions.CANCEL}
              label={t('labels.continueWork')}
              description={t('messages.ignoresDetectionOfTheIssue')}
              onClick={() => setAction(Actions.CANCEL)}
              checked={action === Actions.CANCEL}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        actionsVariant='center'
        errorMessage={isError ? t('messages.sorryThereWasAProblemWithYourRequest') : null}
      >
        <Button onClick={handleConfirm} disabled={!action || isSubmitting}>
          {t('labels.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PotentialFailureDialog
