import React from 'react'
import { useTranslation } from 'react-i18next'
import { Inventory2Outlined as OrdersIcon, Notifications as NotificationsIcon } from '@mui/icons-material'

import { useNotificationContext } from 'Context/NotificationContext'
import paths from 'Routes/paths'

import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const PlannerPanelLinks = ({ collapsed }: Props) => {
  const { t } = useTranslation()
  const { notificationsCount } = useNotificationContext()

  const navItems: NavLinkItem[] = [
    {
      link: paths.plannerNotifications,
      label: t('labels.notifications'),
      Icon: NotificationsIcon,
      border: true,
      badge: notificationsCount,
    },
    {
      link: paths.plannerOrders,
      Icon: OrdersIcon,
      label: t('labels.orders'),
    },
  ]

  return <NavLinks items={navItems} collapsed={collapsed} />
}

export default PlannerPanelLinks
