import { useMutation } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { queryClient } from 'Lib/react-query'
import { PutOrderElementResumptionDto } from 'Types'

export const useOrderElementResumption = () =>
  useMutation(
    async (data: PutOrderElementResumptionDto) => {
      await apiClient.putOrderElementResumption(data)
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['workstation-current-order', variables.machineId])
        queryClient.invalidateQueries(['orders-for-machine', variables.machineId])
      },
    },
  )
