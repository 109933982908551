import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Locale } from 'date-fns'
import enLocale from 'date-fns/locale/en-GB'
import { loadMessages, locale as devExtremeLocale } from 'devextreme/localization'

import { DevExtremeLanguageCodes } from 'Consts/DevExtremeLanguageCodes'
import LocaleLanguageCodes from 'Consts/LocaleLanguageCodes'
import { useTranslationsContext } from 'Context/TranslationsContext'

type LocaleContext = {
  locale: Locale
}

const LocaleContext = createContext<LocaleContext>({ locale: enLocale })

export function useLocaleContext() {
  const context = useContext(LocaleContext)
  if (context === undefined) {
    throw new Error('LocaleContext must be within LocalizationProvider')
  }

  return context
}

const LocalizationContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState(enLocale)
  const { selectedLanguageCode } = useTranslationsContext()

  useEffect(() => {
    if (LocaleLanguageCodes[selectedLanguageCode]) {
      const importLocaleFile = async () => {
        const localeToSet = await import(`date-fns/locale/${LocaleLanguageCodes[selectedLanguageCode]}/index.js`)
        setLocale(localeToSet.default)
      }

      if (locale.code !== selectedLanguageCode) {
        importLocaleFile()
      }
    }
  }, [selectedLanguageCode, locale])

  useEffect(() => {
    if (!DevExtremeLanguageCodes[selectedLanguageCode]) return
    const languageCode = DevExtremeLanguageCodes[selectedLanguageCode]
    import(`devextreme/localization/messages/${languageCode}.json`).then((v) => {
      loadMessages(v)
    })
    devExtremeLocale(languageCode)
  }, [selectedLanguageCode])

  const value: LocaleContext = useMemo(() => ({ locale }), [locale])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
    </LocalizationProvider>
  )
}

export default LocalizationContext
