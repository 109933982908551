import React from 'react'
import { Stack, SvgIconProps, Typography } from '@mui/material'

type Props = {
  children: React.ReactNode
  Icon: React.ElementType
  IconProps: SvgIconProps
}

const DialogTitleWithIcon = ({ Icon, children, IconProps }: Props) => {
  return (
    <Stack spacing={3} alignItems='center'>
      <Icon fontSize='large' {...IconProps} />
      <Typography variant='h5' fontWeight='medium'>
        {children}
      </Typography>
    </Stack>
  )
}

export default DialogTitleWithIcon
