import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare'
import { Button, CircularProgress } from '@mui/material'
import Config from 'config'

import { useMachineContext } from 'Context/MachineContext'
import { sleep } from 'Helpers/Time'
import ErrorPageTemplate from 'Layout/ErrorPageTemplate'

type App = {
  url: string
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent
  }

  interface Navigator {
    getInstalledRelatedApps(): Promise<App[]>
  }
}

const AppInstallation: React.FC = ({ children }) => {
  const [isAppInstalling, setIsAppInstalling] = useState(false)
  const [isAppInstalled, setIsAppInstalled] = useState(false)
  const [isCheckInstalledAppError, setIsCheckInstalledAppError] = useState(false)
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null)
  const { setMachine } = useMachineContext()
  const { t } = useTranslation()

  const handleMoveToAppClick = () => {
    window.open(Config.APP_DEFAULT_URL, '_blank')
  }

  const getIsAppInstalled = async () => {
    if ('getInstalledRelatedApps' in window.navigator) {
      try {
        const relatedApps = await navigator.getInstalledRelatedApps()

        relatedApps.forEach((app) => {
          if (app.url.includes(`${Config.APP_DEFAULT_URL}`)) {
            setIsAppInstalled(true)
          }
        })
      } catch {
        setIsCheckInstalledAppError(true)
        setIsAppInstalled(false)
      }
    }
  }

  useEffect(() => {
    getIsAppInstalled()
  }, [])

  useEffect(() => {
    const handleBeforeInstallPromptEvent = (e: BeforeInstallPromptEvent) => {
      e.preventDefault()
      setDeferredPrompt(e)
      setIsAppInstalled(false)
    }

    const handleAppInstalledEvent = () => {
      setIsAppInstalling(true)
      sleep(7000).then(() => {
        setDeferredPrompt(null)
        setIsAppInstalled(true)
        setIsAppInstalling(false)
      })
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent)
    window.addEventListener('appinstalled', handleAppInstalledEvent)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent)
      window.removeEventListener('appinstalled', handleAppInstalledEvent)
    }
  }, [])

  const handleAddToHomescreenClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setDeferredPrompt(null)
          setMachine(undefined)
        }
      })
    }
  }

  const getHeader = () => {
    if (isAppInstalling) return t('messages.appIsInstalling')

    if (isAppInstalled) return t('messages.appInstalled')

    return t('messages.appNotInstalled')
  }

  const getDescription = () => {
    if (isAppInstalling) return t('messages.installationMayTakeFewSeconds')

    if (isAppInstalled) return t('messages.runAppToUse')

    return t('messages.installToUse')
  }

  if (!isCheckInstalledAppError && isMobile && !window.matchMedia('(display-mode: fullscreen)').matches) {
    return (
      <>
        <ErrorPageTemplate
          Icon={isAppInstalling ? CircularProgress : MobileScreenShareIcon}
          header={getHeader()}
          description={getDescription()}
          buttonText={t('labels.moveToApp')}
          hideButton={!isAppInstalled}
          onClick={handleMoveToAppClick}
          hideArrow
        />
        {!isAppInstalled && !isAppInstalling && deferredPrompt && (
          <Button
            onClick={handleAddToHomescreenClick}
            disabled={!deferredPrompt}
            sx={{
              width: 1,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              boxShadow: 3,
              bgcolor: 'background.default',
              color: 'text.primary',
              '&:disabled': {
                bgcolor: 'background.default',
              },
              '&:hover': {
                bgcolor: 'background.default',
              },
            }}
          >
            {t('labels.addToHomeScreen')}
          </Button>
        )}
      </>
    )
  }

  return <>{children}</>
}

export default AppInstallation
