export default {
  API_DEFAULT_URL: process.env.REACT_APP_API_URL || window.globalConfig?.apiDefaultURL,
  HUB_DEFAULT_URL: process.env.REACT_APP_HUB_URL || window.globalConfig?.hubDefaultURL,
  APP_DEFAULT_URL: process.env.REACT_APP_PUBLIC_URL || window.globalConfig?.appDefaultUrl,
  API_TIMEOUT: process.env.REACT_APP_API_TIMEOUT || window.globalConfig?.apiTimeout,
  APP_VERSION: process.env.REACT_APP_BUILD_NUMBER || window.globalConfig?.appVersion,
  RELEASE_TAG: process.env.REACT_APP_VERSION_TAG || window.globalConfig?.releaseTag,
  MIXPANEL_API_HOST: process.env.REACT_APP_MIXPANEL_API_HOST || window.globalConfig?.mixpanelApiHost,
  MIXPANEL_PROJECT_TOKEN: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || window.globalConfig?.mixpanelProjectToken,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || window.globalConfig?.supportEmail,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || window.globalConfig?.sentryDSN,
}
