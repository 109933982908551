import React, { useEffect, useState } from 'react'

import { DateValidationError } from 'Components/DateRangePicker'

import DateRangeFilters from './DateRangeFilters'

type Props = {
  value: { from: Date; to: Date } | undefined
  onChange: (data: { from: Date | null; to: Date | null }) => void
  dateError: DateValidationError
  onDateError: (err: DateValidationError) => void
}

const DateRangeFormFilters = ({ value, onChange, dateError, onDateError }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const { from, to } = value ?? {}

  useEffect(() => {
    if (from) {
      setStartDate(from)
    }
  }, [from])

  useEffect(() => {
    if (to) {
      setEndDate(to)
    }
  }, [to])

  const fromISOString = startDate?.toISOString()
  const toISOString = endDate?.toISOString()

  useEffect(() => {
    onChange({ from: startDate, to: endDate })
  }, [fromISOString, toISOString])

  return (
    <DateRangeFilters
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={setStartDate}
      onEndDateChange={setEndDate}
      dateError={dateError}
      onDateError={onDateError}
    />
  )
}

export default DateRangeFormFilters
