import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dashboard as DashboardIcon, Reorder as FailureListIcon } from '@mui/icons-material'

import { useFeatureFlagsContext } from 'Context/FeatureFlagsContext'
import paths from 'Routes/paths'

import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const MechanicPanelLinks = ({ collapsed }: Props) => {
  const { t } = useTranslation()
  const { MechanicCanSelfAssignToTask } = useFeatureFlagsContext()

  const navItems: (NavLinkItem | undefined)[] = [
    {
      link: paths.mechanicDashboard,
      Icon: DashboardIcon,
      label: t('labels.dashboard'),
    },
    MechanicCanSelfAssignToTask
      ? {
          link: paths.mechanicFailureAssignment,
          Icon: FailureListIcon,
          label: t('labels.failureList'),
        }
      : undefined,
  ].filter(Boolean)

  return <NavLinks items={navItems as NavLinkItem[]} collapsed={collapsed} />
}

export default MechanicPanelLinks
