import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReportProblemOutlined as ReportProblemOutlinedIcon } from '@mui/icons-material'
import { Button, Dialog as MuiDialog, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle, DialogTitleWithIcon } from 'Components/Dialog'
import errors from 'Consts/Errors'
import { getErrorMessage } from 'Helpers/ApiErrorCodeGetter'
import useAccidentConfirmation from 'Hooks/incidents/useAccidentConfirmation'
import { UnacknowledgeIncidentDto } from 'Types'

interface Props {
  open: boolean
  onClose: (id: string) => void
  incident: UnacknowledgeIncidentDto
}

const AccidentConfirmationDialog: React.FC<Props> = ({ open, onClose, incident }) => {
  const { mutate: confirmIncident, isLoading } = useAccidentConfirmation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { t } = useTranslation()

  const handleConfirmation = () => {
    confirmIncident(incident.incidentId, {
      onSuccess: handleClose,
      onError: (err: unknown) => {
        const errorMessage = getErrorMessage(err)

        if (errorMessage === errors.accidentIsAcknowledged) {
          setErrorMessage(t('messages.incidentAlreadyAcknowledged'))
        } else {
          setErrorMessage(t('messages.errorDuringAccidentConfirmation'))
        }
      },
    })
  }

  const handleClose = () => {
    if (onClose) {
      onClose(incident.incidentId)
    }
  }

  return (
    <MuiDialog open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose} disableTypography>
        <DialogTitleWithIcon Icon={ReportProblemOutlinedIcon} IconProps={{ color: 'warning', sx: { fontSize: 65 } }}>
          {t('messages.accidentHasBeenReported')}
        </DialogTitleWithIcon>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6' fontWeight='bold' align='center'>
          {t('labels.workstation')}: {incident.machineName || t('labels.deleted', { context: 'workstation' })}
        </Typography>
      </DialogContent>
      <DialogActions errorMessage={errorMessage}>
        <Button variant='text' onClick={handleClose}>
          {t('labels.cancel')}
        </Button>
        <Button onClick={handleConfirmation} disabled={isLoading} color='warning'>
          {t('labels.confirm')}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default AccidentConfirmationDialog
