import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import AppsIcon from '@mui/icons-material/Apps'
import CloseIcon from '@mui/icons-material/Close'
import LanguageIcon from '@mui/icons-material/Language'
import MenuIcon from '@mui/icons-material/Menu'
import { alpha, Box, Drawer, IconButton, PaperProps, Tabs, useMediaQuery, useTheme } from '@mui/material'

import getApplications from 'Consts/Applications'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'

import AppLinks from './AppLinks'
import CurrentTime from './CurrentTime'
import LogoutMenuItem from './LogoutMenuItem'
import OperatorWorkEndMenuItem from './OperatorWorkEndMenuItem'
import SideNavItem from './SideNavItem'

const SideNav = () => {
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down('lg'))

  const drawerPaperSxProp: PaperProps['sx'] = useMemo(
    () => ({
      width: 224,
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: 'flex',
      flexDirection: 'column',
    }),
    [theme],
  )

  useEffect(() => {
    if (open) {
      setOpen(false)
    }
  }, [pathname])

  return (
    <Box
      component='nav'
      onContextMenu={(event) => {
        const nativeEvent = event.nativeEvent
        if (nativeEvent instanceof TouchEvent) {
          event.preventDefault()
        }
      }}
    >
      {isNarrow ? (
        <Drawer
          open
          variant={open ? 'temporary' : 'permanent'}
          onClose={() => setOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              ...drawerPaperSxProp,
              width: open ? 224 : 68,
            },
          }}
        >
          <SideNavItems open={open} toggleOpen={() => setOpen(!open)} isNarrow={isNarrow} />
        </Drawer>
      ) : (
        <Drawer
          open
          variant='permanent'
          PaperProps={{
            sx: drawerPaperSxProp,
          }}
        >
          <SideNavItems open={open} toggleOpen={() => setOpen(!open)} isNarrow={isNarrow} />
        </Drawer>
      )}
    </Box>
  )
}

const SideNavItems = ({ open, toggleOpen, isNarrow }: { open: boolean; toggleOpen: () => void; isNarrow: boolean }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { selectedApp, user, isOperatorWorkInProgress } = useUserContext()
  const { t } = useTranslation()

  const userApplications = getApplications().filter((app) => !app.permission || user?.permissions.has(app.permission))
  const showAppSelection = userApplications.length > 1
  const showOperatorEndWork = isOperatorWorkInProgress && showAppSelection && selectedApp === 'OperatorPanel'

  const collapsed = !open && isNarrow

  return (
    <>
      {isNarrow ? (
        <SideNavTogleCollapse onTogle={toggleOpen} collapsed={collapsed} />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '64px',
          }}
        />
      )}
      <CurrentTime collapsed={collapsed} />
      <Tabs
        orientation='vertical'
        variant='scrollable'
        scrollButtons
        aria-label='sidebar-navigation'
        TabIndicatorProps={{ style: { display: 'none' } }}
        TabScrollButtonProps={{
          sx: {
            boxShadow: '0px 2px 8px -2px #00000085, 0px -2px 8px -2px #00000085',
            '&:hover': {
              backgroundColor: ({ palette }) => alpha(palette.primary.contrastText, palette.action.hoverOpacity),
            },
          },
        }}
        value={false}
        sx={{
          flex: '1 0 0',
          width: '100%',
          '& .Mui-disabled': {
            height: 0,
            transition: 'height 200ms',
          },
          '& .MuiButtonBase-root': {
            color: ({ palette }) => palette.primary.contrastText,
          },
        }}
      >
        <AppLinks collapsed={collapsed} />
        <SideNavItem
          onClick={() => history.push(paths.languageSelection, { app: selectedApp })}
          selected={paths.languageSelection === pathname}
          collapsed={collapsed}
          Icon={LanguageIcon}
          label={t('labels.language')}
        />
        {showOperatorEndWork && <OperatorWorkEndMenuItem collapsed={collapsed} />}
      </Tabs>
      {showAppSelection && (
        <SideNavItem
          onClick={() => history.push(paths.appSelection, { app: selectedApp })}
          selected={paths.appSelection === pathname}
          collapsed={collapsed}
          Icon={AppsIcon}
        />
      )}
      <LogoutMenuItem collapsed={collapsed} />
    </>
  )
}

const SideNavTogleCollapse = ({ collapsed, onTogle }: { collapsed: boolean; onTogle: () => void }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '64px',
        padding: '8px 17px 8px 17px',
        gap: 1,
        color: 'inherit',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexShrink: 0,
      }}
    >
      <IconButton
        onClick={onTogle}
        size='small'
        sx={{
          color: 'inherit',
          '&:hover': {
            backgroundColor: ({ palette }) => alpha(palette.primary.contrastText, palette.action.hoverOpacity),
          },
        }}
      >
        {collapsed ? <MenuIcon fontSize='small' color='inherit' /> : <CloseIcon fontSize='small' color='inherit' />}
      </IconButton>
    </Box>
  )
}

export default SideNav
