import { useMutation } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { queryClient } from 'Lib/react-query'

const useDeleteOrder = () =>
  useMutation(
    async (id: number) => {
      await apiClient.deleteOrder(id)
    },
    {
      onSuccess: () => queryClient.invalidateQueries('orders'),
      onError: () => queryClient.invalidateQueries('orders'),
    },
  )

export default useDeleteOrder
