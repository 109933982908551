import { lightBlue } from '@mui/material/colors'

const colors = {
  americanBlue: '#333F65',
  americanSilver: '#CDCDD2',
  antiFlashWhite: '#F0F2F8',
  cultured: '#F6F7F8',
  white: '#ffffff',
  sunsetOrange: '#FF5A5F',
  gunPowder: '#43425D',
  codGray: '#161615',
  green: '#2BD195',
  mantis: '#5CC461',
  blue: '#4873F9',
  red: '#FD6370',
  neonCarrot: '#FEA339',
  lightGray: '#e5e5eb',
  osloGray: '#84868F',
  yellow: '#FFD400',
  governorBay: '#3555B8',
  manate: '#9A9A9E',
  pastelSilver: '#DBDBDC',
  bombay: '#B3B3B8',
  black: '#000000',
}

export const palette = {
  primary: {
    main: colors.americanBlue,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.sunsetOrange,
    contrastText: colors.white,
  },
  background: {
    default: colors.cultured,
    paper: colors.white,
    lightGrey: colors.antiFlashWhite,
    grey: colors.lightGray,
    backdrop: 'rgba(22, 22, 21, 0.8)',
  },
  text: {
    primary: colors.gunPowder,
    secondary: colors.osloGray,
  },
  success: {
    main: colors.green,
    contrastText: colors.white,
  },
  info: {
    main: lightBlue[700],
    contrastText: colors.white,
  },
  error: {
    main: colors.red,
    contrastText: colors.white,
  },
  warning: {
    main: colors.yellow,
    contrastText: colors.gunPowder,
  },
  status: {
    notWorking: {
      main: colors.governorBay,
      contrastText: colors.white,
    },
    normalWork: {
      main: colors.green,
      contrastText: colors.gunPowder,
    },
    workWithFailure: {
      main: colors.yellow,
      contrastText: colors.gunPowder,
    },
    failure: {
      main: colors.red,
      contrastText: colors.white,
    },
    idle: {
      main: colors.manate,
      contrastText: colors.white,
    },
    microidle: {
      main: colors.pastelSilver,
      contrastText: colors.white,
    },
    retool: {
      main: colors.codGray,
      contrastText: colors.white,
    },
    suspendedRetool: {
      main: colors.codGray,
      contrastText: colors.white,
    },
  },
  scrap: {
    main: colors.blue,
    contrastText: colors.white,
  },
  rework: {
    main: colors.gunPowder,
    contrastText: colors.white,
  },
  accident: {
    main: colors.neonCarrot,
    contrastText: colors.white,
  },
  dark: {
    main: colors.gunPowder,
    contrastText: colors.white,
  },
  blue: {
    main: colors.blue,
    contrastText: colors.white,
  },
  common: {
    blue: colors.blue,
    white: colors.white,
    black: colors.black,
    yellow: colors.yellow,
  },
}

export default colors
