import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box, Button, Dialog, Stack, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import ManualNumericControl from 'Components/NumericControl/ManualNumericControl'
import { useDialogsContext } from 'Context/DialogsContext'
import { useMachineContext } from 'Context/MachineContext'
import { useProductionContext } from 'Context/ProductionContext'
import { useShiftsConfigurationContext } from 'Context/ShiftsContext/ShiftsContext'
import { useUser as useUserContext } from 'Context/UserContext'
import { isDuringGivenShift, isEarlierThanGivenShift } from 'Helpers/Shifts'
import useReportPotentialNOKs from 'Hooks/scraps/useReportPotentialNOKs'
import paths from 'Routes/paths'
import { Bold } from 'Styles/utils'

const PotentialNOKDialogHandler = () => {
  const [potentialNOKReportError, setPotentialNOKReportError] = useState('')
  const { currentShift } = useShiftsConfigurationContext()
  const { mutate: reportPotentialNOKs, isLoading: isReportingPotentialNOKs } = useReportPotentialNOKs()
  const { potentialNOKReportDialogOpen, setPotentialNOKReportDialogOpen } = useDialogsContext()
  const {
    potentialNOKCount,
    setPotentialNOKCount,
    producedPotentialNOK,
    setProducedPotentialNOK,
    setPotentialNOKToReportCount,
  } = useProductionContext()
  const { user, selectedApp } = useUserContext()
  const { machine } = useMachineContext()
  const history = useHistory()
  const { t } = useTranslation()
  const shouldOpenPotentialNOKReportDialog =
    potentialNOKReportDialogOpen &&
    history.location.pathname !== paths.operatorReportScrapReason &&
    history.location.pathname !== paths.operatorReportComponentScrapReason &&
    user &&
    selectedApp === 'OperatorPanel'
  const errorTimeout = useRef<number | undefined>()
  const hasOnlyPotentialNOKFromPreviousShift = producedPotentialNOK.length && !potentialNOKCount
  const producedPotentialNOKLength = producedPotentialNOK.length
  const currentPath = history.location.pathname
  const [value, setValue] = useState(0)

  const handleAccept = (amount: number) => {
    if (!amount && machine) {
      reportPotentialNOKs(
        { machineId: machine.id, potentialNOKs: [] },
        {
          onSuccess: () => {
            setPotentialNOKCount(0)
            setProducedPotentialNOK([])
          },
        },
      )

      return
    }

    const potentialNOKProducedOnPreviousShift = producedPotentialNOK.filter(
      (nok) => currentShift && isEarlierThanGivenShift(new Date(nok.productionDate), currentShift),
    )
    const potentialNOKProducedOnPreviousShiftCount = potentialNOKProducedOnPreviousShift.reduce(
      (result, nok) => result + nok.unitsCount,
      0,
    )
    const validPotentialNOKCount = potentialNOKCount - potentialNOKProducedOnPreviousShiftCount

    if (amount > validPotentialNOKCount) {
      setPotentialNOKCount((prev) => prev - potentialNOKProducedOnPreviousShiftCount)
      setPotentialNOKReportError(t('messages.cannotReportScrapsFromPreviousShift'))
      if (validPotentialNOKCount) {
        setProducedPotentialNOK((prev) => {
          return prev.filter((nok) => currentShift && isDuringGivenShift(new Date(nok.productionDate), currentShift))
        })
      }

      return
    }

    setPotentialNOKToReportCount(amount)
    history.push(paths.operatorReportScrapReason)
  }

  const handleClose = () => {
    setProducedPotentialNOK([])
    setPotentialNOKReportDialogOpen(false)
  }

  useEffect(() => {
    if ((potentialNOKCount || !!producedPotentialNOKLength) && currentPath !== paths.operatorReportScrapReason) {
      setPotentialNOKReportDialogOpen(true)
    } else {
      setPotentialNOKReportDialogOpen(false)
    }
  }, [potentialNOKCount, producedPotentialNOKLength, currentPath])

  useEffect(() => {
    if (potentialNOKReportError && !hasOnlyPotentialNOKFromPreviousShift) {
      setTimeout(() => {
        setPotentialNOKReportError('')
      }, 3000)
    }

    return () => {
      if (errorTimeout.current) {
        clearTimeout(errorTimeout.current)
      }
    }
  }, [potentialNOKReportError, hasOnlyPotentialNOKFromPreviousShift])

  if (!shouldOpenPotentialNOKReportDialog) {
    return null
  }

  return (
    <Dialog open={shouldOpenPotentialNOKReportDialog}>
      <DialogTitle>{t('messages.potentialDeficienciesDetected')}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} useFlexGap>
          <Typography>
            {t('messages.yourWorkstationHasDetectedThatCertainProductsMayContainDeficiencies')}
            {'. '}
            <Trans i18nKey='messages.indicateHowManyProducedUnitsYouWantToQualifyAsScrap'>
              Indicate how many produced units you want to qualify as <Bold>scrap</Bold>
            </Trans>
            {'.'}
          </Typography>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{
              background: ({ palette }) => palette.background.default,
              px: 1,
              py: 2,
            }}
          >
            <Typography>{t('labels.numberOfScraps')}</Typography>
            <Box>
              <ManualNumericControl
                defaultValue={0}
                minNumber={0}
                maxNumber={potentialNOKCount}
                onChange={setValue}
                disableFloat
              />
              <Typography variant='caption' color={potentialNOKReportError ? 'error' : 'text.secondary'}>
                {potentialNOKReportError || t('messages.maximumNumber', { count: potentialNOKCount })}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions actionsVariant='center'>
        <Button
          onClick={() => {
            if (hasOnlyPotentialNOKFromPreviousShift) {
              handleClose()
            } else {
              handleAccept(value)
            }
          }}
          disabled={isReportingPotentialNOKs}
        >
          {t('labels.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PotentialNOKDialogHandler
