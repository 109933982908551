import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'

type Props = {
  open: boolean
  onConfirm: () => void
}

const ProductionStartedWithoutIdleReason = ({ open, onConfirm }: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open}>
      <DialogTitle>{t('messages.startedProductionWithoutReason')}</DialogTitle>
      <DialogContent>
        <Typography align='center' variant='h5'>
          {t('messages.selectIdleReason')}
        </Typography>
      </DialogContent>
      <DialogActions actionsVariant='center'>
        <Button onClick={onConfirm}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductionStartedWithoutIdleReason
