import React, { useMemo, useState } from 'react'
import { getI18n } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import { Box, Button, Divider, ListItemIcon, MenuItem } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import {
  DeleteDialog,
  EditDialog,
  FinishWorkDialog,
  OrdersTable,
  type OrderTableItem,
  ResumeWorkDialog,
} from 'Features/orders'

import MenuButton from 'Components/MenuButton'
import Tooltip from 'Components/Tooltip'
import { OrderStatuses as OrderStatusTypes } from 'Consts/OrderStatuses'
import { OrderDto, OrderStatusDto } from 'Types'

type Props = {
  data: OrderDto[]
  isLoading: boolean
  errorMessage?: string | null
  statuses: OrderStatusDto[]
  defaultExpanded?: boolean
}

const PlannerOrdersTable = ({ data, isLoading, errorMessage, statuses, defaultExpanded }: Props) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [resumeWorkDialogOpen, setResumeWorkDialogOpen] = useState(false)
  const [finishWorkDialogOpen, setFinishWorkDialogOpen] = useState(false)
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null)
  const [selectedOrderComponentId, setSelectedOrderComponentId] = useState<number | null | undefined>(null)
  const [selectedMachineId, setSelectedMachineId] = useState<string | null | undefined>(null)
  const selectedOrder = data.find(({ id }) => id === selectedOrderId)

  const ctaColumnDefinition = useMemo(
    () =>
      getMenuColumnDefinition({
        onEditClick(id) {
          setSelectedOrderId(id as number)
          setEditDialogOpen(true)
        },
        onDeleteClick(id) {
          setSelectedOrderId(id as number)
          setDeleteDialogOpen(true)
        },
        onCompleteClick({ orderId, orderComponentId, machineId }) {
          setSelectedOrderId(orderId)
          setSelectedOrderComponentId(orderComponentId)
          setSelectedMachineId(machineId)
          setFinishWorkDialogOpen(true)
        },
        onResumeClick({ orderId, orderComponentId, machineId }) {
          setSelectedOrderId(orderId)
          setSelectedOrderComponentId(orderComponentId)
          setSelectedMachineId(machineId)
          setResumeWorkDialogOpen(true)
        },
      }),
    [],
  )

  return (
    <>
      <OrdersTable
        isLoading={isLoading}
        errorMessage={errorMessage}
        statuses={statuses}
        data={data}
        ctaColumnDefinition={ctaColumnDefinition}
        defaultExpanded={defaultExpanded}
      />
      {editDialogOpen && selectedOrderId && (
        <EditDialog
          open={editDialogOpen}
          orderId={selectedOrderId}
          onCancel={() => {
            setEditDialogOpen(false)
            setSelectedOrderId(null)
          }}
          onSuccess={() => {
            setEditDialogOpen(false)
            setSelectedOrderId(null)
          }}
        />
      )}
      {deleteDialogOpen && selectedOrderId && (
        <DeleteDialog
          open={deleteDialogOpen}
          id={selectedOrderId}
          onCancel={() => {
            setDeleteDialogOpen(false)
            setSelectedOrderId(null)
          }}
          onSuccess={() => {
            setDeleteDialogOpen(false)
            setSelectedOrderId(null)
          }}
        />
      )}
      {resumeWorkDialogOpen && selectedOrderId && selectedOrder && (
        <ResumeWorkDialog
          open={resumeWorkDialogOpen}
          onClose={() => {
            setResumeWorkDialogOpen(false)
            setSelectedOrderId(null)
            setSelectedOrderComponentId(null)
            setSelectedMachineId(null)
          }}
          order={selectedOrder}
          orderComponentId={selectedOrderComponentId}
          orderComponentMachineId={selectedMachineId}
        />
      )}
      {finishWorkDialogOpen && selectedOrderId && selectedOrder && (
        <FinishWorkDialog
          open={finishWorkDialogOpen}
          onClose={() => {
            setFinishWorkDialogOpen(false)
            setSelectedOrderId(null)
            setSelectedOrderComponentId(null)
            setSelectedMachineId(null)
          }}
          order={selectedOrder}
          orderComponentId={selectedOrderComponentId}
          orderComponentMachineId={selectedMachineId}
        />
      )}
    </>
  )
}

const columnHelper = createColumnHelper<OrderTableItem>()

const getMenuColumnDefinition = ({
  onEditClick,
  onDeleteClick,
  onCompleteClick,
  onResumeClick,
}: {
  onEditClick: (id: OrderTableItem['id']) => void
  onDeleteClick: (id: OrderTableItem['id']) => void
  onCompleteClick: (params: {
    orderId: OrderDto['id']
    orderComponentId?: OrderDto['orderComponents'][0]['id']
    machineId?: string
  }) => void
  onResumeClick: (params: {
    orderId: OrderDto['id']
    orderComponentId?: OrderDto['orderComponents'][0]['id']
    machineId?: string
  }) => void
}) =>
  columnHelper.display({
    id: 'menu',
    header: '',
    cell: ({ row }) => {
      const { id, status } = row.original
      const isEditable = status !== OrderStatusTypes.Ended
      const isRemovable = status === OrderStatusTypes.NotStarted
      const isCompleted = status === OrderStatusTypes.Ended

      if (row.depth === 0) {
        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <MenuButton>
              {isCompleted ? (
                <MenuItem onClick={() => onResumeClick({ orderId: id as number })}>
                  <ListItemIcon>
                    <PlayArrowIcon fontSize='small' />
                  </ListItemIcon>
                  {getI18n().t('labels.resumeOrder')}
                </MenuItem>
              ) : (
                <MenuItem onClick={() => onCompleteClick({ orderId: id as number })}>
                  <ListItemIcon>
                    <StopIcon fontSize='small' />
                  </ListItemIcon>
                  {getI18n().t('labels.completeOrder')}
                </MenuItem>
              )}
              <Divider />
              <MenuItem onClick={() => onEditClick(id)} disabled={!isEditable}>
                <ListItemIcon>
                  <EditIcon fontSize='small' />
                </ListItemIcon>
                {getI18n().t('labels.edit')}
              </MenuItem>
              <MenuItem onClick={() => onDeleteClick(id)} disabled={!isRemovable}>
                <ListItemIcon>
                  <DeleteIcon fontSize='small' />
                </ListItemIcon>
                {getI18n().t('labels.delete')}
              </MenuItem>
            </MenuButton>
          </Box>
        )
      }

      if (row.depth === 1) {
        const orderId = row.getParentRow()?.original.id as number
        const orderComponentId = row.original.id as number

        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            {isCompleted ? (
              <Button
                onClick={() =>
                  onResumeClick({
                    orderId,
                    orderComponentId,
                  })
                }
                variant='outlined'
                size='small'
              >
                {getI18n().t('labels.resume')}
              </Button>
            ) : (
              <Button
                onClick={() =>
                  onCompleteClick({
                    orderId,
                    orderComponentId,
                  })
                }
                size='small'
              >
                {getI18n().t('labels.finish')}
              </Button>
            )}
          </Box>
        )
      }

      if (row.depth === 2) {
        const orderId = row.getParentRow()?.getParentRow()?.original.id as number
        const orderComponentId = row.getParentRow()?.original.id as number
        const machineId = row.original.id as string
        const disabled = row.original.isDeleted || !row.original.isActive

        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            {isCompleted ? (
              <Tooltip title={disabled ? getI18n().t('messages.cannotResumeWorkAtAWorkstationThatIsNotActive') : ''}>
                <div>
                  <Button
                    onClick={() =>
                      onResumeClick({
                        orderId,
                        orderComponentId,
                        machineId,
                      })
                    }
                    variant='outlined'
                    size='small'
                    disabled={disabled}
                  >
                    {getI18n().t('labels.resume')}
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={disabled ? getI18n().t('messages.cannotFinishWorkAtAWorkstationThatIsNotActive') : ''}>
                <div>
                  <Button
                    onClick={() =>
                      onCompleteClick({
                        orderId,
                        orderComponentId,
                        machineId,
                      })
                    }
                    size='small'
                    disabled={disabled}
                  >
                    {getI18n().t('labels.finish')}
                  </Button>
                </div>
              </Tooltip>
            )}
          </Box>
        )
      }

      return null
    },
    size: 220,
  })

export default PlannerOrdersTable
