import EventEmitter from 'events'
import { nanoid } from 'nanoid'

import ErrorTracker from 'Lib/error-tracker'

interface MessageType {
  type: 'add_observer' | 'subject_changed'
  senderTabId: string
}

export const TabsMonitorEvents = {
  TabOpened: 'tab:opened',
  TabClosed: 'tab:closed',
  TabAcive: 'tab:active',
  TabInactive: 'tab:inactive',
}

const OpenedTabsMonitor = () => {
  const events = new EventEmitter()
  const currentTabId = nanoid()
  let subjectId = document.visibilityState === 'visible' ? currentTabId : null
  let broadcastChannel: BroadcastChannel | null = null

  const isLastActiveTab = () => currentTabId === subjectId

  const broadcastInitTabMessage = () => {
    const initMessage: MessageType = {
      type: 'add_observer',
      senderTabId: currentTabId,
    }
    if (broadcastChannel) {
      broadcastChannel.postMessage(initMessage)
    }
  }

  const broadcastNewActiveTabMessage = () => {
    const message: MessageType = {
      type: 'subject_changed',
      senderTabId: currentTabId,
    }
    if (broadcastChannel) {
      broadcastChannel.postMessage(message)
    }
  }

  const handleTabActivated = () => {
    broadcastNewActiveTabMessage()
    subjectId = currentTabId
    events.emit(TabsMonitorEvents.TabAcive)
  }

  const attachEvents = () => {
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        handleTabActivated()
      }
    }

    if (broadcastChannel) {
      broadcastChannel.onmessage = (ev: MessageEvent<MessageType>) => {
        if (ev.data.type === 'subject_changed') {
          subjectId = ev.data.senderTabId
          events.emit(TabsMonitorEvents.TabInactive)
        }
        if (ev.data.type === 'add_observer') {
          if (isLastActiveTab()) {
            broadcastNewActiveTabMessage()
          }
        }
      }
    }
  }

  const init = () => {
    if (typeof BroadcastChannel === 'function') {
      broadcastChannel = new BroadcastChannel('kanri_opened_tabs')
    } else {
      ErrorTracker.captureMessage('BroadcastChannel is not supported. OpenedTabsMonitor will monitor only current tab.')
    }

    attachEvents()

    if (document.visibilityState === 'visible') {
      handleTabActivated()
    } else {
      broadcastInitTabMessage()
    }
  }

  return {
    init,
    events,
    isLastActiveTab,
  }
}

export default OpenedTabsMonitor()
