import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Task as TaskIcon } from '@mui/icons-material'
import { Button, Dialog } from '@mui/material'

import { apiClient } from 'Api/ApiClient'
import { DialogActions, DialogTitle } from 'Components/Dialog'
import { useMachineContext } from 'Context/MachineContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'

import SideNavItem from './SideNavItem'

type DialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  error?: string | null
  disabled: boolean
}

const ConfirmDialog: React.FC<DialogProps> = ({ open, onClose, onConfirm, disabled, error }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{t('labels.confirmTheEndOfTheOperatorsWork')}</DialogTitle>
      <DialogActions errorMessage={error}>
        <Button variant='text' onClick={onClose}>
          {t('labels.cancel')}
        </Button>
        <Button onClick={onConfirm} disabled={disabled}>
          {t('labels.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type Props = {
  collapsed: boolean
}

const OperatorWorkEndMenuItem: React.FC<Props> = ({ collapsed }) => {
  const { machine } = useMachineContext()
  const [endWorkDialogOpen, setEndWorkDialogOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { t } = useTranslation()
  const { setSelectedApp, selectedApp } = useUserContext()
  const history = useHistory()

  const handleClick = () => {
    setEndWorkDialogOpen(true)
  }

  const handleConfirm = () => {
    if (!machine || isSubmitting) {
      return
    }

    setIsSubmitting(true)
    apiClient
      .postOperatorWorkEnd(machine.id)
      .then(() => {
        if (selectedApp === 'OperatorPanel') {
          history.push(paths.home)
          setSelectedApp('')
        }
        setEndWorkDialogOpen(false)
      })
      .catch(() => {
        setError(t('messages.sorryThereWasAProblemWithYourRequest'))
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  if (!machine) {
    return null
  }

  return (
    <>
      <SideNavItem collapsed={collapsed} onClick={handleClick} Icon={TaskIcon} label={t('labels.endWork')} />
      {endWorkDialogOpen && (
        <ConfirmDialog
          open={endWorkDialogOpen}
          onClose={() => setEndWorkDialogOpen(false)}
          onConfirm={handleConfirm}
          disabled={isSubmitting}
          error={error}
        />
      )}
    </>
  )
}

export default OperatorWorkEndMenuItem
