import React, { createContext, useContext, useMemo, useState } from 'react'

import { AnyType, CalendarFilterType } from 'Types'

interface MechanicContextInterface {
  markedAsReadTasks: string[]
  setMarkedAsReadTasks: (data: string[]) => void
  selectedSuccessfulReason: AnyType
  setSelectedSuccessfulReason: React.Dispatch<React.SetStateAction<AnyType>>
  selectedUnsuccessfulReason: AnyType
  setSelectedUnsuccessfulReason: React.Dispatch<React.SetStateAction<AnyType>>
  failureDetailsId: string | null
  setFailureDetailsId: (id: string) => void
  failureDetailsMachineName: string
  setFailureDetailsMachineName: React.Dispatch<React.SetStateAction<string>>
  calendarFilter: CalendarFilterType
  setCalendarFilter: React.Dispatch<React.SetStateAction<CalendarFilterType>>
}

const MechanicContext = createContext<MechanicContextInterface | undefined>(undefined)

export const useMechanicContext = (): MechanicContextInterface => {
  const context = useContext(MechanicContext)

  if (context === undefined) {
    throw new Error('useMechanicContext must be used within a MechanicContextProvider')
  }

  return context
}

export const MechanicContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [markedAsReadTasks, _setMarkedAsReadTasks] = useState<string[]>(
    JSON.parse(localStorage.getItem('markedAsReadTasks') as string) || [],
  )
  const [selectedUnsuccessfulReason, setSelectedUnsuccessfulReason] = useState<AnyType>({})
  const [selectedSuccessfulReason, setSelectedSuccessfulReason] = useState<AnyType>({})
  const [failureDetailsId, _setFailureDetailsId] = useState(localStorage.getItem('failureDetailsId') ?? null)
  const [failureDetailsMachineName, setFailureDetailsMachineName] = useState('')
  const [calendarFilter, setCalendarFilter] = useState<CalendarFilterType>('Today')

  const setFailureDetailsId = (id: string) => {
    localStorage.setItem('failureDetailsId', id)
    _setFailureDetailsId(id)
  }

  const setMarkedAsReadTasks = (data: string[]) => {
    _setMarkedAsReadTasks(data)
    localStorage.setItem('markedAsReadTasks', JSON.stringify(data))
  }

  const value: MechanicContextInterface = useMemo(
    () => ({
      markedAsReadTasks,
      setMarkedAsReadTasks,
      selectedUnsuccessfulReason,
      setSelectedUnsuccessfulReason,
      failureDetailsId,
      setFailureDetailsId,
      failureDetailsMachineName,
      setFailureDetailsMachineName,
      calendarFilter,
      setCalendarFilter,
      setSelectedSuccessfulReason,
      selectedSuccessfulReason,
    }),
    [
      markedAsReadTasks,
      selectedUnsuccessfulReason,
      failureDetailsId,
      failureDetailsMachineName,
      calendarFilter,
      selectedSuccessfulReason,
    ],
  )

  return <MechanicContext.Provider value={value}>{children}</MechanicContext.Provider>
}
