import React, { useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import { Box, TextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { DatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { endOfDay, format, isAfter, isBefore, isDate, isSameDay, startOfDay } from 'date-fns'

import Day from './Day'

const DATE_INPUT_MASK = '99/99/9999 - 99/99/9999'
const DATE_INPUT_MASK_PLACEHOLDER = '_'
const DEFAULT_MIN_DATE = new Date('01/01/1900')
const DEFAULT_MAX_DATE = new Date('12/31/2099')

const DateValidationErrors = {
  minDate: 'minDate',
  maxDate: 'maxDate',
  dateRange: 'dateRange',
  invalidDate: 'invalidDate',
} as const

type DateValidationErrorsKeys = keyof typeof DateValidationErrors
export type DateValidationError = (typeof DateValidationErrors)[DateValidationErrorsKeys] | null

const getError = (startDate: Date | null, endDate: Date | null, minDate: Date, maxDate: Date): DateValidationError => {
  if (!startDate || !endDate || !isDate(startDate) || !isDate(endDate)) {
    return DateValidationErrors.invalidDate
  }

  if (isBefore(startDate, minDate) || isBefore(endDate, minDate)) {
    return DateValidationErrors.minDate
  }

  if (isAfter(startDate, maxDate) || isAfter(endDate, maxDate)) {
    return DateValidationErrors.maxDate
  }

  if (isBefore(endDate, startDate)) {
    return DateValidationErrors.dateRange
  }

  return null
}

const formatStringToDate = (date: string): Date => {
  const dateParts = date.split('/')

  const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])

  return dateObject
}

const formatDateToString = (date: Date): string => {
  const stringDate = format(date, 'dd/MM/yyyy')

  return stringDate
}

const renderDay = (
  date: Date,
  pickersDayProps: PickersDayProps<Date>,
  startDate: Date | null,
  endDate: Date | null,
) => {
  const start = startDate
  const end = endDate

  const isOnlyOneDay = end === null || (!!start && isSameDay(start, date) && end && isSameDay(start, end))
  const dayIsBetween = !!start && !!end && isAfter(date, start) && isBefore(date, end) && !isOnlyOneDay
  const isFirstDay = !!start && isSameDay(start, date) && !isOnlyOneDay
  const isLastDay = !!end && isSameDay(end, date) && !isOnlyOneDay

  return (
    <Day
      {...pickersDayProps}
      isOnlyOneDay={isOnlyOneDay}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  )
}

const getPickerValue = (isOnStartDate: boolean, startDate: Date | null, endDate: Date | null) => {
  if (isOnStartDate && startDate) {
    return startDate
  }

  return endDate
}

type Props = {
  startDate: Date | null
  endDate: Date | null
  onStartDateChange: (date: Date | null) => void
  onEndDateChange: (date: Date | null) => void
  minDate?: Date
  maxDate?: Date
  onError?: (error: DateValidationError) => void
  TextFieldProps?: MuiTextFieldProps
  DatePickerProps?: Omit<MuiDatePickerProps<Date, Date>, 'onChange' | 'renderInput' | 'value'>
}

const DateRangePicker = ({
  TextFieldProps,
  DatePickerProps,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onError,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const inputWrapperRef = useRef<HTMLElement | null>(null)
  const [isOnStartDate, setIsOnStartDate] = useState<boolean>(true)

  useEffect(() => {
    setAnchorEl(inputWrapperRef.current)
  }, [])

  useEffect(() => {
    if (!startDate && !endDate) {
      return
    }

    const startDateValue = startDate ? formatDateToString(startDate) : '__/__/____'
    const endDateValue = endDate ? formatDateToString(endDate) : '__/__/____'

    const newInputValue = `${startDateValue} - ${endDateValue}`

    setInputValue(newInputValue)
  }, [startDate, endDate])

  useEffect(() => {
    if (!startDate && !endDate) {
      return
    }

    const error = getError(startDate, endDate, minDate, maxDate)

    onError?.(error)
  }, [startDate, endDate, maxDate, minDate])

  const handleDatePickerChange = (value: Date | null) => {
    if (value && isDate(value)) {
      const startOfTheDay = startOfDay(value)
      const endOfTheDay = endOfDay(value)

      if (isOnStartDate || !startDate || (startDate && value < startDate)) {
        onStartDateChange(startOfTheDay)
        if (endDate && value >= endDate) {
          onEndDateChange(endOfTheDay)
        }
        setIsOnStartDate(false)
      } else {
        onEndDateChange(endOfTheDay)
        setIsOnStartDate(true)
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setInputValue(value)

    if (!value) {
      return
    }

    const datesArr = value.split('-')
    const startDateValue = datesArr[0].trim()
    const endDateValue = datesArr[1].trim()
    const isStartDateValid = !startDateValue.includes(DATE_INPUT_MASK_PLACEHOLDER)
    const isEndDateValid = !endDateValue.includes(DATE_INPUT_MASK_PLACEHOLDER)
    const start = startOfDay(formatStringToDate(startDateValue))
    const end = endOfDay(formatStringToDate(endDateValue))

    const shouldSetStartDate = isStartDateValid && isDate(start) && start.getTime() !== startDate?.getTime()
    const shouldSetEndDate = isEndDateValid && isDate(end) && end.getTime() !== endDate?.getTime()

    if (shouldSetStartDate) {
      onStartDateChange(start)
    } else if (shouldSetEndDate) {
      onEndDateChange(end)
    } else {
      onError?.(DateValidationErrors.invalidDate)
    }
  }

  return (
    <DatePicker
      value={getPickerValue(isOnStartDate, startDate, endDate)}
      onChange={handleDatePickerChange}
      onOpen={() => setIsOnStartDate(true)}
      PaperProps={{
        sx: { borderRadius: 4, '& .PrivatePickersFadeTransitionGroup-root': { fontSize: '1.25rem' } },
      }}
      PopperProps={{ anchorEl }}
      renderDay={(date: Date, _, pickersDayProps: PickersDayProps<Date>) =>
        renderDay(date, pickersDayProps, startDate, endDate)
      }
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params) => {
        return (
          <Box ref={inputWrapperRef}>
            <InputMask
              mask={DATE_INPUT_MASK}
              maskPlaceholder={DATE_INPUT_MASK_PLACEHOLDER}
              onChange={handleInputChange}
              value={inputValue}
            >
              <TextField InputProps={{ endAdornment: params?.InputProps?.endAdornment }} {...TextFieldProps} />
            </InputMask>
          </Box>
        )
      }}
      {...DatePickerProps}
    />
  )
}

export default DateRangePicker
