import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Alert, Button, Dialog, Stack, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { useOrderElementResumption } from 'Hooks/orders/useOrderElementResumption'
import { Bold } from 'Styles/utils'
import { OrderDto } from 'Types/Orders'

type Props = {
  open: boolean
  onClose: () => void
  order: OrderDto
  orderComponentId?: OrderDto['orderComponents'][0]['id'] | null
  orderComponentMachineId?: string | null
}

const ResumeWorkDialog = ({ open, onClose, order, orderComponentId, orderComponentMachineId }: Props) => {
  const { t } = useTranslation()
  const { mutate: resumeOrderElement, isLoading: isSubmitting, isError } = useOrderElementResumption()
  const orderComponent = order.orderComponents.find(({ id }) => id === orderComponentId)
  const machine = orderComponent?.orderComponentMachines?.find(({ id }) => id === orderComponentMachineId)
  const machineId = machine?.machineId

  const handleComplete = () => {
    resumeOrderElement(
      {
        orderId: order.id,
        orderComponentId,
        machineId,
      },
      {
        onSuccess: onClose,
      },
    )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {t(getResumeWorkTitleTranslationKey({ orderComponentId, orderComponentMachineId }))}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <ResumeWorkMessage
            orderName={order.name}
            componentName={orderComponent?.name}
            machineName={machine?.machineName}
          />
        </Stack>
      </DialogContent>
      <DialogActions errorMessage={isError ? t('messages.sorryThereWasAProblemWithYourRequest') : undefined}>
        <Button variant='text' onClick={onClose}>
          {t('labels.cancel')}
        </Button>
        <Button onClick={handleComplete} disabled={isSubmitting}>
          {t('labels.resume')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const getResumeWorkTitleTranslationKey = ({
  orderComponentId,
  orderComponentMachineId,
}: Pick<Props, 'orderComponentId' | 'orderComponentMachineId'>) => {
  if (orderComponentMachineId) {
    return 'labels.resumeWorkAtTheWorkstation'
  }

  if (orderComponentId) {
    return 'labels.resumeWorkOnTheComponent'
  }

  return 'labels.resumeWorkOnTheOrder'
}

const ResumeWorkMessage = ({
  orderName,
  componentName,
  machineName,
}: {
  orderName: string
  componentName?: string
  machineName?: string
}) => {
  const { t } = useTranslation()

  if (machineName && componentName) {
    return (
      <>
        <Typography>
          <Trans t={t} i18nKey='messages.confirmTheResumptionOfWorkOnTheOrderComponentWorkstation'>
            Confirm the resumption of work at the <Bold>{{ machineName }}</Bold> station assigned to the production of
            the <Bold>{{ componentName }}</Bold> component in the <Bold>{{ orderName }}</Bold> order.
          </Trans>
        </Typography>
        <Alert severity='info'>
          <Trans t={t} i18nKey='messages.workWillBeResumedOnlyAtThisWorkstation'>
            Work will be resumed only at <Bold>this workstation</Bold>.
          </Trans>{' '}
          {t('messages.operatorsWillAgainBeAbleToRetoolToTheProductionOfThisComponent')}
        </Alert>
      </>
    )
  }

  if (componentName) {
    return (
      <>
        <Typography>
          <Trans t={t} i18nKey='messages.confirmTheResumptionOfWorkOnTheOrderComponent'>
            Confirm the resumption of work on component <Bold>{{ componentName }}</Bold> in order
            <Bold>{{ orderName }}</Bold>.
          </Trans>
        </Typography>
        <Alert severity='info'>
          <Trans t={t} i18nKey='messages.workWillBeResumedOnAllWorkstationsAssignedToTheProductionOfThisComponent'>
            Work will be resumed on <Bold>all workstations</Bold> assigned to the production of this component.
          </Trans>{' '}
          {t('messages.operatorsWillAgainBeAbleToRetoolToTheProductionOfThisComponent')}
        </Alert>
      </>
    )
  }

  return (
    <>
      <Typography>
        <Trans t={t} i18nKey='messages.confirmTheResumptionOfWorkOnTheOrder'>
          Confirm the resumption of work on the order <Bold>{{ orderName }}</Bold>.
        </Trans>
      </Typography>
      <Alert severity='info'>
        <Trans t={t} i18nKey='messages.workWillBeResumedForAllComponentsAndWorkstationsAssignedToTheirProduction'>
          Work will be resumed for <Bold>all components and workstations</Bold> assigned to their production.
        </Trans>{' '}
        {t('messages.operatorsWillAgainBeAbleToRetoolToTheProductionOfThisComponent')}
      </Alert>
    </>
  )
}

export default ResumeWorkDialog
