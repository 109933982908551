import { useEffect } from 'react'

import { apiClient } from 'Api/ApiClient'
import HubMethods from 'Consts/HubMethods'
import { useNotificationContext } from 'Context/NotificationContext'
import { useSignalRContext } from 'Context/SignalRContext'

export const useOperatorNotificationsHandler = ({ machineId, enabled }: { machineId: string; enabled: boolean }) => {
  const { hubConnection } = useSignalRContext()
  const { setNotificationsCount, incrementNotificationCount } = useNotificationContext()

  useEffect(() => {
    if (enabled && machineId) {
      apiClient.getUnreadNotificationCount(machineId).then(({ data }) => {
        setNotificationsCount(data)
      })
    } else {
      setNotificationsCount(0)
    }

    const handleWorkstationNotificationDeliveryAddedMessage = () => {
      if (enabled) {
        incrementNotificationCount()
      }
    }

    hubConnection?.on(
      HubMethods.WorkstationNotificationDeliveryAdded,
      handleWorkstationNotificationDeliveryAddedMessage,
    )

    return () => {
      hubConnection?.off(
        HubMethods.WorkstationNotificationDeliveryAdded,
        handleWorkstationNotificationDeliveryAddedMessage,
      )
    }
  }, [hubConnection, machineId, enabled])

  return null
}
