import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import HubMethods from 'Consts/HubMethods'
import { useMachineContext } from 'Context/MachineContext'
import { useSignalRContext } from 'Context/SignalRContext'
import paths from 'Routes/paths'
import {
  MachineAssignedToProductionLineMessage,
  MachineModeChangedMessage,
  MachineProducedUnitUpdatedMessage,
  MachineUnassignedFromProductionLineMessage,
} from 'Types'

export const MachineHandler = () => {
  const history = useHistory()
  const { hubConnection } = useSignalRContext()
  const { machine, setMachine, setMachineMode } = useMachineContext()
  const machineId = machine?.id

  useEffect(() => {
    const handleMachineProducedUnitUpdated = ({ unitsProductionCount }: MachineProducedUnitUpdatedMessage) => {
      setMachine((prevMachine) => {
        if (prevMachine) {
          return {
            ...prevMachine,
            actualUnitProductionCount: unitsProductionCount,
            defaultUnitProductionCount: unitsProductionCount,
          }
        }

        return prevMachine
      })
    }
    hubConnection?.on(HubMethods.MachineProducedUnitUpdated, handleMachineProducedUnitUpdated)

    return () => {
      hubConnection?.off(HubMethods.MachineProducedUnitUpdated, handleMachineProducedUnitUpdated)
    }
  }, [hubConnection])

  useEffect(() => {
    const handleMachineAssignedToProductionLine = ({
      workstationId,
      productionLineId,
    }: MachineAssignedToProductionLineMessage) => {
      if (machine?.id === workstationId) {
        setMachine((prevMachine) => {
          if (prevMachine) {
            return {
              ...prevMachine,
              productionLineIds: [...prevMachine.productionLineIds, productionLineId],
            }
          }

          return prevMachine
        })
      }
    }
    const handleMachineUnassignedFromProductionLine = ({
      workstationId,
      productionLineId,
    }: MachineUnassignedFromProductionLineMessage) => {
      if (machine?.id === workstationId && productionLineId) {
        setMachine((prevMachine) => {
          if (prevMachine) {
            return {
              ...prevMachine,
              productionLineIds: prevMachine.productionLineIds.filter((lineId) => lineId !== productionLineId),
            }
          }

          return prevMachine
        })
      }
    }

    hubConnection?.on(HubMethods.MachineAssignedToProductionLine, handleMachineAssignedToProductionLine)
    hubConnection?.on(HubMethods.MachineUnassignedFromProductionLine, handleMachineUnassignedFromProductionLine)

    return () => {
      hubConnection?.off(HubMethods.MachineAssignedToProductionLine, handleMachineAssignedToProductionLine)
      hubConnection?.off(HubMethods.MachineUnassignedFromProductionLine, handleMachineUnassignedFromProductionLine)
    }
  }, [hubConnection, machine])

  useEffect(() => {
    const handleMachineRemoved = () => {
      setMachine((prevMachine) => {
        if (prevMachine) {
          return {
            ...prevMachine,
            removed: true,
          }
        }

        return prevMachine
      })
    }

    hubConnection?.on(HubMethods.MachineDeleted, handleMachineRemoved)

    return () => hubConnection?.off(HubMethods.MachineDeleted, handleMachineRemoved)
  }, [hubConnection])

  useEffect(() => {
    const handleMachineDeactivated = () => {
      setMachine((prevMachine) => {
        if (prevMachine) {
          return {
            ...prevMachine,
            active: false,
          }
        }

        return prevMachine
      })
    }

    hubConnection?.on(HubMethods.MachineDeactivated, handleMachineDeactivated)

    return () => hubConnection?.off(HubMethods.MachineDeactivated, handleMachineDeactivated)
  }, [hubConnection])

  useEffect(() => {
    const handleMachineActivated = () => {
      setMachine((prevMachine) => {
        if (prevMachine) {
          return {
            ...prevMachine,
            active: true,
          }
        }

        return prevMachine
      })

      history.push(paths.rfidLogin)
    }

    hubConnection?.on(HubMethods.MachineActivated, handleMachineActivated)

    return () => hubConnection?.off(HubMethods.MachineActivated, handleMachineActivated)
  }, [hubConnection])

  useEffect(() => {
    const handleMachineDisconnect = () => {
      setMachine(undefined)

      history.push(paths.workstationDisconnected)
    }

    hubConnection?.on(HubMethods.DisconnectPairedMachine, handleMachineDisconnect)

    return () => hubConnection?.off(HubMethods.DisconnectPairedMachine, handleMachineDisconnect)
  }, [hubConnection])

  useEffect(() => {
    const handleMachineModeChanged = ({ workstationId, mode }: MachineModeChangedMessage) => {
      if (workstationId === machineId) {
        setMachineMode(mode)
      }
    }

    hubConnection?.on(HubMethods.MachineModeChanged, handleMachineModeChanged)

    return () => hubConnection?.off(HubMethods.MachineModeChanged, handleMachineModeChanged)
  }, [hubConnection, machineId])

  return null
}
