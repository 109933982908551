import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const DataLoggersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 20 16' {...props}>
      <path d='M5.76 12.2402C4.67 11.1602 4 9.66018 4 8.00018C4 6.34018 4.67 4.84018 5.76 3.76018L7.18 5.18018C6.45 5.90018 6 6.90018 6 8.00018C6 9.10018 6.45 10.1002 7.17 10.8302L5.76 12.2402ZM14.24 12.2402C15.33 11.1602 16 9.66018 16 8.00018C16 6.34018 15.33 4.84018 14.24 3.76018L12.82 5.18018C13.55 5.90018 14 6.90018 14 8.00018C14 9.10018 13.55 10.1002 12.83 10.8302L14.24 12.2402ZM10 6.00018C8.9 6.00018 8 6.90018 8 8.00018C8 9.10018 8.9 10.0002 10 10.0002C11.1 10.0002 12 9.10018 12 8.00018C12 6.90018 11.1 6.00018 10 6.00018ZM18 8.00018C18 10.2102 17.1 12.2102 15.65 13.6502L17.07 15.0702C18.88 13.2602 20 10.7602 20 8.00018C20 5.24018 18.88 2.74018 17.07 0.930176L15.65 2.35018C17.1 3.79018 18 5.79018 18 8.00018ZM4.35 2.35018L2.93 0.930176C1.12 2.74018 0 5.24018 0 8.00018C0 10.7602 1.12 13.2602 2.93 15.0702L4.35 13.6502C2.9 12.2102 2 10.2102 2 8.00018C2 5.79018 2.9 3.79018 4.35 2.35018Z' />
    </SvgIcon>
  )
}

export default DataLoggersIcon
