import React, { useMemo, useState } from 'react'
import { getI18n } from 'react-i18next'
import { Cancel } from '@mui/icons-material'
import { Box, Button, IconButton } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import { OrdersTable, OrderTableItem } from 'Features/orders'
import AddWorkstationDialog from 'Features/orders/AddWorkstationDialog'
import RemoveWorkstationDialog from 'Features/orders/RemoveWorkstationDialog'

import Tooltip from 'Components/Tooltip'
import { OrderDto, OrderStatusDto } from 'Types'

type OrderId = OrderDto['id']
type OrderComponentId = OrderDto['orderComponents'][0]['id']
type OrderComponentMachineId = OrderDto['orderComponents'][0]['orderComponentMachines'][0]['id']

type Props = {
  data: OrderDto[]
  isLoading: boolean
  errorMessage?: string | null
  statuses: OrderStatusDto[]
  defaultExpanded?: boolean
}

const ForemanOrdersTable = ({ data, isLoading, errorMessage, statuses, defaultExpanded }: Props) => {
  const [addWorkstationDialogOpen, setAddWorkstationDialogOpen] = useState(false)
  const [removeWorkstationDialogOpen, setRemoveWorkstationDialogOpen] = useState(false)
  const [orderId, setOrderId] = useState<OrderId | null>(null)
  const [orderComponentId, setOrderComponentId] = useState<OrderComponentId | null>(null)
  const [orderComponentMachineId, setOrderComponentMachineId] = useState<OrderComponentMachineId | null>(null)
  const selectedOrder = data.find(({ id }) => id === orderId)

  const ctaColumnDefinition = useMemo(
    () =>
      getMenuColumnDefinition({
        onAddWorkstationClick({ orderId, orderComponentId }) {
          setOrderId(orderId)
          setOrderComponentId(orderComponentId)
          setAddWorkstationDialogOpen(true)
        },
        onRemoveWorkstationClick({ orderId, orderComponentId, orderComponentMachineId }) {
          setOrderId(orderId)
          setOrderComponentId(orderComponentId)
          setOrderComponentMachineId(orderComponentMachineId)
          setRemoveWorkstationDialogOpen(true)
        },
      }),
    [],
  )

  const handleAddDialogClose = () => {
    setAddWorkstationDialogOpen(false)
    clearSelection()
  }

  const handleRemoveDialogClose = () => {
    setRemoveWorkstationDialogOpen(false)
    clearSelection()
  }

  const clearSelection = () => {
    setOrderId(null)
    setOrderComponentId(null)
    setOrderComponentMachineId(null)
  }

  return (
    <>
      <OrdersTable
        isLoading={isLoading}
        errorMessage={errorMessage}
        statuses={statuses}
        data={data}
        ctaColumnDefinition={ctaColumnDefinition}
        defaultExpanded={defaultExpanded}
      />
      {addWorkstationDialogOpen && !!orderId && !!orderComponentId && !!selectedOrder && (
        <AddWorkstationDialog
          open={addWorkstationDialogOpen}
          onClose={handleAddDialogClose}
          order={selectedOrder}
          orderComponentId={orderComponentId}
        />
      )}
      {removeWorkstationDialogOpen && !!orderId && !!orderComponentId && orderComponentMachineId && !!selectedOrder && (
        <RemoveWorkstationDialog
          open={removeWorkstationDialogOpen}
          onClose={handleRemoveDialogClose}
          order={selectedOrder}
          orderComponentId={orderComponentId}
          orderComponentMachineId={orderComponentMachineId}
        />
      )}
    </>
  )
}

const columnHelper = createColumnHelper<OrderTableItem>()

const getMenuColumnDefinition = ({
  onAddWorkstationClick,
  onRemoveWorkstationClick,
}: {
  onAddWorkstationClick: ({
    orderId,
    orderComponentId,
  }: {
    orderId: OrderId
    orderComponentId: OrderComponentId
  }) => void
  onRemoveWorkstationClick: ({
    orderId,
    orderComponentId,
    orderComponentMachineId,
  }: {
    orderId: OrderId
    orderComponentId: OrderComponentId
    orderComponentMachineId: OrderComponentMachineId
  }) => void
}) =>
  columnHelper.display({
    id: 'menu',
    header: '',
    cell: ({ row }) => {
      if (row.depth === 1) {
        const orderId = row.getParentRow()?.original.id as OrderId
        const orderComponentId = row.original.id as OrderComponentId

        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Button
              variant='outlined'
              onClick={() => onAddWorkstationClick({ orderId, orderComponentId })}
              disabled={!orderId || !orderComponentId}
              size='small'
            >
              {getI18n().t('labels.addWorkstation')}
            </Button>
          </Box>
        )
      } else if (row.depth === 2) {
        const componentRow = row.getParentRow()
        const orderId = componentRow?.getParentRow()?.original.id as OrderId
        const orderComponentId = componentRow?.original.id as OrderComponentId
        const orderComponentMachineId = row.original.id as OrderComponentMachineId
        const orderComponentMachinesCount = componentRow?.original.subRows?.length ?? 0
        const { isRemovable, isEssentialForCompletion } = row.original
        const isLastMachine = orderComponentMachinesCount === 1
        const isDisabled = !isRemovable || !!isEssentialForCompletion || !!isLastMachine

        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Tooltip
              title={getCannotRemoveMachineMessage({ isEssentialForCompletion, isLastMachine, isRemovable })}
              placement='bottom-start'
            >
              <span>
                <IconButton
                  onClick={() => onRemoveWorkstationClick({ orderId, orderComponentId, orderComponentMachineId })}
                  disabled={isDisabled}
                >
                  <Cancel />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )
      }

      return null
    },
  })

const getCannotRemoveMachineMessage = ({
  isEssentialForCompletion,
  isLastMachine,
  isRemovable,
}: {
  isEssentialForCompletion?: boolean
  isLastMachine?: boolean
  isRemovable?: boolean
}) => {
  if (isLastMachine) {
    return getI18n().t('messages.youCannotRemoveTheOnlyOneWorkstationAssignedToTheComponent')
  }

  if (isEssentialForCompletion) {
    return getI18n().t('messages.youCannotRemoveTheOnlyActiveWorkstationAssignedToTheComponent')
  }

  if (!isRemovable) {
    return getI18n().t('messages.youCannotRemoveWorkstationThatHasAlreadyWorkedOnTheOrder')
  }

  return ''
}

export default ForemanOrdersTable
