import * as Sentry from '@sentry/react'
import Config from 'config'

const init = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: Config.SENTRY_DSN,
      environment: window.location.host,
      release: Config.APP_VERSION,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
      ],
    })
    Sentry.setTag('ReleaseTag', Config.RELEASE_TAG)
  }
}

const ErrorTracker = {
  ...Sentry,
  init,
}

export default ErrorTracker
