import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers'
import { endOfDay, isToday, isYesterday, startOfDay, subDays } from 'date-fns'

import DateRangePicker, { DateValidationError } from 'Components/DateRangePicker'

type Props = {
  onStartDateChange: (date: Date | null) => void
  onEndDateChange: (date: Date | null) => void
  startDate: Date | null
  endDate: Date | null
  onDateError: (err: DateValidationError) => void
  dateError: DateValidationError
  onTodayClick?: () => void
  onYesterdayClick?: () => void
  minDate?: Date
  size?: 'small'
  DatePickerProps?: Omit<MuiDatePickerProps<Date, Date>, 'onChange' | 'renderInput' | 'value'>
}

const DateRangeFilters = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onDateError,
  dateError,
  onTodayClick,
  onYesterdayClick,
  minDate,
  size,
  DatePickerProps,
}: Props) => {
  const { t } = useTranslation()
  const isYesterdaySelected = startDate && endDate && isYesterday(startDate) && isYesterday(endDate)
  const isTodaySelected = startDate && endDate && isToday(startDate) && isToday(endDate)

  const handleTodayClick = () => {
    onStartDateChange(startOfDay(new Date()))
    onEndDateChange(endOfDay(new Date()))

    if (onTodayClick) onTodayClick()
  }

  const handleYesterdayClick = () => {
    onStartDateChange(startOfDay(subDays(new Date(), 1)))
    onEndDateChange(endOfDay(subDays(new Date(), 1)))

    if (onYesterdayClick) onYesterdayClick()
  }

  return (
    <Box display='flex' alignItems='center' sx={{ gap: { xs: size === 'small' ? 1 : 2, xxl: 2 } }}>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onError={onDateError}
        TextFieldProps={{
          sx: { minWidth: 250 },
          size: 'small',
          label: t('labels.date'),
          helperText: dateError ? t('messages.wrongDate') : '',
          error: !!dateError,
          FormHelperTextProps: {
            sx: {
              position: 'absolute',
              bottom: -20,
            },
          },
        }}
        DatePickerProps={{
          disableHighlightToday: true,
          closeOnSelect: false,
          views: ['day'],
          ...DatePickerProps,
        }}
        minDate={minDate}
      />
      <Button
        onClick={handleYesterdayClick}
        size='small'
        variant={isYesterdaySelected ? 'contained' : 'outlined'}
        sx={{
          boxShadow: 0,
          minWidth: { xs: size === 'small' ? 36 : 92, xxl: 71 },
        }}
      >
        {t('labels.yesterday')}
      </Button>
      <Button
        size='small'
        onClick={handleTodayClick}
        variant={isTodaySelected ? 'contained' : 'outlined'}
        sx={{
          boxShadow: 0,
          minWidth: { xs: size === 'small' ? 36 : 92, xxl: 71 },
        }}
      >
        {t('labels.today')}
      </Button>
    </Box>
  )
}

export default DateRangeFilters
