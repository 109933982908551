import React, { createContext, useContext, useMemo, useState } from 'react'

import { UnacknowledgeIncidentDto } from 'Types'

interface AccidentsContext {
  notConfirmedAccidents: UnacknowledgeIncidentDto[]
  setNotConfirmedAccidents: React.Dispatch<React.SetStateAction<UnacknowledgeIncidentDto[]>>
}

const AccidentsContext = createContext<AccidentsContext | undefined>(undefined)

export function useAccidentsContext() {
  const context = useContext(AccidentsContext)
  if (context === undefined) {
    throw new Error('useAccidentsContext must be within AccidentsContextProvider')
  }

  return context
}

export const AccidentsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notConfirmedAccidents, setNotConfirmedAccidents] = useState<UnacknowledgeIncidentDto[]>([])

  const value = useMemo(() => ({ notConfirmedAccidents, setNotConfirmedAccidents }), [notConfirmedAccidents])

  return <AccidentsContext.Provider value={value}>{children}</AccidentsContext.Provider>
}
