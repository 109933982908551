import React from 'react'
import { Box } from '@mui/material'

const PageContainer: React.FC = ({ children }) => (
  <Box height='100vh' mx={2} pb={4}>
    {children}
  </Box>
)

export default PageContainer
