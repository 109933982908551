import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ForemanDashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M76 20V28H60V20H76ZM36 20V44H20V20H36ZM76 52V76H60V52H76ZM36 68V76H20V68H36ZM84 12H52V36H84V12ZM44 12H12V52H44V12ZM84 44H52V84H84V44ZM44 60H12V84H44V60Z' />
    </SvgIcon>
  )
}

export default ForemanDashboardIcon
