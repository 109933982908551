import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const OperatorPanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M48 24C52.4 24 56 27.6 56 32C56 36.4 52.4 40 48 40C43.6 40 40 36.4 40 32C40 27.6 43.6 24 48 24ZM48 64C58.8 64 71.2 69.16 72 72H24C24.92 69.12 37.24 64 48 64ZM48 16C39.16 16 32 23.16 32 32C32 40.84 39.16 48 48 48C56.84 48 64 40.84 64 32C64 23.16 56.84 16 48 16ZM48 56C37.32 56 16 61.36 16 72V80H80V72C80 61.36 58.68 56 48 56Z' />
    </SvgIcon>
  )
}

export default OperatorPanelIcon
