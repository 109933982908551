import React from 'react'
import { useTranslation } from 'react-i18next'
import { BlurLinear as BlurLinearIcon, Notifications as NotificationsIcon } from '@mui/icons-material'
import WidgetsIcon from '@mui/icons-material/Widgets'

import { useNotificationContext } from 'Context/NotificationContext'
import paths from 'Routes/paths'

import VariantsIcon from '../Icons/VariantsIcon'
import WorkstationIcon from '../Icons/WorkstationIcon'
import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const MaintenanceDashboardLinks = ({ collapsed }: Props) => {
  const { notificationsCount } = useNotificationContext()
  const { t } = useTranslation()

  const navItems: NavLinkItem[] = [
    {
      link: paths.technologistNotifications,
      Icon: NotificationsIcon,
      label: t('labels.notifications'),
      badge: notificationsCount,
      border: true,
    },
    {
      link: paths.technologistLines,
      Icon: BlurLinearIcon,
      label: t('labels.lines'),
    },
    {
      link: paths.technologistWorkstations,
      Icon: WorkstationIcon,
      label: t('labels.workstations'),
    },
    {
      link: paths.technologistVariants,
      Icon: VariantsIcon,
      label: t('labels.variants'),
    },
    {
      link: paths.technologistParameters,
      Icon: VariantsIcon,
      label: t('labels.parameters'),
    },
    {
      link: paths.technologistTechnologyEditor,
      Icon: WidgetsIcon,
      label: t('labels.technologies'),
    },
  ]

  return <NavLinks items={navItems} collapsed={collapsed} />
}

export default MaintenanceDashboardLinks
