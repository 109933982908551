import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExitToApp as LogoutIcon } from '@mui/icons-material'

import useLogout from 'Hooks/useLogout'

import SideNavItem from './SideNavItem'

type Props = {
  collapsed?: boolean
}

const LogoutMenuItem: React.FC<Props> = ({ collapsed }: Props) => {
  const { handleLogout, isLoggedIn } = useLogout()
  const { t } = useTranslation()

  if (!isLoggedIn) {
    return null
  }

  return (
    <SideNavItem
      collapsed={collapsed}
      border='top'
      onClick={handleLogout}
      Icon={LogoutIcon}
      label={t('labels.logout')}
    />
  )
}

export default LogoutMenuItem
