import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Dialog, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { errorCodes } from 'Consts/Errors'
import getErrorCode from 'Helpers/ApiErrorCodeGetter'
import { useRemoveOrderComponentMachine } from 'Hooks/orders/useRemoveOrderComponentMachine'
import { OrderDto } from 'Types/Orders'

type Props = {
  open: boolean
  order: OrderDto
  orderComponentId: OrderDto['orderComponents'][0]['id']
  orderComponentMachineId: string
  onClose: () => void
}

const RemoveWorkstationDialog = ({ open, order, orderComponentId, orderComponentMachineId, onClose }: Props) => {
  const { t } = useTranslation()
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(null)
  const { mutate, isLoading: isSubmitting } = useRemoveOrderComponentMachine()
  const orderComponent = order.orderComponents.find(({ id }) => id === orderComponentId)
  const machine = orderComponent?.orderComponentMachines?.find(({ id }) => id === orderComponentMachineId)

  const handleSubmit = () => {
    setSubmitErrorMessage(null)

    mutate(
      {
        orderId: order.id,
        orderComponentId,
        orderComponentMachineId,
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const errorCode = getErrorCode(error)

          if (errorCode === errorCodes.cannotRemoveOrderComponentMachine) {
            setSubmitErrorMessage(t('messages.cannotRemoveMachineFromOrderComponent'))
          } else {
            setSubmitErrorMessage(t('messages.sorryThereWasAProblemWithYourRequest'))
          }
        },
      },
    )
  }

  useEffect(() => {
    if (!machine) onClose()
  }, [machine])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{t('labels.removeWorkstationFromTheProductionOfAnOrderComponent')}</DialogTitle>
      <DialogContent>
        <Trans i18nKey='messages.confirmTheRemovalOfTheWorkstationAssignedToThePProductionOfTheComponentInTheOrder'>
          Confirm the removal of the
          <Typography component='span' fontWeight='bold'>
            {{ machineName: machine?.machineName }}
          </Typography>
          workstation assigned to the production of the
          <Typography component='span' fontWeight='bold'>
            {{ componentName: orderComponent?.name }}
          </Typography>
          component in the
          <Typography component='span' fontWeight='bold'>
            {{ orderName: order.name }}
          </Typography>
          order.
        </Trans>
      </DialogContent>
      <DialogActions errorMessage={submitErrorMessage}>
        <Button variant='text' onClick={onClose}>
          {t('labels.cancel')}
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting}>
          {t('labels.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveWorkstationDialog
