import { useMutation } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { queryClient } from 'Lib/react-query'
import { OrderDto } from 'Types/Orders'

type AddOrderComponentMachines = {
  orderId: OrderDto['id']
  orderComponentId: OrderDto['orderComponents'][0]['id']
  machineIds: string[]
}

export const useAddOrderComponentMachines = () =>
  useMutation(
    async ({ orderId, orderComponentId, machineIds }: AddOrderComponentMachines) => {
      return apiClient.putAddOrderComponentMachines(orderId, orderComponentId, { machineIds })
    },
    {
      onError: () => queryClient.invalidateQueries('orders'),
    },
  )
