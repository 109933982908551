import { nanoid } from 'nanoid'

import { PostTechnologyDto, PutTechnologyDto, TechnologyDto } from 'Types/Technologies'

import { TechnologyFormValues } from './validation'

export const mapTechnologyFormValuesToPostTechnologyDto = (values: TechnologyFormValues): PostTechnologyDto => {
  return {
    ...values,
    components: values.components.map(({ variantId, amount, machines }) => ({
      variantId,
      amount,
      machineIds: machines.map(({ id }) => id),
    })),
  }
}

export const mapTechnologyFormValuesToPutTechnologyDto = (values: TechnologyFormValues): PutTechnologyDto =>
  mapTechnologyFormValuesToPostTechnologyDto(values)

export const mapTechnologyDtoToTechnologyFormValues = (
  technology: Pick<TechnologyDto, 'name' | 'components'>,
): TechnologyFormValues => {
  return {
    ...technology,
    components: technology.components.map(({ id, name, variantId, amount, technologyComponentMachines }) => ({
      id,
      name,
      variantId,
      amount,
      machines: technologyComponentMachines,
      tempId: nanoid(),
    })),
  }
}
