import React, { useEffect, useState } from 'react'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import { IconButton as MuiIconButton, styled } from '@mui/material'

const Container = styled('div')<{ height: string }>(({ height, theme }) => ({
  heigh: height,
  maxHeight: height,
  width: '231px',
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'row',
  verticalAlign: 'middle',
  alignItems: 'center',
}))

const Icon = styled('div')<{ side: 'left' | 'right' }>(({ side, theme }) => ({
  width: '75px',
  textAlign: 'center',
  borderLeft: side === 'left' ? '2px solid' : 0,
  borderRight: side === 'left' ? 0 : '2px solid',
  borderColor: theme.palette.primary.main,
  alignItems: 'center',
}))

const NumberContainer = styled('div')(({ theme }) => ({
  height: '100%',
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightBold,
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  justifyItems: 'center',
  placeItems: 'center',
  margin: 'auto',
}))

const FloatNumber = styled('div')({
  border: 0,
  width: 'auto',
  outline: 'none',
})

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

interface Props {
  maxNumber?: number
  minNumber?: number
  onChange?: (value: number) => void
  defaultValue: number
  height?: string
  isFloat?: boolean
  setInputValue?: (name: string, value: number) => void
  name?: string
}

const NumericControl: React.FC<Props> = ({
  maxNumber = 1000,
  minNumber = 1,
  onChange = () => {},
  defaultValue,
  height = '3.25rem',
  isFloat = false,
  setInputValue = () => {},
  name = '',
}) => {
  const [value, setValue] = useState(defaultValue)
  const [selected, setSelected] = useState('integer')

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (name) {
      setInputValue(name, value)
    } else {
      onChange(value)
    }
  }, [value])

  return (
    <Container height={height}>
      <Icon side='right'>
        <IconButton
          style={{ height, width: height }}
          data-testid='numeric-control-decrease-value'
          onClick={() =>
            setValue((prev) => {
              if (selected === 'integer') {
                if (prev - 1 < minNumber) {
                  return prev
                }
                const newValue = Math.round((prev - 1) * 100) / 100

                return newValue
              }
              if (prev - 0.01 < minNumber) {
                return prev
              }
              const newValue = Math.round((prev - 0.01) * 100) / 100

              return newValue
            })
          }
        >
          <RemoveIcon style={{ fontSize: `${+height / 2}px` }} key='removeIcon' />
        </IconButton>
      </Icon>
      <NumberContainer>
        <FloatNumber onClick={() => setSelected('integer')} data-testid='numeric-control-value-int' key='int-part'>
          {value?.toString().split('.')[0] || 0}
        </FloatNumber>
        {isFloat && (
          <>
            <div>,</div>
            <FloatNumber onClick={() => setSelected('decimal')} data-testid='numeric-control-value-dec' key='dec-part'>
              {value?.toString().split('.')[1] || 0}
            </FloatNumber>
          </>
        )}
      </NumberContainer>
      <Icon side='left' key='add-icon'>
        <IconButton
          key='add'
          style={{ height, width: height }}
          data-testid='numeric-control-increase-value'
          onClick={() =>
            setValue((prev) => {
              if (selected === 'integer') {
                if (prev + 1 > maxNumber) {
                  return prev
                }
                const newValue = Math.round((prev + 1) * 100) / 100

                return newValue
              }
              if (prev + 0.01 > maxNumber) {
                return prev
              }
              const newValue = Math.round((prev + 0.01) * 100) / 100

              return newValue
            })
          }
        >
          <AddIcon style={{ fontSize: `${+height / 2}px` }} key='addIcon' />
        </IconButton>
      </Icon>
    </Container>
  )
}

export default NumericControl
