import React from 'react'
import { alpha, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'

interface DayProps extends PickersDayProps<Date> {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
  isOnlyOneDay: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay' && prop !== 'isOnlyOneDay',
})<DayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay, isOnlyOneDay }) => ({
  '&.Mui-selected': {
    color: 'inherit',
    backgroundColor: 'inherit',
    fotWeight: 400,
  },
  '&:focus.Mui-selected': {
    backgroundColor: 'inherit',
  },
  ...(dayIsBetween && {
    backgroundColor: 'transparent',
  }),
  ...((isFirstDay || isLastDay || isOnlyOneDay) && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }),
})) as React.ComponentType<DayProps>

const Day = ({ dayIsBetween, isFirstDay, isLastDay, isOnlyOneDay, ...props }: DayProps) => {
  return (
    <Box
      sx={{
        ...((isFirstDay || isLastDay || dayIsBetween || isOnlyOneDay) && {
          backgroundColor: (theme) =>
            props.outsideCurrentMonth ? theme.palette.background.paper : alpha(theme.palette.primary.main, 0.5),
          color: (theme) =>
            props.outsideCurrentMonth
              ? theme.palette.text.primary
              : theme.palette.getContrastText(alpha(theme.palette.primary.main, 0.5)),
        }),
        ...(isFirstDay && {
          borderTopLeftRadius: '50%',
          borderBottomLeftRadius: '50%',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }),
        ...(isLastDay && {
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }),
        ...(isOnlyOneDay && {
          borderRadius: '50%',
        }),
      }}
    >
      <CustomPickersDay
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isOnlyOneDay={isOnlyOneDay}
        {...props}
      />
    </Box>
  )
}

export default Day
