import React, { createContext, useContext, useMemo, useState } from 'react'

import type { ShiftConfigurationElement, ShiftsConfigurationDto } from 'Types'

type ShiftsConfiguration = ShiftsConfigurationDto | null
type CurrentShift = ShiftConfigurationElement | null

interface ShiftsContext {
  shiftsConfiguration: ShiftsConfiguration
  setShiftsConfiguration: React.Dispatch<React.SetStateAction<ShiftsConfiguration>>
  currentShift: CurrentShift
  setCurrentShift: React.Dispatch<React.SetStateAction<CurrentShift>>
}

const ShiftsContext = createContext<ShiftsContext | undefined>(undefined)

export function useShiftsConfigurationContext() {
  const context = useContext(ShiftsContext)
  if (context === undefined) {
    throw new Error('useShiftsContext must be within ShiftsContextProvider')
  }

  return context
}

export const ShiftsContextProvider: React.FC = ({ children }) => {
  const [shiftsConfiguration, setShiftsConfiguration] = useState<ShiftsConfiguration>(null)
  const [currentShift, setCurrentShift] = useState<CurrentShift>(null)

  const value: ShiftsContext = useMemo(
    () => ({
      shiftsConfiguration,
      setShiftsConfiguration,
      currentShift,
      setCurrentShift,
    }),
    [shiftsConfiguration, currentShift],
  )

  return <ShiftsContext.Provider value={value}>{children}</ShiftsContext.Provider>
}
