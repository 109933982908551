import { useController, useFormContext } from 'react-hook-form'
import { TextField, type TextFieldProps } from '@mui/material'

import type { FormField } from './types'

export type FormTextFieldProps = FormField & Omit<TextFieldProps, 'inputRef' | 'label' | 'value' | 'error'>

export const FormTextField = ({ label, name, helperText, ...otherProps }: FormTextFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { ref, value, ...fieldProps },
    fieldState: { error, invalid },
  } = useController({ name, control })

  return (
    <TextField
      inputRef={ref}
      label={label}
      error={invalid}
      helperText={invalid ? error?.message : helperText}
      value={value ?? ''}
      fullWidth
      {...fieldProps}
      {...otherProps}
    />
  )
}
