import React from 'react'

import AppNames from 'Consts/AppNames'
import { useMachineContext } from 'Context/MachineContext'
import { useUser as useUserContext } from 'Context/UserContext'

import FilesToAcknowledge from './FilesToAcknowledge'

export const FilesToAcknowledgeHandler = () => {
  const { user, selectedApp } = useUserContext()
  const { machine, isAvailable } = useMachineContext()

  if (selectedApp === AppNames.OperatorPanel && user && machine && isAvailable) {
    return <FilesToAcknowledge machineId={machine.id} />
  }

  return null
}

export default FilesToAcknowledgeHandler
