import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'

import { apiClient } from 'Api/ApiClient'
import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import ManualNumericControl from 'Components/NumericControl/ManualNumericControl'
import HubMethods from 'Consts/HubMethods'
import WorkstationStates, { type WorkstationState } from 'Consts/WorkstationStates'
import { useDialogsContext } from 'Context/DialogsContext'
import { useMachineContext } from 'Context/MachineContext'
import { useProductionContext } from 'Context/ProductionContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { FailureBunchProducedMessage } from 'Types/HubMessages'

interface Props {
  open: boolean
  onClose: () => void
  openFailureStatusSelect?: () => void
  selectedStatus: WorkstationState | null
}

const FailureUnitsDialog: React.FC<Props> = ({ open = false, onClose, openFailureStatusSelect, selectedStatus }) => {
  const [validFailureUnits, setValidFailureUnits] = useState(1)
  const [failureUnitsProduced, setFailureUnitsProduced] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const { machine } = useMachineContext()
  const { hubConnection } = useSignalRContext()
  const { setUnconfirmedStatus } = useProductionContext()
  const { setMultipleUnitsDialogOpen } = useDialogsContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (machine) {
      apiClient.getFailureUnitsProducedAmount(machine.id).then((response) => {
        const failureUnitsAmount = response.data

        setFailureUnitsProduced(failureUnitsAmount)
      })
    }
  }, [])

  const handleFailureBunchProducedMessage = ({ producedUnitCount }: FailureBunchProducedMessage) => {
    setFailureUnitsProduced((prevUnits) => prevUnits + producedUnitCount)
  }

  useEffect(() => {
    hubConnection?.on(HubMethods.FailureBunchProduced, handleFailureBunchProducedMessage)

    return () => {
      hubConnection?.off(HubMethods.FailureBunchProduced, handleFailureBunchProducedMessage)
    }
  }, [hubConnection])

  const saveStatus = async () => {
    try {
      if (machine) {
        setIsSaving(true)

        await apiClient.postValidFailureUnits(machine.id, { unitCount: validFailureUnits })

        if (selectedStatus === WorkstationStates.NORMAL_WORK) {
          await apiClient.putWorkstationStatusNormalWork(machine.id)
        }

        if (selectedStatus === WorkstationStates.WORK_WITH_FAILURE) {
          setUnconfirmedStatus(WorkstationStates.WORK_WITH_FAILURE)
          await apiClient.putWorkstationWorkWithFailureReason(machine.id, {
            reasonId: null,
            reasonDescription: null,
          })

          if (machine?.defaultUnitProductionCount > 1) {
            setMultipleUnitsDialogOpen(true)
          }
        }

        setIsSaving(false)
        onClose()
      }
    } catch {
      setIsSaving(false)
    }
  }

  if (!machine) {
    return null
  }

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography>
        <IconButton
          sx={{ position: 'absolute', top: '6px', left: '6px' }}
          aria-label='back-arrow'
          size='small'
          disabled={isSaving}
          onClick={() => {
            onClose()
            openFailureStatusSelect?.()
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h5'>
          {t('messages.indicateHowManyUnitsFromTestCycleShouldBeCountedAsProducedUnits')}
        </Typography>
        <Box display='flex' justifyContent='center' mt={4}>
          <ManualNumericControl
            minNumber={0}
            maxNumber={failureUnitsProduced}
            defaultValue={failureUnitsProduced}
            onChange={setValidFailureUnits}
            disableFloat
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          onClick={() => {
            onClose()
            openFailureStatusSelect?.()
          }}
        >
          {t('labels.back')}
        </Button>
        <Button
          onClick={() => {
            saveStatus()
          }}
          disabled={isSaving}
        >
          {t('labels.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FailureUnitsDialog
