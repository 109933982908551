import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import {
  OrderStatus as StatusType,
  OrderStatuses as StatusTypes,
  OrderStatusesTranslationKeys,
} from 'Consts/OrderStatuses'

import { getColor } from './utils'

type Props = { status: StatusType; inLine?: boolean }

const getStatusTranslation = (status: StatusType) => {
  if (status === StatusTypes.Ended) return OrderStatusesTranslationKeys[StatusTypes.Ended]
  if (status === StatusTypes.Stopped) return OrderStatusesTranslationKeys[StatusTypes.Stopped]
  if (status === StatusTypes.Started) return OrderStatusesTranslationKeys[StatusTypes.Started]
  if (status === StatusTypes.NotStarted) return OrderStatusesTranslationKeys[StatusTypes.NotStarted]

  return OrderStatusesTranslationKeys[StatusTypes.NotStarted]
}

const OrderStatus = ({ status, inLine = false }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {inLine ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Typography variant='caption' fontWeight='500' lineHeight={1.2} whiteSpace='nowrap'>
            {t(getStatusTranslation(status)).toUpperCase()}
          </Typography>
          <Box bgcolor={getColor(status)} sx={{ height: 16, width: 16, borderRadius: '50%', marginLeft: '10px' }} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Box bgcolor={getColor(status)} sx={{ height: 16, width: 16, borderRadius: '50%' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography variant='caption' fontWeight='500' lineHeight={1.2}>
              {t(getStatusTranslation(status)).toUpperCase()}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default OrderStatus
