import React, { useEffect } from 'react'

import { apiClient } from 'Api/ApiClient'
import HubMethods from 'Consts/HubMethods'
import { useAccidentsContext } from 'Context/AccidentsContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { IncidentCreatedMessage } from 'Types'

import AccidentConfirmationDialog from './AccidentConfirmationDialog'

interface Props {
  onAccidentConfirmation?: (id: string) => void
}

const AccidentsHandler: React.FC<Props> = ({ onAccidentConfirmation }) => {
  const { notConfirmedAccidents, setNotConfirmedAccidents } = useAccidentsContext()
  const { hubConnection } = useSignalRContext()

  useEffect(() => {
    apiClient.getUnacknowledgedIncidents().then(({ data }) => {
      setNotConfirmedAccidents(data)
    })
  }, [])

  useEffect(() => {
    const handleIncidentCreatedMessage = (incident: IncidentCreatedMessage) => {
      setNotConfirmedAccidents((prevIncidents) => {
        const found = prevIncidents.find((prevIncident) => prevIncident.incidentId === incident.incidentId)
        if (!found) {
          return [...prevIncidents, incident]
        }

        return prevIncidents
      })
    }
    hubConnection?.on(HubMethods.IncidentCreated, handleIncidentCreatedMessage)

    return () => {
      hubConnection?.off(HubMethods.IncidentCreated, handleIncidentCreatedMessage)
    }
  }, [hubConnection])

  const handleAccidentConfirmation = (id: string) => {
    setNotConfirmedAccidents((prevIncidents) => prevIncidents.filter((incident) => incident.incidentId !== id))
    if (onAccidentConfirmation) {
      onAccidentConfirmation(id)
    }
  }

  const accidentConfirmationDialogOpen = notConfirmedAccidents?.length > 0
  if (accidentConfirmationDialogOpen) {
    return (
      <AccidentConfirmationDialog
        open={accidentConfirmationDialogOpen}
        onClose={handleAccidentConfirmation}
        incident={notConfirmedAccidents[0]}
      />
    )
  }

  return null
}

export default AccidentsHandler
