import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Typography } from '@mui/material'

type Props = {
  primaryMessage: string
  secondaryMessage?: string
  Icon?: React.ElementType
}

const ZeroState = ({ primaryMessage, secondaryMessage, Icon }: Props) => {
  return (
    <Stack spacing={3} p={4} alignItems='center'>
      {Icon ? <Icon fontSize='large' color='info' /> : <InfoOutlinedIcon fontSize='large' color='info' />}
      <Stack spacing={1} alignItems='center'>
        <Typography fontSize={20} fontWeight='medium' textAlign='center'>
          {primaryMessage}
        </Typography>
        {secondaryMessage && (
          <Typography fontSize={16} color='text.secondary' textAlign='center'>
            {secondaryMessage}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default ZeroState
