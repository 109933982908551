export const EventNames = {
  SIGNED_IN: 'Signed In',
  SIGNED_OFF: 'Signed Off',
  PANEL_OPENED: 'Panel - Opened',
  PANEL_CLOSED: 'Panel - Closed',
  PAGE_VISITED: 'Page - Visited',
  PAGE_LEFT: 'Page - Left',
} as const

export const GroupNames = {
  ENVIRONMENT: 'Environment',
  APP_VERSION: 'App Version',
  RELEASE_TAG: 'Release Tag',
} as const

export const EventProperties = {
  PANEL_NAME: 'panel_name',
  PAGE_NAME: 'page_name',
  LOGIN_METHOD: 'login_method',
} as const
