import { useController, useFormContext } from 'react-hook-form'
import { TextField } from '@mui/material'
import { DatePicker as MuiDatePicker, type DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers'

import type { FormField } from './types'

export type FormDatePicker<TInputDate, TDate> = FormField &
  Omit<MuiDatePickerProps<TInputDate, TDate>, 'value' | 'renderInput' | 'onChange'> & {
    onChange?: MuiDatePickerProps<TInputDate, TDate>['onChange']
  } & {
    helperText?: string
  }

export const FormDatePicker = <TInputDate, TDate>(props: FormDatePicker<TInputDate, TDate>) => {
  const { control } = useFormContext()
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({ name: props.name, control })

  return (
    <MuiDatePicker
      {...field}
      value={field.value === undefined ? null : field.value}
      inputRef={ref}
      inputFormat='dd/MM/yyyy'
      PaperProps={{
        sx: { borderRadius: 4, '& .PrivatePickersFadeTransitionGroup-root': { fontSize: '1.25rem' } },
      }}
      renderInput={(inputProps) => {
        return (
          <TextField
            {...inputProps}
            label={props.label}
            style={{ marginTop: 0 }}
            error={fieldState.invalid}
            helperText={fieldState.invalid ? fieldState.error?.message : props.helperText}
            fullWidth
          />
        )
      }}
      {...props}
      views={['day']}
      disabled={props.disabled}
      onChange={(value, keyboardInputValue) => {
        field.onChange(value, keyboardInputValue)
        props.onChange?.(value, keyboardInputValue)
      }}
    />
  )
}
