import WorkstationStates, {
  type WorkstationState,
  WorkstationStateIcons,
  WorkstationStateShortTranslationKeys,
  WorkstationStatesTranslationKeys,
} from 'Consts/WorkstationStates'
import { palette } from 'Theme/colors'

const FALLBACK_STATUS = WorkstationStates.IDLE

export const getColor = (type?: WorkstationState | null) => {
  switch (type) {
    case WorkstationStates.NOT_WORKING:
      return palette.status.notWorking.main
    case WorkstationStates.MICROIDLE:
      return palette.status.microidle.main
    case WorkstationStates.IDLE:
      return palette.status.idle.main
    case WorkstationStates.NORMAL_WORK:
      return palette.status.normalWork.main
    case WorkstationStates.FAILURE:
      return palette.status.failure.main
    case WorkstationStates.WORK_WITH_FAILURE:
      return palette.status.workWithFailure.main
    case WorkstationStates.RETOOL:
      return palette.status.retool.main
    case WorkstationStates.SUSPENDED_RETOOL:
      return palette.status.suspendedRetool.main
    default:
      return palette.status.idle.main
  }
}

export const getStatusColorContrastText = (type?: WorkstationState | null) => {
  switch (type) {
    case WorkstationStates.NOT_WORKING:
      return palette.status.notWorking.contrastText
    case WorkstationStates.MICROIDLE:
      return palette.status.microidle.contrastText
    case WorkstationStates.IDLE:
      return palette.status.idle.contrastText
    case WorkstationStates.NORMAL_WORK:
      return palette.status.normalWork.contrastText
    case WorkstationStates.FAILURE:
      return palette.status.failure.contrastText
    case WorkstationStates.WORK_WITH_FAILURE:
      return palette.status.workWithFailure.contrastText
    case WorkstationStates.RETOOL:
      return palette.status.retool.contrastText
    case WorkstationStates.SUSPENDED_RETOOL:
      return palette.status.suspendedRetool.contrastText
    default:
      return palette.status.idle.contrastText
  }
}

export const getText = (type?: WorkstationState) => {
  if (typeof type === 'string' && type in WorkstationStatesTranslationKeys) {
    return WorkstationStatesTranslationKeys[type]
  }

  return WorkstationStatesTranslationKeys[FALLBACK_STATUS]
}

export const getStatusShortText = (type?: WorkstationState) => {
  if (typeof type === 'string' && type in WorkstationStateShortTranslationKeys) {
    return WorkstationStateShortTranslationKeys[type]
  }

  return WorkstationStateShortTranslationKeys[FALLBACK_STATUS]
}

export const getStatusIconComponent = (status: WorkstationState) => {
  if (typeof status === 'string' && status in WorkstationStateIcons) {
    return WorkstationStateIcons[status]
  }

  return WorkstationStateIcons[FALLBACK_STATUS]
}

export const getAdditionalText = (type = '') => {
  switch (type) {
    case WorkstationStates.FAILURE:
      return 'labels.machineProblem'
    case WorkstationStates.WORK_WITH_FAILURE:
      return 'labels.vulnerability'
    default:
      return ''
  }
}
