import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  BuildOutlined as BuildOutlinedIcon,
  Dashboard as DashboardIcon,
  EventNote as TasksAssignmentIcon,
  Factory as LineLayoutIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Notifications as NotificationsIcon,
  Traffic as TrafficIcon,
  ViewQuilt as ViewQuiltIcon,
} from '@mui/icons-material'

import { useNotificationContext } from 'Context/NotificationContext'
import paths from 'Routes/paths'

import CheckListIcon from '../Icons/CheckListIcon'
import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const MaintenanceDashboardLinks = ({ collapsed }: Props) => {
  const { t } = useTranslation()
  const { notificationsCount } = useNotificationContext()

  const navItems: NavLinkItem[] = [
    {
      link: paths.maintenanceNotifications,
      label: t('labels.notifications'),
      Icon: NotificationsIcon,
      border: true,
      badge: notificationsCount,
    },
    {
      link: paths.maintenanceRepairTasksAssignment,
      Icon: TasksAssignmentIcon,
      label: t('labels.failureList'),
    },
    {
      link: paths.maintenanceMaintenanceTasksAssignment,
      Icon: TasksAssignmentIcon,
      label: t('labels.maintenanceList'),
    },
    {
      link: paths.maintenanceLineStatus,
      label: t('labels.lineStatus'),
      Icon: TrafficIcon,
    },
    {
      link: paths.maintenanceFiles,
      Icon: InsertDriveFileIcon,
      label: t('labels.files'),
    },
    {
      link: paths.maintenanceCheckLists,
      Icon: CheckListIcon,
      label: t('labels.checkLists'),
    },
    {
      link: paths.maintenancePdca,
      Icon: ViewQuiltIcon,
      label: t('labels.pdca'),
    },
    {
      link: paths.maintenanceLineLayout,
      Icon: LineLayoutIcon,
      label: t('labels.lineLayout'),
    },
    {
      link: paths.maintenanceMaintenancePlanner,
      Icon: BuildOutlinedIcon,
      label: t('labels.maintenancePlanner'),
    },
    {
      link: paths.maintenanceDashboard,
      Icon: DashboardIcon,
      label: t('labels.dashboard'),
    },
  ]

  return <NavLinks items={navItems} collapsed={collapsed} />
}

export default MaintenanceDashboardLinks
