import { useController, useFormContext } from 'react-hook-form'

import SelectVirtualized, { type SelectVirtualizedProps } from 'Components/SelectVirtualized'

import type { FormField } from './types'

type OptionValue = string | number
export type FormSelectVirtualized<T extends OptionValue> = FormField & Omit<SelectVirtualizedProps<T>, 'value'>

export const FormSelectVirtualized = <T extends OptionValue>(props: FormSelectVirtualized<T>) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ name: props.name, control })

  return (
    <SelectVirtualized
      {...props}
      value={value}
      onChange={(event) => {
        onChange(event)
        props.onChange?.(event)
      }}
      error={fieldState.invalid}
      helperText={fieldState.invalid ? fieldState.error?.message : props.helperText}
    />
  )
}
