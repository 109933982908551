import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const AdminPanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M62.3999 61.0562C64.6267 61.0562 66.4319 59.251 66.4319 57.0242C66.4319 54.7974 64.6267 52.9922 62.3999 52.9922C60.1731 52.9922 58.3679 54.7974 58.3679 57.0242C58.3679 59.251 60.1731 61.0562 62.3999 61.0562Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.3999 64.2246C59.7599 64.2246 54.5279 65.5206 54.3359 68.1126C56.1599 70.6566 59.0879 72.2886 62.3999 72.2886C65.7119 72.2886 68.6879 70.6086 70.4639 68.1126C70.2719 65.4726 65.0399 64.2246 62.3999 64.2246Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M67.2 43.8722V29.6642L43.2 19.2002L19.2 29.6642V45.3602C19.2 59.9042 29.424 73.4882 43.2 76.8002C44.88 76.4162 46.56 75.8402 48.096 75.1202C51.648 79.0562 56.688 81.6002 62.4 81.6002C73.008 81.6002 81.6 73.0082 81.6 62.4002C81.6 53.4722 75.456 46.0322 67.2 43.8722ZM43.2 71.8562C32.16 68.6402 24 57.4562 24 45.4082V32.8322L43.2 24.4322L62.4 32.8322V43.2002C51.792 43.2002 43.2 51.7922 43.2 62.4002C43.2 65.5202 44.0159 68.4002 45.312 70.9922C44.5919 71.2802 43.92 71.6162 43.2 71.8562ZM62.4 76.8002C54.432 76.8002 48 70.3682 48 62.4002C48 54.4322 54.432 48.0002 62.4 48.0002C70.368 48.0002 76.8 54.4322 76.8 62.4002C76.8 70.3682 70.368 76.8002 62.4 76.8002Z'
      />
    </SvgIcon>
  )
}

export default AdminPanelIcon
