import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { GetRetoolBunchesApiResponse } from 'Types'

export const useRetoolBunches = (
  id: string,
  options?: UseQueryOptions<
    GetRetoolBunchesApiResponse,
    unknown,
    GetRetoolBunchesApiResponse,
    ['retool-bunches', string]
  >,
) =>
  useQuery(
    ['retool-bunches', id],
    async () => {
      const { data } = await apiClient.getRetoolBunches(id)

      return data
    },
    options,
  )

export default useRetoolBunches
