import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import Config from 'config'

const SEM_VER_REGEX =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

const getAppVersion = ({ releaseTag, appVersion }: { releaseTag?: string; appVersion?: string }) => {
  if (releaseTag?.match(SEM_VER_REGEX)) {
    return releaseTag
  }

  return appVersion
}

const AppVersionLabel = () => {
  const { t } = useTranslation()

  return (
    <Box position='absolute' bottom={0} right={0} pb={0.5} pr={1}>
      <Typography variant='caption' color='text.secondary'>
        {`${t('labels.version')}: ${getAppVersion({ releaseTag: Config.RELEASE_TAG, appVersion: Config.APP_VERSION })}`}
      </Typography>
    </Box>
  )
}

export default AppVersionLabel
