import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'

import { AnyType } from 'Types'

import AppVersionLabel from './AppVersionLabel'

const ContentContainer = ({ children }: { children: React.ReactNode }) => {
  const {
    breakpoints,
    custom: { sidebar },
  } = useTheme()
  const isNarrow = useMediaQuery(breakpoints.down('lg'))

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        marginLeft: `${isNarrow ? sidebar.collapsedWidth : sidebar.width}px`,
      }}
      onContextMenu={(event: AnyType) => {
        if (event?.nativeEvent?.pointerType === 'touch') {
          event.preventDefault()
        }
      }}
    >
      {children}
      <AppVersionLabel />
    </Box>
  )
}

export default ContentContainer
