import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { WorkstationCurrentOrderApiResponse } from 'Types'

export const useWorkstationCurrentOrder = (
  id: string,
  options?: UseQueryOptions<
    WorkstationCurrentOrderApiResponse,
    unknown,
    WorkstationCurrentOrderApiResponse,
    ['workstation-current-order', string]
  >,
) =>
  useQuery(
    ['workstation-current-order', id],
    async () => {
      const { data } = await apiClient.getWorkstationCurrentOrder(id)

      return data
    },
    options,
  )
