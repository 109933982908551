import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box, Button, Dialog, Stack, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import ManualNumericControl from 'Components/NumericControl/ManualNumericControl'
import { useMachineContext } from 'Context/MachineContext'
import { useProductionContext } from 'Context/ProductionContext'
import useRetoolBunches from 'Hooks/workstations/useRetoolBunches'
import paths from 'Routes/paths'

type Props = {
  open: boolean
  onClose: () => void
}

const SuspendRetoolDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { machine } = useMachineContext()
  const machineId = machine?.id
  const [previousVariantUnits, setPreviousVariantUnits] = useState<number>(0)
  const { data: producedBunches, isFetching } = useRetoolBunches(machineId!, {
    enabled: !!machineId && open,
  })
  const { setPreviousRetoolOKs } = useProductionContext()

  const handleSuspendRetoolClick = () => {
    setPreviousRetoolOKs(previousVariantUnits)
    history.push(paths.operatorReportSuspendedRetoolReason)
    onClose()
  }

  const handleCancel = () => {
    setPreviousRetoolOKs(0)
    onClose()
  }

  if (isFetching) {
    return <div />
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle onClose={handleCancel}>{t('labels.suspendRetool')}</DialogTitle>
      {producedBunches?.previousRetool && producedBunches.unitsCount > 0 ? (
        <DialogContent>
          <Stack spacing={3}>
            <Box>
              <Typography>{t('messages.indicateHowManyUnitsFromRetoolShouldBeCountedAsProducedUnits')}.</Typography>
              <Typography gutterBottom>{t('messages.unitsNotAssignedToAVariantWillBeTreatedAsTestUnits')}.</Typography>
            </Box>
            <Typography color='text.secondary'>
              {t('labels.producedUnits')}: {producedBunches.unitsCount}
            </Typography>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt={3} key='previous-units-count'>
              <Typography variant='body1'>{t('messages.previousVariant')}:</Typography>
              <ManualNumericControl
                minNumber={0}
                defaultValue={producedBunches?.unitsCount}
                onChange={setPreviousVariantUnits}
                disableFloat
                maxNumber={producedBunches?.unitsCount}
              />
            </Box>
          </Stack>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography>{t('messages.clickNextToGiveTheReasonForTheRetoolingSuspension')}.</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel} variant='text'>
          {t('labels.cancel')}
        </Button>
        <Button onClick={handleSuspendRetoolClick} disabled={isFetching}>
          {t('labels.next')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SuspendRetoolDialog
