import { Typography, type TypographyProps } from '@mui/material'

import Tooltip, { type TooltipProps } from 'Components/Tooltip'

type Props = {
  children: string
  maxLines?: number
  slotProps?: Partial<{ tooltip: Partial<TooltipProps>; typography: Partial<TypographyProps> }>
}

const EllipsisTextTooltip = ({ children, maxLines = 2, slotProps }: Props) => {
  return (
    <Tooltip title={children} {...slotProps?.tooltip}>
      <Typography
        fontSize='inherit'
        {...slotProps?.typography}
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          ...slotProps?.typography?.sx,
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  )
}

export default EllipsisTextTooltip
