import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material'
import { Button, Dialog } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle, DialogTitleWithIcon } from 'Components/Dialog'

type Props = {
  open: boolean
  onClose: () => void
}

const NetworkErrorDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose} disableTypography>
        <DialogTitleWithIcon Icon={ErrorOutlineIcon} IconProps={{ color: 'info' }}>
          {t('messages.noInternetConnection')}
        </DialogTitleWithIcon>
      </DialogTitle>
      <DialogContent>{t('messages.checkYourInternetConnectionAndTryAgain')}</DialogContent>
      <DialogActions actionsVariant='center'>
        <Button variant='text' onClick={onClose}>
          {t('labels.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NetworkErrorDialog
