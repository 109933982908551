import { useController, useFormContext } from 'react-hook-form'
import { TextField, TextFieldProps } from '@mui/material'
import {
  DateTimePicker as MuiDateTimePicker,
  type DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers'

import { CustomDateTimePickerToolbarComponent } from 'Layout/CustomDateTimePickerToolbar'

import type { FormField } from './types'

export type FormDateTimePicker<TInputDate, TDate> = FormField &
  Omit<MuiDateTimePickerProps<TInputDate, TDate>, 'value' | 'renderInput' | 'onChange'> & {
    onChange?: MuiDateTimePickerProps<TInputDate, TDate>['onChange']
    textFieldProps?: TextFieldProps
  }

export const FormDateTimePicker = <TInputDate, TDate>(props: FormDateTimePicker<TInputDate, TDate>) => {
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name: props.name, control })

  return (
    <MuiDateTimePicker
      {...field}
      value={field.value === undefined ? null : field.value}
      ToolbarComponent={CustomDateTimePickerToolbarComponent}
      hideTabs={false}
      ampm={false}
      PaperProps={{
        sx: { borderRadius: 4, '& .PrivatePickersFadeTransitionGroup-root': { fontSize: '1.25rem' } },
      }}
      inputFormat='yyyy/MM/dd HH:mm'
      renderInput={(inputProps) => {
        return (
          <TextField
            {...inputProps}
            {...props.textFieldProps}
            label={props.label}
            style={{ ...props.textFieldProps?.style, marginTop: 0 }}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )
      }}
      {...props}
      onChange={(value, keyboardInputValue) => {
        field.onChange(value, keyboardInputValue)
        props.onChange?.(value, keyboardInputValue)
      }}
    />
  )
}
