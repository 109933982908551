import { useController, useFormContext } from 'react-hook-form'
import { styled, Switch, type SwitchProps } from '@mui/material'

import type { FormField } from './types'

const SwitchStyled = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
}))

export type FormSwitchProps = FormField & Omit<SwitchProps, 'name'>

export const FormSwitch = (props: FormSwitchProps) => {
  const { control } = useFormContext()
  const { field } = useController({ name: props.name, control })

  return (
    <SwitchStyled
      {...props}
      checked={field.value}
      name={field.name}
      onChange={(value, checked) => {
        field.onChange(value)
        props.onChange?.(value, checked)
      }}
    />
  )
}
