import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

import { AppNamesType } from 'Consts/AppNames'
import { useUser as useUserContext } from 'Context/UserContext'
import Analytics from 'Lib/Analytics'

type PublicRouteProps = {
  path: string
  route: {
    component: React.ElementType
    app?: AppNamesType
    name?: string
  }
  exact?: boolean
}

const PublicRoute = ({ route, path }: PublicRouteProps) => {
  const { setSelectedApp } = useUserContext()
  const Component = route.component

  useEffect(() => {
    if (route.app) {
      setSelectedApp(route.app)
    }
    if (route.name) {
      Analytics.track.pageVisited({ name: route.name, selectedApp: route.app })
    }

    return () => {
      if (route.name) {
        Analytics.track.pageLeft({ name: route.name, selectedApp: route.app })
      }
    }
  }, [route])

  return <Route exact path={path} render={(props) => <Component {...props} />} />
}

export default PublicRoute
