import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const GroupAddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d='M22 9V7H20V9H18V11H20V13H22V11H24V9H22Z' />
      <path d='M8 12C10.21 12 12 10.21 12 8C12 5.79 10.21 4 8 4C5.79 4 4 5.79 4 8C4 10.21 5.79 12 8 12ZM8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6Z' />
      <path d='M8 13C5.33 13 0 14.34 0 17V20H16V17C16 14.34 10.67 13 8 13ZM14 18H2V17.01C2.2 16.29 5.3 15 8 15C10.7 15 13.8 16.29 14 17V18Z' />
      <path d='M12.5098 4.0498C13.4298 5.1098 13.9998 6.4898 13.9998 7.9998C13.9998 9.5098 13.4298 10.8898 12.5098 11.9498C14.4698 11.6998 15.9998 10.0398 15.9998 7.9998C15.9998 5.9598 14.4698 4.2998 12.5098 4.0498Z' />
      <path d='M16.5298 13.8301C17.4198 14.6601 17.9998 15.7001 17.9998 17.0001V20.0001H19.9998V17.0001C19.9998 15.5501 18.4098 14.4901 16.5298 13.8301Z' />
    </SvgIcon>
  )
}

export default GroupAddIcon
