import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Dialog, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import NumericControl from 'Components/NumericControl'

interface Props {
  actualUnitProductionCount: number
  onAccept: (unitsCount: number) => void
  title: string
  actionDescription: string
  open: boolean
  buttonDisabled: boolean
}

export const MultipleUnitsDialog: React.FC<Props> = ({
  open,
  onAccept,
  title,
  actionDescription,
  actualUnitProductionCount,
  buttonDisabled,
}) => {
  const [unitsCount, setUnitsCount] = useState(actualUnitProductionCount)
  const { t } = useTranslation()

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant='h5' align='center'>
          {actionDescription}
        </Typography>
        <Box display='flex' justifyContent='center' mt={4}>
          <NumericControl
            minNumber={1}
            maxNumber={actualUnitProductionCount}
            defaultValue={actualUnitProductionCount}
            onChange={setUnitsCount}
          />
        </Box>
      </DialogContent>
      <DialogActions actionsVariant='center'>
        <Button
          onClick={() => {
            onAccept(unitsCount)
            setUnitsCount(actualUnitProductionCount)
          }}
          disabled={buttonDisabled}
        >
          {t('labels.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MultipleUnitsDialog
