import AppNames from 'Consts/AppNames'
import { useMachineContext } from 'Context/MachineContext'
import { useUser as useUserContext } from 'Context/UserContext'

import { useOperatorNotificationsHandler } from './useOperatorNotificationsHandler'
import { useUserNotificationsHandler } from './useUserNotificationsHandler'

const NotificationsHandler = () => {
  const { machine } = useMachineContext()
  const { user, selectedApp } = useUserContext()
  const machineId = machine?.id

  useOperatorNotificationsHandler({
    machineId: machineId!,
    enabled: !!machineId && selectedApp === AppNames.OperatorPanel && !!user?.permissions.has('OperatorPanel'),
  })

  useUserNotificationsHandler({
    selectedApp,
    enabled: !!selectedApp && selectedApp !== AppNames.OperatorPanel && !!user?.permissions.has(selectedApp),
  })

  return null
}

export default NotificationsHandler
