/* eslint-disable class-methods-use-this */
import { AxiosRequestConfig } from 'axios'
import { Buffer } from 'buffer'

import { ApiHeaders, createAxiosInstance } from 'Api/AxiosInstance'
import AppNames from 'Consts/AppNames'
import { OperatorWorkstationAbilityToLogin } from 'Types'

const A = createAxiosInstance()

export class AuthApiClient {
  credentialsLogin = (login: string, password: string, clientId: string) =>
    A.post<string>('authentication/users/login-credentials', {
      login: Buffer.from(login).toString('base64'),
      password: Buffer.from(password).toString('base64'),
      clientId,
    })

  rfidLogin = (workstationId: string, clientId: string, rfid: string) =>
    A.post('authentication/users/login-rfid', {
      workstationId,
      clientId,
      rfid,
    })

  logout = () => A.post('authentication/users/logout')

  refresToken = () => A.post('authentication/users/refresh-token')

  workstationAbilityToLogin = (workstationId: string, params: { clientId: string }, config: AxiosRequestConfig) =>
    A.get<OperatorWorkstationAbilityToLogin>(`authentication/users/${workstationId}`, { ...config, params })

  getServerTime = () => A.get<string>('configuration/server-time')

  reloginCredentialsMultiOperator = ({
    workstationId,
    login,
    password,
  }: {
    workstationId: string
    login: string
    password: string
  }) =>
    A.post(
      'authentication/multi-operators/re-login-credentials',
      {
        workstationId,
        login: Buffer.from(login).toString('base64'),
        password: Buffer.from(password).toString('base64'),
      },
      {
        headers: { [ApiHeaders.ORIGIN_APP]: AppNames.OperatorPanel },
      },
    )

  reloginMultiOperator = (data: { workstationId: string; userId: string }) =>
    A.post('authentication/multi-operators/re-login', data, {
      headers: { [ApiHeaders.ORIGIN_APP]: AppNames.OperatorPanel },
    })

  addCredentialsMultiOperator = ({
    workstationId,
    login,
    password,
  }: {
    workstationId: string
    login: string
    password: string
  }) =>
    A.put(
      'authentication/multi-operators/add-credentials',
      {
        workstationId,
        login: Buffer.from(login).toString('base64'),
        password: Buffer.from(password).toString('base64'),
      },
      {
        headers: { [ApiHeaders.ORIGIN_APP]: AppNames.OperatorPanel },
      },
    )

  addMultiOperator = (data: { workstationId: string; userId: string }) =>
    A.put(`authentication/multi-operators/add`, data, {
      headers: { [ApiHeaders.ORIGIN_APP]: AppNames.OperatorPanel },
    })

  removeMultiOperator = (data: { workstationId: string; userId: string }) =>
    A.delete(`authentication/multi-operators/remove`, {
      data,
      headers: { [ApiHeaders.ORIGIN_APP]: AppNames.OperatorPanel },
    })
}

export const authApiClient = new AuthApiClient()
