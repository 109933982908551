import { styled, TableCell } from '@mui/material'

export const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  lineHeight: 1.2,
  paddingRight: 16,
}))

export default TableHeaderCell
