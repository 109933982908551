import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const GroupSettingsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d='M4 18V17.35C4 17.01 4.16 16.69 4.41 16.54C6.1 15.53 8.03 15 10 15C10.03 15 10.05 15 10.08 15.01C10.18 14.31 10.38 13.64 10.67 13.03C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.84 19.4 10.51 18.72 10.29 18H4Z' />
      <path d='M10 12C12.21 12 14 10.21 14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12ZM10 6C11.1 6 12 6.9 12 8C12 9.1 11.1 10 10 10C8.9 10 8 9.1 8 8C8 6.9 8.9 6 10 6Z' />
      <path d='M20.7499 16C20.7499 15.78 20.7199 15.58 20.6899 15.37L21.8299 14.36L20.8299 12.63L19.3799 13.12C19.0599 12.85 18.6999 12.64 18.2999 12.49L17.9999 11H15.9999L15.6999 12.49C15.2999 12.64 14.9399 12.85 14.6199 13.12L13.1699 12.63L12.1699 14.36L13.3099 15.37C13.2799 15.58 13.2499 15.78 13.2499 16C13.2499 16.22 13.2799 16.42 13.3099 16.63L12.1699 17.64L13.1699 19.37L14.6199 18.88C14.9399 19.15 15.2999 19.36 15.6999 19.51L15.9999 21H17.9999L18.2999 19.51C18.6999 19.36 19.0599 19.15 19.3799 18.88L20.8299 19.37L21.8299 17.64L20.6899 16.63C20.7199 16.42 20.7499 16.22 20.7499 16ZM16.9999 18C15.8999 18 14.9999 17.1 14.9999 16C14.9999 14.9 15.8999 14 16.9999 14C18.0999 14 18.9999 14.9 18.9999 16C18.9999 17.1 18.0999 18 16.9999 18Z' />
    </SvgIcon>
  )
}

export default GroupSettingsIcon
