import { getI18n } from 'react-i18next'
import { z } from 'zod'

export const addWorkstationValidationSchema = z.object({
  machines: z
    .object({
      id: z.string(),
    })
    .array()
    .min(1, getI18n().t('messages.selectAtLeastX', { min: 1 })),
})

export type AddWorkstationFormValues = z.infer<typeof addWorkstationValidationSchema>
