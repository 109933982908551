import { createTheme, Shadows } from '@mui/material/styles'

const { shadows: defaultShadows } = createTheme()

const shadows = [
  'none',
  '0px 3px 6px #00000029',
  '0px 1.33333px 1.33333px rgba(22, 22, 21, 0.24)',
  '0px 4px 8px rgba(22, 22, 21, 0.24);',
  '0px 2px 2px rgba(22, 22, 21, 0.24);',
  '0px 6px 6px rgba(0, 0, 0, 0.26), 0px 10px 20px rgba(0, 0, 0, 0.19)',
  '0px 7px 10px 1px rgba(0, 0, 0, 0.14)',
]

const customShadows = [...shadows, ...Array(defaultShadows.length - shadows.length).fill('none')] as Shadows

export default customShadows
