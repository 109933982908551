import React, { useEffect } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { AppNamesType } from 'Consts/AppNames'
import { LocalStorageKeys } from 'Consts/LocalStorageKeys'
import { LoginMethods } from 'Consts/LoginMethods'
import { useMechanicContext } from 'Context/MechanicContext'
import { useUser as useUserContext } from 'Context/UserContext'
import { useIsAuthenticated } from 'Hooks/useIsAuthenticated'
import Analytics from 'Lib/Analytics'
import Unauthorized from 'Pages/shared/error-pages/Unauthorized'

import paths from './paths'

type PrivateRouteProps = {
  path: string
  route: {
    component: React.ElementType
    app?: AppNamesType
    name?: string
  }
  exact?: boolean
}

const PrivateRoute = ({ route, path }: PrivateRouteProps) => {
  const userIsAuthenticated = useIsAuthenticated()
  const Component = route.component
  const { setSelectedApp, user } = useUserContext()
  const { failureDetailsId } = useMechanicContext()
  const history = useHistory()

  useEffect(() => {
    if (route.app) {
      setSelectedApp(route.app)
    }
    if (route.name) {
      Analytics.track.pageVisited({ name: route.name, selectedApp: route.app })
    }

    return () => {
      if (route.name) {
        Analytics.track.pageLeft({ name: route.name, selectedApp: route.app })
      }
    }
  }, [route])

  useEffect(() => {
    if (!userIsAuthenticated) {
      const loginPreference = localStorage.getItem(LocalStorageKeys.LOGIN_PREFERENCE)
      const loginPath = loginPreference === LoginMethods.RFID ? paths.rfidLogin : paths.login

      if (history.location.pathname.startsWith(paths.mechanicTaskDetails)) {
        localStorage.setItem(
          LocalStorageKeys.REDIRECT_PATH,
          history.location.pathname.replace(':id', failureDetailsId ?? ''),
        )
      } else {
        localStorage.setItem(LocalStorageKeys.REDIRECT_PATH, history.location.pathname)
      }
      history.push(loginPath)
    }
  }, [route, userIsAuthenticated])

  if (user) {
    if (!route.app || user.permissions.has(route.app)) {
      return <Route exact path={path} render={(props) => <Component {...props} />} />
    }

    return <Redirect push to={paths.forbidden} />
  }

  return <Route exact path={path} render={() => <Unauthorized />} />
}

export default PrivateRoute
