import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, CircularProgress, Dialog, DialogContentText, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import useFocus from 'Hooks/useFocus'

type Props<T extends Record<string, unknown>> = {
  open: boolean
  title: React.ReactNode
  onCancel: () => void
  onConfirm: () => void
  isItemLoading?: boolean
  item?: T
  getItemName?: (item: T) => string
  confirmButtonLabel: string
  deleteErrorMessage?: string | null
  notFoundMessage?: string | null
  isDeleting: boolean
  renderCustomContent?: () => React.ReactNode
}

const RemoveDialog = <T extends Record<string, unknown>>({
  open,
  title,
  onCancel,
  onConfirm,
  isItemLoading = false,
  item,
  getItemName = (item: T) => (item.name as string) ?? '',
  deleteErrorMessage,
  notFoundMessage,
  confirmButtonLabel,
  isDeleting,
  renderCustomContent,
}: Props<T>) => {
  const { t } = useTranslation()

  const focusRef = useFocus()

  if (isItemLoading) {
    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle onClose={onCancel}>{title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
        <DialogActions />
      </Dialog>
    )
  }

  if (!item || notFoundMessage) {
    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle onClose={onCancel}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{notFoundMessage}</DialogContentText>
        </DialogContent>
        <DialogActions actionsVariant='center'>
          <Button variant='text' onClick={onCancel}>
            {t('labels.close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle onClose={onCancel}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AreYouSureMessage name={getItemName(item)} />
        </DialogContentText>
        {renderCustomContent?.()}
      </DialogContent>
      <DialogActions errorMessage={deleteErrorMessage}>
        <Button variant='text' onClick={onCancel}>
          {t('labels.cancel')}
        </Button>
        <Button ref={focusRef} onClick={onConfirm} disabled={isDeleting}>
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AreYouSureMessage = ({ name }: { name: string }) => {
  const { t } = useTranslation()

  return (
    <>
      <Trans t={t} i18nKey={'messages.areYouSureYouWantToRemove'}>
        Are you sure you want to remove
        <Typography component='span' fontWeight='bold'>
          {{ name }}
        </Typography>
        ?
      </Trans>{' '}
      {t('messages.remenberThisOperationIsIrreversible')}
    </>
  )
}

export default RemoveDialog
