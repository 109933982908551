import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Dialog, Stack, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { FormMultiSelectVirtualized } from 'Components/form-components'
import { errorCodes } from 'Consts/Errors'
import getErrorCode from 'Helpers/ApiErrorCodeGetter'
import { useAddOrderComponentMachines } from 'Hooks/orders/useAddOrderComponentMachines'
import { useVariantsWithMachinesQuery } from 'Hooks/variants/useVariantsWithMachinesQuery'
import { OrderDto } from 'Types/Orders'

import { AddWorkstationFormValues, addWorkstationValidationSchema } from './validation'

type Props = {
  open: boolean
  onClose: () => void
  order: OrderDto
  orderComponentId: OrderDto['orderComponents'][0]['id']
}

const AddWorkstationDialog = ({ open, onClose, order, orderComponentId }: Props) => {
  const { t } = useTranslation()
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(null)
  const formMethods = useForm<AddWorkstationFormValues>({
    resolver: zodResolver(addWorkstationValidationSchema),
    defaultValues: {
      machines: [],
    },
  })
  const {
    watch,
    handleSubmit,
    formState: { isValid },
  } = formMethods
  const { mutate, isLoading: isSubmitting } = useAddOrderComponentMachines()
  const machines = watch('machines')
  const orderComponent = order.orderComponents.find(({ id }) => id === orderComponentId)!
  const variantId = orderComponent.variantId
  const orderComponentMachines = orderComponent.orderComponentMachines ?? []
  const orderComponentMachineIds = orderComponentMachines.map(({ machineId }) => machineId)
  const { data: variantsWithMachines, isLoading: isVariantsWithMachinesLoading } = useVariantsWithMachinesQuery({
    ids: [variantId],
  })
  const variantMachines = variantsWithMachines?.find((v) => v.id === variantId)?.machines ?? []
  const componentMachinesOptions = variantMachines
    .filter(({ id, isDeleted }) => orderComponentMachineIds.includes(id) || !isDeleted)
    .map(({ id, name, isActive, isDeleted }) => {
      if (isDeleted) return { id, name: `${name} (${t('labels.deleted', { context: 'workstation' })})` }

      if (!isActive) return { id, name: `${name} (${t('labels.inactive', { context: 'workstation' })})` }

      return { id, name }
    })

  const onSubmit: SubmitHandler<AddWorkstationFormValues> = async (values) => {
    setSubmitErrorMessage(null)

    mutate(
      {
        orderId: order.id,
        orderComponentId,
        machineIds: values.machines.map(({ id }) => id),
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          if (getErrorCode(error) === errorCodes.cannotAddMachine) {
            formMethods.setError('machines', {
              type: 'manual',
              message: t('messages.cannotAddMachine'),
            })
          } else {
            setSubmitErrorMessage(t('messages.sorryThereWasAProblemWithYourRequest'))
          }
        },
      },
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog open={open} onClose={onClose} disablePortal>
          <DialogTitle onClose={onClose}>{t('labels.addWorkstationToOrderComponentProduction')}</DialogTitle>
          <DialogContent>
            <Stack spacing={3}>
              <Box>
                <Trans i18nKey='messages.addOneOrMoreWorkstationsForTheProductionOfTheComponentInTheOrder'>
                  Add one or more workstations for the production of the
                  <Typography component='span' fontWeight='bold'>
                    {{ componentName: orderComponent.name }}
                  </Typography>
                  component in the
                  <Typography component='span' fontWeight='bold'>
                    {{ orderName: order.name }}
                  </Typography>
                  order.
                </Trans>
                {t('messages.thanksToThisOperatorsAtSelectedWorkstationsWillHaveTheOpportunityToRetoolForThisOrder')}
              </Box>
              <FormMultiSelectVirtualized
                name='machines'
                label={t('labels.workstations')}
                options={componentMachinesOptions}
                disabled={isVariantsWithMachinesLoading}
                disabledOptions={orderComponentMachineIds}
              />
            </Stack>
          </DialogContent>
          <DialogActions errorMessage={submitErrorMessage}>
            <Button variant='text' onClick={onClose}>
              {t('labels.cancel')}
            </Button>
            <Button disabled={!isValid || isSubmitting} type='submit'>
              {machines.length ? `${t('labels.add')} (${machines.length})` : t('labels.add')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  )
}
export default AddWorkstationDialog
