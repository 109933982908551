import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  InputLabel,
  InputLabelProps,
  type MenuItemProps,
  OutlinedInput,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'

import { Bold } from 'Styles/utils'

export type OptionValue = string | number

type Option<T extends OptionValue> = {
  id: T
  name: string
}

export type SelectProps<T extends OptionValue> = Omit<MuiSelectProps<T>, 'value'> & {
  value?: T | null
  options: Option<T>[]
  name: string
  disabled?: boolean
  withPlaceholder?: boolean
  fullWidth?: boolean
  error?: boolean
  helperText?: string
  style?: React.CSSProperties
  itemStyle?: React.CSSProperties
  selectClassNames?: string
  menuItemClassNames?: string
  MenuItemProps?: MenuItemProps
  InputLabelProps?: InputLabelProps
  notSelectedTitle?: string
  readOnly?: boolean
  id?: string
  withoutLabel?: boolean
}

const Select = <T extends OptionValue>({
  options = [],
  value,
  label = '',
  name,
  disabled = false,
  withPlaceholder = false,
  fullWidth = false,
  error = false,
  helperText = '',
  itemStyle,
  withoutLabel,
  selectClassNames,
  menuItemClassNames,
  MenuItemProps,
  InputLabelProps,
  notSelectedTitle,
  autoFocus,
  readOnly,
  id,
  ...props
}: SelectProps<T>) => {
  const { t } = useTranslation()
  const fieldId = id ?? `${name}-multi-select`
  const labelId = `${fieldId}-label`

  return (
    <FormControl fullWidth={fullWidth} error={error} disabled={disabled}>
      {!withoutLabel && (
        <InputLabel id={labelId} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        id={fieldId}
        labelId={labelId}
        data-testid='select-component'
        value={value ?? ''}
        disabled={disabled}
        input={<OutlinedInput name={name} label={label} />}
        inputProps={{
          style: {
            backgroundClip: 'text',
          },
        }}
        autoFocus={autoFocus}
        readOnly={readOnly}
        className={selectClassNames}
        fullWidth={fullWidth}
        {...props}
      >
        {withPlaceholder && (
          <MenuItem value='' disabled className={menuItemClassNames} {...MenuItemProps}>
            <em>{t('labels.choose')}</em>
          </MenuItem>
        )}
        {notSelectedTitle && (
          <MenuItem value='' className={menuItemClassNames} {...MenuItemProps}>
            <Bold>{notSelectedTitle}</Bold>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={`listbox-item-${option.id}`}
            value={option.id}
            data-testid='select-option'
            className={menuItemClassNames}
            style={itemStyle}
            {...MenuItemProps}
          >
            {option.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
