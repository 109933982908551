import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { type GetTechnologyOptionDto } from 'Types/Technologies'

export const useAllTechnologiesList = (
  options?: UseQueryOptions<GetTechnologyOptionDto[], unknown, GetTechnologyOptionDto[], 'all-technologies-list'>,
) =>
  useQuery(
    'all-technologies-list',
    async () => {
      const { data } = await apiClient.getAllTechnologiesList()

      return data
    },
    options,
  )
