import Permissions from './Permissions'

const AppNames = {
  None: '',
  AdminPanel: Permissions.AdminPanel,
  ForemanDashboard: Permissions.ForemanDashboard,
  ForemanPanel: Permissions.ForemanPanel,
  MechanicPanel: Permissions.MechanicPanel,
  OperatorPanel: Permissions.OperatorPanel,
  PlannerPanel: Permissions.PlannerPanel,
  MaintenanceDashboard: Permissions.MaintenanceDashboard,
  TechnologistPanel: Permissions.TechnologistPanel,
} as const

type AppNamesKeys = keyof typeof AppNames
export type AppNamesType = (typeof AppNames)[AppNamesKeys]

export const AppNamesTranslationKeys = {
  [AppNames.AdminPanel as string]: 'values.adminPanel',
  [AppNames.ForemanDashboard as string]: 'values.foremanDashboard',
  [AppNames.ForemanPanel as string]: 'values.foremanPanel',
  [AppNames.MechanicPanel as string]: 'values.mechanicPanel',
  [AppNames.OperatorPanel as string]: 'values.operatorPanel',
  [AppNames.PlannerPanel as string]: 'values.plannerPanel',
  [AppNames.MaintenanceDashboard as string]: 'values.maintenanceDashboard',
  [AppNames.TechnologistPanel as string]: 'values.technologistPanel',
} as const

export default AppNames
