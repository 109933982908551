import { authApiClient } from 'Api/AuthApiClient'
import ErrorTracker from 'Lib/error-tracker'
import type { AppUser } from 'Types'

import AuthManager, { AuthEvents } from './AuthManager'
import OpenedTabsMonitor, { TabsMonitorEvents } from './OpenedTabsMonitor'

interface SessionMonitorOptions {
  clientServerTimeOffset: number
  silentRefreshTimeOffset: number
}

const SessionMonitor = () => {
  const options: SessionMonitorOptions = { silentRefreshTimeOffset: 5 * 60 * 1000, clientServerTimeOffset: 0 }
  const { events: tabsMonitorEvents, isLastActiveTab } = OpenedTabsMonitor
  const { events: authManagerEvents, getUser } = AuthManager
  let refreshSessionTimeout: ReturnType<typeof setTimeout>

  const attachEvents = () => {
    tabsMonitorEvents.on(TabsMonitorEvents.TabAcive, () => {
      if (getUser()) {
        setSessionExpiringTimeout()
      }
    })

    tabsMonitorEvents.on(TabsMonitorEvents.TabInactive, () => {
      if (!isLastActiveTab()) {
        clearTimeout(refreshSessionTimeout)
      }
    })

    authManagerEvents.on(AuthEvents.UserLoaded, () => {
      if (isLastActiveTab()) {
        setSessionExpiringTimeout()
      }
    })

    authManagerEvents.on(AuthEvents.UserUnloaded, () => {
      clearTimeout(refreshSessionTimeout)
    })
  }

  const getTimeToSessionExpire = () => {
    const user: AppUser | null = getUser()
    if (user) {
      return user.expiring * 1000 - Date.now() - options.silentRefreshTimeOffset - options.clientServerTimeOffset
    }

    return 0
  }

  const setClientServerTimeOffset = async () => {
    try {
      const { data: serverTime } = await authApiClient.getServerTime()

      options.clientServerTimeOffset = new Date().getTime() - new Date(serverTime).getTime()
    } catch {
      ErrorTracker.captureException('SessionMonitor could not fetch server time.')
    }
  }

  const init = async () => {
    attachEvents()
    await setClientServerTimeOffset()

    if (getUser() && isLastActiveTab()) {
      setSessionExpiringTimeout()
    }
  }

  const setSessionExpiringTimeout = () => {
    if (refreshSessionTimeout) {
      clearTimeout(refreshSessionTimeout)
    }

    const timeToSessionExpire = getTimeToSessionExpire()

    refreshSessionTimeout = setTimeout(async () => {
      try {
        await AuthManager.refreshToken()
      } catch (error) {
        try {
          await AuthManager.logoutAndRedirectToDefault()
        } catch {
          // error already logged in AuthManager.ts
        }
      }
    }, timeToSessionExpire)
  }

  return {
    init,
  }
}

export default SessionMonitor()
