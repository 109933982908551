import React from 'react'
import { useTranslation } from 'react-i18next'
import { Close as CloseIcon } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Button, IconButton, Snackbar, Typography } from '@mui/material'

interface Props {
  open?: boolean
  onClose?: () => void
}

const handleRefresh = () => {
  window.location.reload()
}

const ServerConnected: React.FC<Props> = ({ open = false, onClose }) => {
  const { t } = useTranslation()

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <CheckCircleIcon color='success' sx={{ mr: 1 }} />
          <Typography variant='body2'>{t('messages.serverConnectionRecovered')}</Typography>
        </Box>
        <Box width='20%' display='flex' justifyContent='center'>
          <Button
            variant='text'
            onClick={handleRefresh}
            sx={{
              fontSize: '14px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {t('labels.refresh')}
          </Button>
        </Box>
        <Box width='10%'>
          <IconButton
            onClick={onClose}
            style={{ maxHeight: '30px', maxWidth: '30px', display: 'flex', justifyContent: 'center' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Snackbar>
  )
}

export default ServerConnected
