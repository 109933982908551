import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, CircularProgress, IconButton, Snackbar, Typography } from '@mui/material'

interface Props {
  onDownload: () => void
  onCancel: () => void
  isPrepared: boolean
  isGenerating: boolean
  isError: boolean
  open?: boolean
  onClose?: () => void
}

const ReportGeneratingSnackbar = ({
  open = false,
  onClose,
  isPrepared,
  isGenerating,
  isError,
  onDownload,
  onCancel,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ borderRadius: { xs: 2, xxl: 4 }, py: { xs: 2, xxl: 4 }, height: 'auto' }}
    >
      <Box display='flex' alignItems='center'>
        {isError && (
          <>
            <InfoOutlinedIcon
              color='error'
              sx={{ width: { xs: '20px', xxl: '40px' }, height: { xs: '20px', xxl: '40px' } }}
            />
            <Typography variant='caption' fontWeight='medium' sx={{ ml: 2, mr: 1 }}>
              {t('messages.sorryThereWasAProblemWithYourRequest')}
            </Typography>
          </>
        )}
        {isGenerating && (
          <>
            <CircularProgress
              size='small'
              sx={{ width: { xs: '18px', xxl: '36px' }, height: { xs: '18px', xxl: '36px' } }}
            />
            <Typography variant='caption' fontWeight='medium' sx={{ ml: 2, mr: 1 }}>
              {t('messages.theReportIsBeingGeneratedPleaseWait')}
            </Typography>
            <Button
              variant='text'
              color='secondary'
              sx={{ fontSize: { xs: '12px', xxl: '24px' }, padding: '4px 7px' }}
              onClick={onCancel}
            >
              {t('labels.cancel')}
            </Button>
          </>
        )}
        {isPrepared && (
          <>
            <CheckCircleOutlineIcon
              color='success'
              sx={{ width: { xs: '20px', xxl: '40px' }, height: { xs: '20px', xxl: '40px' } }}
            />
            <Typography variant='caption' fontWeight='medium' sx={{ ml: 2, mr: 1 }}>
              {t('messages.reportGenerated')}
            </Typography>
            <Button
              variant='text'
              color='secondary'
              sx={{ fontSize: { xs: '12px', xxl: '24px' }, padding: '4px 7px' }}
              onClick={onDownload}
            >
              {t('labels.download')}
            </Button>
          </>
        )}
        {(isPrepared || isError) && (
          <IconButton aria-label='close' onClick={onClose} size='small'>
            <CloseIcon sx={{ width: { xs: '16px', xxl: '32px' }, height: { xs: '16px', xxl: '32px' } }} />
          </IconButton>
        )}
      </Box>
    </Snackbar>
  )
}

export default ReportGeneratingSnackbar
