import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { GetDataLoggerStatusForWorkstationApiResponse } from 'Types'

const useDataLoggerStatus = (
  machineId: string,
  options?: UseQueryOptions<
    GetDataLoggerStatusForWorkstationApiResponse,
    unknown,
    GetDataLoggerStatusForWorkstationApiResponse,
    ['data-logger-status', string | undefined]
  >,
) =>
  useQuery(
    ['data-logger-status', machineId],
    async () => {
      const { data } = await apiClient.getDataLoggerStatusForWorkstation({ machineId })

      return data
    },
    {
      keepPreviousData: true,
      refetchInterval: 30 * 1000,
      ...options,
    },
  )

export default useDataLoggerStatus
