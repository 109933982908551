import { alpha, styled, TableRow as MuiTableRow, TableRowProps as MuiTableRowProps } from '@mui/material'

type ReactTableRowProps = MuiTableRowProps & {
  expandableTable?: boolean
  showBorder?: boolean
  even?: boolean
  depth?: number
}

const ReactTableRow = ({ expandableTable, ...otherProps }: ReactTableRowProps) => {
  if (expandableTable) {
    return <TableRowExpandable {...otherProps} />
  }

  return <TableRow {...otherProps} />
}

export const TableRow = styled(MuiTableRow, {
  shouldForwardProp: (name: string) => !['depth', 'showBorder', 'even'].includes(name),
})<{ even?: boolean; showBorder?: boolean }>(({ theme, even = false, showBorder = false }) => ({
  backgroundColor: even ? theme.palette.background.default : theme.palette.background.paper,
  display: 'table',
  width: '100%',
  ...(!showBorder && {
    '& > *': {
      borderBottom: 'none !important',
    },
  }),
}))

const DEPTH_OPACITY_FACTOR = 0.02

const TableRowExpandable = styled(MuiTableRow, {
  shouldForwardProp: (name: string) => !['depth', 'showBorder', 'even'].includes(name),
})<{ depth?: number; showBorder?: boolean }>(({ depth = 0, showBorder, theme: { palette } }) => ({
  display: 'table',
  width: '100%',
  ...(depth && {
    borderLeft: '4px solid',
    borderLeftColor: palette.grey[300],
    backgroundColor: alpha(
      palette.mode === 'light' ? palette.common.black : palette.common.white,
      depth * DEPTH_OPACITY_FACTOR,
    ),
  }),
  ...(!showBorder && {
    borderBottom: 'none !important',
  }),
}))

export default ReactTableRow
