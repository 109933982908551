import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ForemanPanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M36 48C43.72 48 50 41.72 50 34C50 26.28 43.72 20 36 20C28.28 20 22 26.28 22 34C22 41.72 28.28 48 36 48ZM36 28C39.32 28 42 30.68 42 34C42 37.32 39.32 40 36 40C32.68 40 30 37.32 30 34C30 30.68 32.68 28 36 28ZM36.2 68H19.08C23.04 66 29.88 64 36 64C36.44 64 36.92 64.04 37.36 64.04C38.72 61.12 41.08 58.72 43.92 56.8C41 56.28 38.24 56 36 56C26.64 56 8 60.68 8 70V76H36V70C36 69.32 36.08 68.64 36.2 68ZM66 58C58.64 58 44 62.04 44 70V76H88V70C88 62.04 73.36 58 66 58ZM70.84 50.72C73.88 49 76 45.76 76 42C76 36.48 71.52 32 66 32C60.48 32 56 36.48 56 42C56 45.76 58.12 49 61.16 50.72C62.6 51.52 64.24 52 66 52C67.76 52 69.4 51.52 70.84 50.72Z' />
    </SvgIcon>
  )
}

export default ForemanPanelIcon
