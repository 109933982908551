import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import { convertTranslation } from 'Helpers/Translations'
import defaultTranslations from 'Translations/default.json'
import { TranslationValuesDto } from 'Types/Translations'

// https://github.com/i18next/react-i18next/issues/1587#issuecomment-1386909661
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const LNG_BUNDLE_NAME = 'selectedLanguage'

i18n.use(initReactI18next).init({
  fallbackLng: 'default',
  lng: LNG_BUNDLE_NAME,
  debug: false,
  resources: {
    default: {
      translation: defaultTranslations,
    },
  },
  react: {
    bindI18nStore: 'added',
  },
  returnNull: false,
})

export const replaceTranslation = (translation: TranslationValuesDto) => {
  const convertedTranslation = convertTranslation(translation.keyValuePairs)

  i18n.removeResourceBundle(LNG_BUNDLE_NAME, 'translation')
  i18n.addResourceBundle(LNG_BUNDLE_NAME, 'translation', convertedTranslation, false, true)
}

export default i18n
