import React from 'react'
import { MoreHoriz as MoreHorizIcon, MoreVert as MoreVertIcon } from '@mui/icons-material'
import { IconButton, styled } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const ITEM_HEIGHT = 2.3125

const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    boxShadow: '0px 4px 8px rgba(22, 22, 21, 0.24)',
    borderRadius: '8px',
    overflow: 'hidden',
    [theme.breakpoints.up('xxl')]: {
      width: 'auto',
      borderRadius: '16px',
      boxShadow: '0px 8px 16px rgba(22, 22, 21, 0.24)',
    },
  },
}))

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  backgroundColor: theme.palette.background.paper,
  justifyContent: 'flex-start',
  height: `${ITEM_HEIGHT}rem`,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
  '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    color: theme.palette.white,
  },
  '&:focus': {
    backgroundColor: theme.palette.background.paper,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.75rem',
    height: `${2 * ITEM_HEIGHT}rem`,
  },
}))

interface ItemProp {
  onClick: () => void
  name: string
}

interface Props {
  items?: ItemProp[]
  variant?: 'horizontal' | 'vertical'
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
}

const MenuButton: React.FC<Props> = ({ items, variant = 'vertical', size = 'medium', disabled = false, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <IconButton onClick={handleClick} disabled={disabled}>
        {variant === 'horizontal' ? <MoreHorizIcon fontSize={size} /> : <MoreVertIcon fontSize={size} />}
      </IconButton>
      <MenuStyled
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items
          ? items.map((item) => (
              <MenuItemStyled
                onClick={() => {
                  item.onClick()
                  setAnchorEl(null)
                }}
                key={item.name}
              >
                {item.name}
              </MenuItemStyled>
            ))
          : React.Children.map(children, (child) =>
              React.cloneElement(child as React.ReactElement, {
                onClick: () => {
                  const element = child as React.ReactElement

                  element?.props.onClick()
                  handleClose()
                },
              }),
            )}
      </MenuStyled>
    </>
  )
}

export default MenuButton
