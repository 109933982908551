import type { ReasonDescriptionDto } from 'Types'

export const getReasonDescription = (reasonDescription?: ReasonDescriptionDto | null, languageCode?: string | null) => {
  if (!reasonDescription) {
    return ''
  }

  const { description: customDescription, translatedDescriptions, defaultLanguageCode } = reasonDescription

  const selectedLanguageDescription = languageCode && translatedDescriptions?.[languageCode]
  const defaultLanguageDescription = defaultLanguageCode && translatedDescriptions?.[defaultLanguageCode]
  const fallbackDescription = Object.values(translatedDescriptions ?? {})[0]

  return customDescription ?? selectedLanguageDescription ?? defaultLanguageDescription ?? fallbackDescription ?? ''
}

export const getHasReasonDescriptionCodes = (reasonDescription?: ReasonDescriptionDto | null) => {
  return Boolean(
    reasonDescription?.translatedDescriptions && Object.keys(reasonDescription?.translatedDescriptions).length,
  )
}
