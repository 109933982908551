import { getI18n } from 'react-i18next'
import z from 'zod'

export const validateDateRange = (
  { startDate, endDate }: { startDate: Date | undefined; endDate: Date | undefined },
  { addIssue }: { addIssue: (arg: z.IssueData) => void },
) => {
  if (endDate) {
    const startDateValidationResult = z
      .date()
      .max(endDate, getI18n().t('messages.startDateCannotBeAfterEndDate'))
      .safeParse(startDate)

    if (!startDateValidationResult.success) {
      startDateValidationResult.error.errors.forEach((error) => {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: error.message,
          path: ['startDate'],
        })
      })
    }
  }

  if (startDate) {
    const endDateValidationResult = z
      .date()
      .min(startDate, getI18n().t('messages.endDateCannotBeBeforeStartDate'))
      .safeParse(endDate)

    if (!endDateValidationResult.success) {
      endDateValidationResult.error.errors.forEach((error) => {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: error.message,
          path: ['endDate'],
        })
      })
    }
  }
}
