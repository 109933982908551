import React, { useMemo } from 'react'
import { Control, Controller, useFormContext } from 'react-hook-form'
import { getI18n, useTranslation } from 'react-i18next'
import { Error as ErrorIcon, Info, WarningOutlined } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'

import ColumnTextFilter from 'Components/ColumnTextFilter'
import EllipsisTextTooltip from 'Components/EllipsisTextTooltip'
import { ReactTableVirtualized } from 'Components/ReactTable'
import { createExpanderColumn } from 'Components/ReactTable/createExpanderColumn'
import OverflowTip from 'Components/StatusLineTable/Components/StatusCell/OverflowTip'
import Tooltip from 'Components/Tooltip'
import { WorkstationState } from 'Consts/WorkstationStates'
import { getReasonDescription } from 'Helpers/Catalogs'
import {
  getColor as getStatusColor,
  getStatusColorContrastText,
  getStatusIconComponent,
  getText as getStatusName,
} from 'Helpers/Status'
import { formatTimeSpan } from 'Helpers/Time'
import { OrderDto, OrderStatusDto } from 'Types'

import OrderStatus from './OrderStatus'
import StatusFilterComponent, { StatusFilterProps } from './StatusFilterComponent'
import { FiltersValues, OrderTableItem } from './types'
import { isOrderDelayed, mapOrdersDtoToOrderTableItems, mapStatusesToStatusFilterOptions } from './utils'

const ColumnIds = {
  EXPANDER: 'expander',
  NAME: 'name',
  WORKSTATIONS: 'workstations',
  STATUS: 'status',
  DETAILS: 'details',
  REALIZATIONS: 'realization',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
}

type Props = {
  data: OrderDto[]
  isLoading: boolean
  errorMessage?: string | null
  statuses: OrderStatusDto[]
  ctaColumnDefinition: ColumnDef<OrderTableItem, unknown>
  defaultExpanded?: boolean
}

const OrdersTable = ({ data, isLoading, errorMessage, statuses, ctaColumnDefinition, defaultExpanded }: Props) => {
  const tableData = mapOrdersDtoToOrderTableItems(data)
  const { t } = useTranslation()
  const form = useFormContext<FiltersValues>()
  const control = form?.control
  const setValue = form?.setValue

  const columns = useMemo(
    () =>
      getColumns({
        control,
        onClearOrderNameClick: () => {
          setValue('orderName', '')
        },
        statusFilterProps: {
          options: mapStatusesToStatusFilterOptions(statuses),
        },
        ctaColumnDefinition,
      }),
    [control, statuses, ctaColumnDefinition, t],
  )

  if (!isLoading && errorMessage) {
    return <Typography>{errorMessage}</Typography>
  }

  return (
    <ReactTableVirtualized
      isLoading={isLoading}
      data={tableData}
      columns={columns}
      enableExpanding
      defaultExpanded={defaultExpanded}
    />
  )
}

const columnHelper = createColumnHelper<OrderTableItem>()

const getColumns = ({
  control,
  onClearOrderNameClick,
  statusFilterProps,
  ctaColumnDefinition,
}: {
  control: Control<FiltersValues, object>
  onClearOrderNameClick: () => void
  statusFilterProps: { options: StatusFilterProps['options'] }
  ctaColumnDefinition: ColumnDef<OrderTableItem, unknown>
}) => [
  createExpanderColumn<OrderTableItem>(),
  columnHelper.accessor('name', {
    id: ColumnIds.NAME,
    header: () => {
      if (!control) return getI18n().t('labels.order')

      return (
        <Controller
          name='orderName'
          control={control}
          render={({ field: { value, onChange } }) => (
            <ColumnTextFilter
              onChange={onChange}
              value={value}
              title={getI18n().t('labels.order')}
              label={getI18n().t('labels.order')}
              onClearClick={onClearOrderNameClick}
            />
          )}
        />
      )
    },
    cell: ({ getValue, row }) => {
      if (row.depth === 0) {
        return (
          <Stack sx={{ maxWidth: '100%' }}>
            <OverflowTip style={{ fontWeight: '700' }}>{getValue()}</OverflowTip>
            <Typography fontSize={12}>{row.original.technologyName}</Typography>
          </Stack>
        )
      }
      if (row.depth === 1) {
        return (
          <Stack sx={{ maxWidth: '100%' }}>
            <Typography fontSize={10} fontWeight='bold' textTransform='uppercase'>
              {getI18n().t('labels.technologyComponent')}
            </Typography>
            <OverflowTip style={{ fontWeight: '700' }}>{getValue()}</OverflowTip>
          </Stack>
        )
      }
      if (row.depth === 2) {
        const { workstationStatus, isCompletionAtRisk, isCompletionImpossible, isDeleted } = row.original

        if (isCompletionImpossible) {
          return (
            <Stack spacing={0.5} direction='row' alignItems='center'>
              <ErrorIcon fontSize='inherit' color='error' sx={{ mb: 0.25 }} />
              <Typography fontWeight='medium' fontSize='inherit' textTransform='uppercase'>
                {getI18n().t(isDeleted ? 'labels.deleted' : 'labels.inactive', { context: 'workstation' })}
              </Typography>
            </Stack>
          )
        }

        if (isCompletionAtRisk && workstationStatus) {
          return <MachineStatusLabel status={workstationStatus} />
        }
      }

      return null
    },
    size: 200,
  }),
  columnHelper.accessor('workstations', {
    id: ColumnIds.WORKSTATIONS,
    header: getI18n().t('labels.workstations').toString(),
    cell: ({ getValue, row }) => {
      const value = getValue()

      if (row.depth === 2) {
        return <EllipsisTextTooltip maxLines={3}>{value}</EllipsisTextTooltip>
      }

      if (row.original.isCompletionImpossible) {
        return (
          <Stack spacing={0.5} direction='row' alignItems='center'>
            <span>{value}</span>
            <Tooltip title={getI18n().t('messages.noActiveWorkstationAssignedToProduceTheComponent')}>
              <ErrorIcon fontSize='inherit' color='error' sx={{ mb: 0.25 }} />
            </Tooltip>
          </Stack>
        )
      }

      if (row.original.isCompletionAtRisk) {
        return (
          <Stack spacing={0.5} direction='row' alignItems='center'>
            <span>{value}</span>
            <WarningOutlined fontSize='inherit' sx={{ mb: 0.25 }} />
          </Stack>
        )
      }

      return value
    },
    size: 150,
  }),
  columnHelper.accessor('status', {
    id: ColumnIds.STATUS,
    header: () => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getI18n().t('labels.status')}
        {control && (
          <Controller
            control={control}
            name='selectedStatusesIds'
            render={({ field: { value: selectedIds, onChange } }) => (
              <StatusFilterComponent
                value={statusFilterProps.options?.filter(({ id }) => selectedIds?.includes(id))}
                options={statusFilterProps.options}
                onChange={(option) => onChange(option?.map(({ id }) => id) || [])}
                menuItemHeight='40px'
              />
            )}
          />
        )}
      </Box>
    ),
    cell: ({ getValue }) => <OrderStatus status={getValue()} />,
    size: 150,
  }),
  columnHelper.display({
    id: ColumnIds.DETAILS,
    header: getI18n().t('labels.details').toString(),
    cell: ({ row }) => {
      const { endDate, finishDate, stopReason, isCurrentRetool, suspendedRetoolReason } = row.original

      if (row.depth === 0 && endDate && isOrderDelayed({ endDate, finishDate })) {
        return getI18n().t('labels.delayed')
      }

      if (row.depth === 2) {
        if (isCurrentRetool && suspendedRetoolReason) {
          return (
            <EllipsisTextTooltip maxLines={3}>
              {`${getI18n().t('labels.suspendedRetool')}: ${getReasonDescription(suspendedRetoolReason)}`}
            </EllipsisTextTooltip>
          )
        }
        if (!isCurrentRetool && stopReason) {
          return (
            <EllipsisTextTooltip maxLines={3}>
              {`${getI18n().t('labels.retoolReason')}: ${stopReason}`}
            </EllipsisTextTooltip>
          )
        }
      }

      return null
    },
    size: 150,
  }),
  columnHelper.accessor('units', {
    id: ColumnIds.REALIZATIONS,
    header: () => (
      <>
        {getI18n().t('labels.realization').toString()}
        <Tooltip
          title={
            <Stack>
              <Typography fontSize='inherit' fontWeight='medium'>
                {getI18n().t('labels.componentsCompletedOverPlanned')}
              </Typography>
              <Typography fontSize='inherit'>{getI18n().t('labels.workingTimeActualOverEstimated')}</Typography>
            </Stack>
          }
        >
          <Info color='action' fontSize='inherit' sx={{ ml: 1 }} />
        </Tooltip>
      </>
    ),
    cell: ({ getValue, row }) => {
      const value = getValue()
      const order = row.original

      return (
        <Stack>
          <Typography fontSize='inherit' fontWeight='medium'>
            {value.produced} / {value.planned}
          </Typography>
          {!!order.predictedTime && (
            <div>
              {formatTimeSpan(order.elapsedTime)} / {formatTimeSpan(order.predictedTime)}
            </div>
          )}
        </Stack>
      )
    },
    size: 200,
  }),
  columnHelper.display({
    id: ColumnIds.START_DATE,
    header: getI18n().t('labels.start').toString(),
    cell: ({ row }) => {
      const { startDate } = row.original

      return startDate ? format(new Date(startDate), 'dd/MM/yyyy') : null
    },
    size: 160,
  }),
  columnHelper.display({
    id: ColumnIds.END_DATE,
    header: getI18n().t('labels.end').toString(),
    cell: ({ row }) => {
      const { endDate } = row.original

      return endDate ? format(new Date(endDate), 'dd/MM/yyyy') : null
    },
    size: 150,
  }),
  ctaColumnDefinition,
]

const MachineStatusLabel = ({ status }: { status: WorkstationState }) => {
  const { t } = useTranslation()
  const StatusIcon = getStatusIconComponent(status)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: getStatusColor(status),
        color: getStatusColorContrastText(status),
        borderRadius: 100,
        py: 0.5,
        px: 1,
      }}
    >
      <StatusIcon sx={{ mr: 1 }} fontSize='inherit' color='inherit' />
      <Typography fontSize={10} fontWeight='bold' textTransform='uppercase' color='inherit'>
        {t(getStatusName(status))}
      </Typography>
    </Box>
  )
}

export default OrdersTable
