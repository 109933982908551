import React, { useEffect, useRef, useState } from 'react'

import Tooltip from 'Components/Tooltip'

interface Props {
  children: React.ReactChild | React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const OverflowTip: React.FC<Props> = ({ children, className, style }) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)

  const customStyles: React.CSSProperties = {
    ...style,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: `ellipsis`,
  }

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth)
    }
  }, [textElementRef])

  return (
    <Tooltip title={children as string} disableHoverListener={!isOverflowed} sx={{ maxWidth: 260 }}>
      <div ref={textElementRef} style={customStyles} className={className}>
        {children}
      </div>
    </Tooltip>
  )
}

export default OverflowTip
