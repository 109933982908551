import { useController, useFormContext } from 'react-hook-form'

import MultiSelect, { type MultiSelectProps } from 'Components/MultiSelect'

import type { FormField } from './types'

type OptionValue = string | number
export type FormMultiSelect<T extends OptionValue> = FormField & Omit<MultiSelectProps<T>, 'value'>

export const FormMultiSelect = <T extends OptionValue>(props: FormMultiSelect<T>) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ name: props.name, control })

  return (
    <MultiSelect
      {...props}
      value={value}
      onChange={(value) => {
        onChange(value)
        props.onChange?.(value)
      }}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
    />
  )
}
