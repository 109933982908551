import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const TechnologistPanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M64 32H32V64H64V32ZM53.3333 53.3333H42.6667V42.6667H53.3333V53.3333ZM96 42.6667V32H85.3333V21.3333C85.3333 15.4667 80.5333 10.6667 74.6667 10.6667H64V0H53.3333V10.6667H42.6667V0H32V10.6667H21.3333C15.4667 10.6667 10.6667 15.4667 10.6667 21.3333V32H0V42.6667H10.6667V53.3333H0V64H10.6667V74.6667C10.6667 80.5333 15.4667 85.3333 21.3333 85.3333H32V96H42.6667V85.3333H53.3333V96H64V85.3333H74.6667C80.5333 85.3333 85.3333 80.5333 85.3333 74.6667V64H96V53.3333H85.3333V42.6667H96ZM74.6667 74.6667H21.3333V21.3333H74.6667V74.6667Z' />
    </SvgIcon>
  )
}

export default TechnologistPanelIcon
