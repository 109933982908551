import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfDay, isValid, startOfDay } from 'date-fns'

import HubMethods from 'Consts/HubMethods'
import { useSignalRContext } from 'Context/SignalRContext'
import { useOrdersQuery } from 'Hooks/orders/useOrdersQuery'
import { queryClient } from 'Lib/react-query'
import { OrderDeletedMessage } from 'Types/HubMessages'
import { OrderDto, OrderStatusDto } from 'Types/Orders'

import { FiltersValues } from './types'

export const useOrdersTableData = ({
  filters,
  filtersLoading,
  statuses,
}: {
  filters: FiltersValues
  filtersLoading: boolean
  statuses: OrderStatusDto[] | undefined
}) => {
  const { dateRange, selectedStatusesIds, orderName } = filters
  const from = dateRange?.from ? startOfDay(dateRange?.from).toISOString() : new Date().toISOString()
  const to = dateRange?.to ? endOfDay(dateRange?.to).toISOString() : new Date().toISOString()
  const isOrdersQueryEnabled = isValid(dateRange?.from) && isValid(dateRange?.to) && !filtersLoading
  const {
    data: orders,
    isLoading: isLoadingOrders,
    error,
    refetch,
  } = useOrdersQuery(
    {
      from,
      to,
    },
    {
      enabled: isOrdersQueryEnabled,
    },
  )
  const { hubConnection } = useSignalRContext()
  const { t } = useTranslation()

  const ordersQueryKey = useMemo(() => ['orders', from, to], [from, to])

  useEffect(() => {
    const handleOrderCreated = () => {
      refetch()
    }

    const handleOrderDeleted = ({ orderId }: OrderDeletedMessage) => {
      queryClient.setQueryData<OrderDto[] | undefined>(ordersQueryKey, (prevOrders) => {
        if (prevOrders) {
          return prevOrders.filter((item) => item.id !== orderId)
        }

        return prevOrders
      })
    }

    const handleOrderUpdated = (order: OrderDto) => {
      queryClient.setQueryData<OrderDto[] | undefined>(ordersQueryKey, (prevOrders) => {
        if (prevOrders) {
          return prevOrders.map((item) => {
            if (item.id === order.id) {
              return order
            }

            return item
          })
        }

        return prevOrders
      })
    }

    hubConnection?.on(HubMethods.OrderCreated, handleOrderCreated)
    hubConnection?.on(HubMethods.OrderUpdated, handleOrderUpdated)
    hubConnection?.on(HubMethods.OrderDeleted, handleOrderDeleted)

    return () => {
      hubConnection?.off(HubMethods.OrderCreated, handleOrderCreated)
      hubConnection?.off(HubMethods.OrderUpdated, handleOrderUpdated)
      hubConnection?.off(HubMethods.OrderDeleted, handleOrderDeleted)
    }
  }, [hubConnection, ordersQueryKey])

  const getErrorMessage = () => {
    if (!isLoadingOrders && error) {
      return t('messages.sorryThereWasAProblemWithYourRequest')
    }

    return undefined
  }

  const selectedStatusesNames = statuses?.filter(({ id }) => selectedStatusesIds?.includes(id)).map((s) => s.name) ?? []
  const filteredOrders =
    orders
      ?.filter((order) => order.name.toLowerCase().includes(orderName?.toLowerCase() ?? ''))
      .filter((o) => selectedStatusesNames.includes(o.status)) ?? []

  return {
    data: filteredOrders,
    isLoading: isLoadingOrders,
    errorMessage: getErrorMessage(),
  }
}
