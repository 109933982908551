import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { InfoOutlined as InfoOutlinedIcon, Refresh as RefreshIcon } from '@mui/icons-material'
import { Button, Link, Stack, Typography } from '@mui/material'
import Config from 'config'

import CenteredContentWithLogo from 'Layout/CenteredContentWithLogo'
import PaperCard, { PaperCardContent, PaperCardHeader } from 'Layout/PaperCard'
import paths from 'Routes/paths'

type Props = {
  eventId?: string
}

const FullSizeErrorFallback = ({ eventId }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const showContactSupport = !!Config.SUPPORT_EMAIL

  function handleBackClick() {
    history.push(paths.root)
    window.location.reload()
  }

  return (
    <CenteredContentWithLogo>
      <PaperCard>
        <InfoOutlinedIcon color='error' sx={{ fontSize: 48 }} />
        <PaperCardHeader>
          <Typography variant='h5' align='center' fontWeight='medium' gutterBottom>
            {t('messages.appHasCrashed')}
          </Typography>
          <Typography align='center'>
            <span>{t('messages.theAppHasEncounteredAnIssueAndHasCrashed')}</span>{' '}
            <span>{t('messages.thisBugHasBeenReportedToOurDevelopmentTeam')}</span>
            {!!showContactSupport && (
              <span>
                {' '}
                {t('messages.inCaseOfAnyQuestionsFeelFreeToContactUsVia')} <SupportEmailLink eventId={eventId} />
              </span>
            )}
          </Typography>
        </PaperCardHeader>
        <PaperCardContent>
          <Stack>
            <Typography align='center'>{t('messages.refreshTheAppToTryAgain')}</Typography>
            <Button variant='text' onClick={handleBackClick} sx={{ mt: 2 }}>
              <RefreshIcon sx={{ mr: 1 }} />
              {t('labels.refreshTheApp')}
            </Button>
          </Stack>
        </PaperCardContent>
      </PaperCard>
    </CenteredContentWithLogo>
  )
}

const SupportEmailLink = ({ eventId }: { eventId?: string }) => {
  const email = Config.SUPPORT_EMAIL
  const subjectPrefix = eventId ? `[EventId: ${eventId}] ` : ''
  const subject = `${subjectPrefix}Bug report: `
  const href = `mailto:${email}?subject=${encodeURIComponent(subject)}`

  return <Link href={href}>{email}</Link>
}

export default FullSizeErrorFallback
