import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const PlannerPanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width='96' height='96' viewBox='0 0 96 96'>
      <path d='M74.6667 69.3333L53.3333 48V31.04C59.52 28.8 64 22.9333 64 16C64 7.14667 56.8533 0 48 0C39.1467 0 32 7.14667 32 16C32 22.9333 36.48 28.8 42.6667 31.04V48L21.3333 69.3333H0V96H26.6667V79.7333L48 57.3333L69.3333 79.7333V96H96V69.3333H74.6667Z' />
    </SvgIcon>
  )
}

export default PlannerPanelIcon
