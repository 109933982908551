import Config from 'config'
import mixpanel from 'mixpanel-browser'

import { EventNames, EventProperties, GroupNames } from './const'

let isAnalyticsEnabled = false

const addInitialGroups = () => {
  if (!isAnalyticsEnabled) return

  mixpanel.add_group(GroupNames.ENVIRONMENT, window.location.host)
  mixpanel.add_group(GroupNames.APP_VERSION, Config.APP_VERSION)
  mixpanel.add_group(GroupNames.RELEASE_TAG, Config.RELEASE_TAG)
}

const init = () => {
  if (process.env.NODE_ENV !== 'development' && Config.MIXPANEL_PROJECT_TOKEN && Config.MIXPANEL_API_HOST) {
    mixpanel.init(Config.MIXPANEL_PROJECT_TOKEN, {
      debug: false,
      api_host: Config.MIXPANEL_API_HOST,
    })
    isAnalyticsEnabled = true
    addInitialGroups()
  }
}

const identify = (uniqueId?: string) => {
  if (!isAnalyticsEnabled) return

  mixpanel.identify(uniqueId)
}

const reset = () => {
  if (!isAnalyticsEnabled) return

  mixpanel.reset()
  addInitialGroups()
}

const Analytics = {
  init,
  identify,
  reset,
  track: {
    signIn: ({ loginMethod }: { loginMethod?: string }) => {
      if (!isAnalyticsEnabled) return

      mixpanel.track(EventNames.SIGNED_IN, { [EventProperties.LOGIN_METHOD]: loginMethod })
      mixpanel.time_event(EventNames.SIGNED_OFF)
    },
    signOut: ({ loginMethod }: { loginMethod?: string }) => {
      if (!isAnalyticsEnabled) return

      mixpanel.track(EventNames.SIGNED_OFF, { [EventProperties.LOGIN_METHOD]: loginMethod })
    },
    panelOpened: (properties: { selectedApp: string }) => {
      if (!isAnalyticsEnabled) return

      mixpanel.track(EventNames.PANEL_OPENED, { [EventProperties.PANEL_NAME]: properties.selectedApp })
      mixpanel.time_event(EventNames.PANEL_CLOSED)
    },
    panelClosed: (properties: { selectedApp: string }) => {
      if (!isAnalyticsEnabled) return

      mixpanel.track(EventNames.PANEL_CLOSED, { [EventProperties.PANEL_NAME]: properties.selectedApp })
    },
    pageVisited: (properties: { name: string; selectedApp?: string }) => {
      if (!isAnalyticsEnabled) return

      mixpanel.track(EventNames.PAGE_VISITED, {
        [EventProperties.PAGE_NAME]: properties.name,
        [EventProperties.PANEL_NAME]: properties.selectedApp,
      })
      mixpanel.time_event(EventNames.PAGE_LEFT)
    },
    pageLeft: (properties: { name: string; selectedApp?: string }) => {
      if (!isAnalyticsEnabled) return

      mixpanel.track(EventNames.PAGE_LEFT, {
        [EventProperties.PAGE_NAME]: properties.name,
        [EventProperties.PANEL_NAME]: properties.selectedApp,
      })
    },
  },
}

export default Analytics
