import React from 'react'
import { CircularProgress, styled } from '@mui/material'

import Typography from 'Components/Typography'

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
})

type Props = {
  isLoading: boolean
  showValidationError: boolean
  validationErrorText: string | null
  children: React.ReactNode
}

const ChartContent: React.FC<Props> = ({ isLoading, showValidationError, validationErrorText, children }) => {
  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    )
  }

  if (showValidationError && validationErrorText) {
    return (
      <Container>
        <Typography>{validationErrorText}</Typography>
      </Container>
    )
  }

  return <>{children}</>
}

export default ChartContent
