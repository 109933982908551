import { ErrorBoundary as SentryErrorBoundary, ErrorBoundaryProps as SentryErrorBoundaryProps } from '@sentry/react'

import FullSizeErrorFallback from './FullSizeErrorFallback'

type Props = {
  children: React.ReactNode
  fallback?: SentryErrorBoundaryProps['fallback']
}

const ErrorBoundary = ({
  children,
  fallback = ({ eventId }) => <FullSizeErrorFallback eventId={eventId} />,
}: Props) => {
  return <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
}

export default ErrorBoundary
