import { useMutation } from 'react-query'

import { apiClient } from 'Api/ApiClient'
import { queryClient } from 'Lib/react-query'
import { PutOrderDto } from 'Types'

export const useEditOrder = () =>
  useMutation(
    async (data: PutOrderDto) => {
      await apiClient.putOrder(data.id, data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['orders'])
      },
    },
  )
