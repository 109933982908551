import { useQuery } from 'react-query'

import { apiClient } from 'Api/ApiClient'

const useFilesToAcknowledge = (machineId: string, options?: { enabled: boolean }) =>
  useQuery(
    ['files-to-acknowledge', machineId],
    async ({ signal }) => {
      const { data } = await apiClient.getFilesToAcknowledge(machineId, { signal })

      return data
    },
    {
      ...options,
    },
  )

export default useFilesToAcknowledge
