import { getI18n } from 'react-i18next'

import { ErrorCodesTranslationKeys } from 'Consts/Errors'
import { AnyType, ApiBaseErrorType, ApiCodedErrorType, ValidationError } from 'Types'

const isError = (error: AnyType) => {
  if ((error as ApiBaseErrorType)?.ErrorId) {
    return true
  }

  return false
}

const isValidationError = (error: AnyType) => {
  if (!isError(error)) {
    return false
  }

  if (!(error as ValidationError).Errors) {
    return false
  }

  return true
}

export const getErrorCode = (error: AnyType): string => {
  const errorData = error?.response?.data

  if (!isError(errorData)) {
    return ''
  }

  if (isValidationError(errorData)) {
    return (errorData as ValidationError).Errors[0]?.Code
  }

  return errorData.Code
}

export const getErrorMessage = (error: AnyType): string => {
  const errorData = error?.response?.data

  if (!isError(errorData)) {
    return ''
  }

  if (isValidationError(errorData)) {
    return (errorData as ValidationError).Errors[0]?.Message
  }

  return errorData.Message
}

export const getErrorType = (error: AnyType): string => {
  const errorData = error?.response?.data

  if (!isError(errorData)) {
    return ''
  }

  if (isValidationError(errorData)) {
    return (errorData as ValidationError).Errors[0]?.Type
  }

  return errorData.Type
}

export const getValidationErrors = (error: AnyType): ApiCodedErrorType[] | null => {
  const errorData = error?.response?.data

  if (isValidationError(errorData)) {
    return (errorData as ValidationError).Errors
  }

  return null
}

export const getErrorMessageForCode = (errorCode?: string | null) => {
  if (!errorCode) {
    return getI18n().t('messages.sorryThereWasAProblemWithYourRequest')
  }

  return getI18n().t(ErrorCodesTranslationKeys[errorCode]) ?? errorCode
}

export default getErrorCode
