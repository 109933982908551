import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Done from '@mui/icons-material/Done'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { Box, Dialog, Typography } from '@mui/material'

import AuthManager, { AuthManagerErrors } from 'Auth/AuthManager'
import { DialogTitleWithIcon } from 'Components/Dialog'
import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog/Dialog'
import HubMethods from 'Consts/HubMethods'
import { LocalStorageKeys } from 'Consts/LocalStorageKeys'
import { useMachineContext } from 'Context/MachineContext'
import { useSignalRContext } from 'Context/SignalRContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'
import type { RfidCardAppliedMessage } from 'Types'

interface Props {
  open: boolean
  close: () => void
}

const CardRemoved: React.FC<Props> = ({ open, close }) => {
  const history = useHistory()
  const { hubConnection, connectedClientId } = useSignalRContext()
  const [time, setTime] = useState(20)
  const [cardInserted, setCardInserted] = useState(false)
  const [flag, setFlag] = useState(false)
  const { machine } = useMachineContext()
  const machineId = machine?.id
  const { user } = useUserContext()
  const { t } = useTranslation()

  useEffect(() => {
    setTime(20)
  }, [open])

  useEffect(() => {
    const handleRfidCardApplied = ({ isValid, rfid, workstationId }: RfidCardAppliedMessage) => {
      if (!machineId || !connectedClientId || !user) {
        return
      }

      if (isValid && user.rfid === rfid && machineId === workstationId) {
        if (open) {
          AuthManager.refreshToken()
            .then(() => {
              setTime(3)
              setCardInserted(true)
            })
            .catch((error) => {
              if (error?.message !== AuthManagerErrors.REQUEST_IN_PROGRESS) {
                setCardInserted(false)
              }
            })
        } else {
          setCardInserted(false)
          setTime(20)
        }
      }
    }

    hubConnection?.on(HubMethods.RfidCardApplied, handleRfidCardApplied)

    return () => {
      hubConnection?.off(HubMethods.RfidCardApplied, handleRfidCardApplied)
    }
  }, [hubConnection, machineId, connectedClientId, user, open])

  useEffect(() => {
    const interval = setInterval(() => {
      setFlag((prev) => !prev)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (time <= 0) {
      if (!cardInserted) {
        AuthManager.logout().then(() => {
          localStorage.removeItem(LocalStorageKeys.REDIRECT_PATH)
          history.push(paths.rfidLogin)
        })
      }
      close()
    } else {
      setTime((prev) => prev - 1)
    }
  }, [flag])

  return (
    <Dialog open={open}>
      {!cardInserted ? (
        <>
          <DialogTitle disableTypography>
            <Box position='absolute' top='8px' right='8px'>
              <Typography variant='h5'>{time}s</Typography>
            </Box>
            <DialogTitleWithIcon Icon={ErrorOutline} IconProps={{ color: 'error', sx: { fontSize: 65 } }}>
              <Typography component='span' fontWeight='inherit' fontSize='inherit' color='error'>
                {t('messages.rfidRemoved')}!
              </Typography>
            </DialogTitleWithIcon>
          </DialogTitle>
          <DialogContent>
            <Typography fontWeight='bold' variant='h5'>
              {t('messages.insertRfid')},
            </Typography>
            <Typography variant='h5'>{t('messages.toContinueOrYouWillBeRedirected')}</Typography>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle disableTypography>
            <DialogTitleWithIcon Icon={Done} IconProps={{ color: 'success', sx: { fontSize: 65 } }}>
              {t('messages.okThankYou')}
            </DialogTitleWithIcon>
          </DialogTitle>
        </>
      )}
      <DialogActions />
    </Dialog>
  )
}

export default CardRemoved
