import React from 'react'

import { AccidentsContextProvider } from './AccidentsContext'
import { DialogsProvider } from './DialogsContext'
import { MachineContextProvider } from './MachineContext'
import { MechanicContextProvider } from './MechanicContext'
import { NavigationContextProvider } from './NavigationContext'
import { NotificationContextProvider } from './NotificationContext'
import { ProductionProvider } from './ProductionContext'
import { ReportsGeneratingContextProvider } from './ReportsGeneratingContext'
import { ShiftsContextProvider } from './ShiftsContext/ShiftsContext'
import { SnackbarsProvider } from './SnackbarsContext'
import { UserProvider } from './UserContext'

const AppContext: React.FC = ({ children }) => (
  <UserProvider>
    <ShiftsContextProvider>
      <MachineContextProvider>
        <NotificationContextProvider>
          <ProductionProvider>
            <AccidentsContextProvider>
              <DialogsProvider>
                  <SnackbarsProvider>
                    <NavigationContextProvider>
                      <MechanicContextProvider>
                        <ReportsGeneratingContextProvider>{children}</ReportsGeneratingContextProvider>
                      </MechanicContextProvider>
                    </NavigationContextProvider>
                  </SnackbarsProvider>
              </DialogsProvider>
            </AccidentsContextProvider>
          </ProductionProvider>
        </NotificationContextProvider>
      </MachineContextProvider>
    </ShiftsContextProvider>
  </UserProvider>
)

export default AppContext
