import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { nanoid } from 'nanoid'

import {
  FieldArrayAddItemButton,
  FieldArrayHeader,
  FieldArrayItem,
  FieldArraySection,
  FormManualNumericControl,
  FormMultiSelectVirtualized,
  FormTextField,
} from 'Components/form-components'
import SelectVirtualized from 'Components/SelectVirtualized'

import { TechnologyFormValues } from './validation'

type VariantWithMachines = {
  id: string
  name: string
  machines: { id: string; name: string; isActive: boolean; isDeleted: boolean }[]
  isActive: boolean
  isDeleted: boolean
}

type Props = {
  variantsWithMachines: VariantWithMachines[]
  containerRef?: React.RefObject<HTMLDivElement | null>
}

const TechnologyForm = ({ variantsWithMachines, containerRef }: Props) => {
  const { t } = useTranslation()
  const [triggerScrollBottom, setTriggerScrollBottom] = useState(false)

  const { control, trigger } = useFormContext<TechnologyFormValues>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'components',
    keyName: 'tempId',
  })

  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef?.current) {
        containerRef?.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' })
        setTriggerScrollBottom(false)
      }
    }

    if (triggerScrollBottom) {
      scrollToBottom()
    }
  }, [triggerScrollBottom])

  const selectedVariantIds = fields.map(({ variantId }) => variantId)

  return (
    <Stack spacing={3}>
      <FormTextField name='name' label={t('labels.name')} fullWidth />
      <FieldArraySection>
        <FieldArrayHeader
          title={t('labels.technologyComponents')}
          subtitle={t(
            'messages.defineTheComponentsSpecificToThisTechnologyTheirNumberAndSelectTheWorkstationsResponsibleByDefaultForTheProductionOfAGivenComponent',
          )}
        />
        {fields.map((item, index) => {
          const variantsWithMachinesOptions = variantsWithMachines.filter(
            ({ id }) => !selectedVariantIds.includes(id) || id === item.variantId,
          )

          return (
            <TechnologyComponent
              key={item.id ?? item.tempId}
              index={index}
              component={item}
              variantsWithMachines={variantsWithMachinesOptions}
              showRemoveButton={fields.length > 1}
              onRemoveClick={() => {
                if (fields.length > 1) {
                  remove(index)
                  trigger('components')
                }
              }}
              defaultExpanded={!item.id}
            />
          )
        })}
        <FieldArrayAddItemButton
          onClick={() => {
            append({ variantId: '', amount: 1, machines: [], tempId: nanoid() })
            setTriggerScrollBottom(true)
          }}
        >
          {t('labels.addComponent')}
        </FieldArrayAddItemButton>
      </FieldArraySection>
    </Stack>
  )
}

type TechnologyComponentProps = {
  index: number
  variantsWithMachines: VariantWithMachines[]
  component: TechnologyFormValues['components'][0]
  onRemoveClick?: () => void
  defaultExpanded?: boolean
  showRemoveButton?: boolean
}

const TechnologyComponent = ({
  index,
  variantsWithMachines,
  component,
  onRemoveClick,
  defaultExpanded = false,
  showRemoveButton = true,
}: TechnologyComponentProps) => {
  const { t } = useTranslation()
  const { control, trigger, watch, setValue, clearErrors } = useFormContext<TechnologyFormValues>()
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)

  const varinatWithMachinesOptions = variantsWithMachines.map(({ id, name, isActive, isDeleted }) => {
    if (isDeleted) return { id, name: `${name} (${t('labels.deleted', { context: 'component' })})` }

    if (!isActive) return { id, name: `${name} (${t('labels.inactive', { context: 'component' })})` }

    return { id, name }
  })

  const variantId = watch(`components.${index}.variantId`)
  const variantMachines = variantsWithMachines.find((v) => v.id === variantId)?.machines ?? []
  const componentMachines = watch(`components.${index}.machines`)
  const componentMachineIds = componentMachines.map(({ id }) => id)
  const componentMachinesOptions = variantMachines
    .filter(({ id, isDeleted }) => componentMachineIds.includes(id) || !isDeleted)
    .map(({ id, name, isActive, isDeleted }) => {
      if (isDeleted) return { id, name: `${name} (${t('labels.deleted', { context: 'workstation' })})` }

      if (!isActive) return { id, name: `${name} (${t('labels.inactive', { context: 'workstation' })})` }

      return { id, name }
    })
  const selectedMachineNames = componentMachinesOptions
    .filter(({ id }) => componentMachineIds.includes(id))
    .map(({ name }) => name)

  return (
    <Stack>
      <FieldArrayItem onRemove={onRemoveClick} disableRemove={!showRemoveButton} showRemoveButton={showRemoveButton}>
        <Box
          sx={{
            height: '100%',
            paddingTop: 1,
          }}
        >
          <IconButton onClick={() => setIsExpanded((prev) => !prev)}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Stack spacing={4} flexGrow={1}>
          <Stack direction='row' spacing={2}>
            <Controller
              name={`components.${index}.variantId`}
              control={control}
              defaultValue={component.variantId}
              render={({ field, fieldState }) => (
                <SelectVirtualized
                  {...field}
                  fullWidth
                  options={varinatWithMachinesOptions}
                  label={t('labels.component')}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  onChange={(value) => {
                    field.onChange({ target: { value: value ?? '', name: field.name } })
                    setValue(`components.${index}.machines`, [])
                    trigger(`components`).then(() => {
                      clearErrors(`components.${index}.machines`)
                    })
                  }}
                />
              )}
            />
            <Box>
              <FormManualNumericControl
                name={`components.${index}.amount`}
                minNumber={1}
                maxNumber={31556926}
                height='52px'
              />
            </Box>
          </Stack>
          {isExpanded && (
            <FormMultiSelectVirtualized
              name={`components.${index}.machines`}
              options={componentMachinesOptions}
              label={t('labels.workstations')}
              disabled={!variantId}
              helperText={t('messages.thisInformationWillBeUsedToEstimateTheOrderProcessingTime')}
            />
          )}
        </Stack>
      </FieldArrayItem>
      {!isExpanded && (
        <>
          {selectedMachineNames.length ? (
            <Typography variant='caption'>{selectedMachineNames.join(', ')}</Typography>
          ) : (
            <Typography variant='caption' color='error'>
              {t('messages.noSelectedWorkstations')} {t('messages.selectAtLeastX', { min: 1 })}
            </Typography>
          )}
        </>
      )}
    </Stack>
  )
}

export default TechnologyForm
