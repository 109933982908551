import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const PlanIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 18' {...props}>
      <path d='M23.25 15H3V0.75C3 0.551088 2.92098 0.360322 2.78033 0.21967C2.63968 0.0790175 2.44891 0 2.25 0H0.75C0.551088 0 0.360322 0.0790175 0.21967 0.21967C0.0790176 0.360322 0 0.551088 0 0.75L0 16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H23.25C23.4489 18 23.6397 17.921 23.7803 17.7803C23.921 17.6397 24 17.4489 24 17.25V15.75C24 15.5511 23.921 15.3603 23.7803 15.2197C23.6397 15.079 23.4489 15 23.25 15ZM21.75 1.5H16.216C15.9932 1.4992 15.7752 1.56457 15.5896 1.68782C15.404 1.81107 15.2592 1.98665 15.1735 2.1923C15.0878 2.39795 15.0651 2.62441 15.1083 2.84298C15.1515 3.06154 15.2586 3.26237 15.416 3.42L16.935 4.939L13.5 8.379L10.061 4.94C9.77971 4.65879 9.39825 4.50082 9.0005 4.50082C8.60275 4.50082 8.22129 4.65879 7.94 4.94L4.72 8.16C4.6503 8.22965 4.59502 8.31235 4.5573 8.40338C4.51958 8.4944 4.50016 8.59197 4.50016 8.6905C4.50016 8.78903 4.51958 8.8866 4.5573 8.97762C4.59502 9.06865 4.6503 9.15135 4.72 9.221L5.78 10.281C5.84965 10.3507 5.93235 10.406 6.02338 10.4437C6.1144 10.4814 6.21197 10.5008 6.3105 10.5008C6.40903 10.5008 6.5066 10.4814 6.59762 10.4437C6.68865 10.406 6.77135 10.3507 6.841 10.281L9 8.121L12.439 11.56C12.7203 11.8412 13.1018 11.9992 13.4995 11.9992C13.8972 11.9992 14.2787 11.8412 14.56 11.56L19.06 7.06L20.579 8.579C20.7366 8.73644 20.9375 8.84353 21.156 8.8867C21.3746 8.92986 21.601 8.90717 21.8067 8.82148C22.0124 8.73579 22.1879 8.59097 22.3112 8.40538C22.4344 8.21979 22.4998 8.00179 22.499 7.779V2.25C22.4991 2.15156 22.4799 2.05405 22.4423 1.96306C22.4047 1.87207 22.3495 1.78939 22.28 1.71973C22.2104 1.65007 22.1278 1.59481 22.0369 1.55711C21.9459 1.51941 21.8484 1.5 21.75 1.5Z' />
    </SvgIcon>
  )
}

export default PlanIcon
