import React from 'react'
import { useTranslation } from 'react-i18next'
import { Error as ErrorIcon } from '@mui/icons-material'
import { Box, Snackbar, Typography } from '@mui/material'

interface Props {
  open: boolean
}

const ServerDisconnected: React.FC<Props> = ({ open }) => {
  const { t } = useTranslation()

  return (
    <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <ErrorIcon color='error' sx={{ mr: 1 }} />
        <Typography variant='body2' fontWeight='bold'>
          {t('messages.serverConnectionLost')}
        </Typography>
      </Box>
    </Snackbar>
  )
}

export default ServerDisconnected
