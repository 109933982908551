import React, { useMemo, useState } from 'react'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, CircularProgress, Dialog, Stack, Typography } from '@mui/material'
import { startOfDay } from 'date-fns'
import { getIncompleteTechnologyNameWithRevision, getTechnologyNameWithRevision } from 'Features/technologies'

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog'
import { ErrorCodes } from 'Consts/Errors'
import getErrorCode, { getErrorMessageForCode, getValidationErrors } from 'Helpers/ApiErrorCodeGetter'
import { useAddOrder } from 'Hooks/orders/useAddOrder'
import { useAllTechnologiesList } from 'Hooks/technologies/useTechnologiesOptions'
import useTechnology from 'Hooks/technologies/useTechnology'

import OrderForm, { TechnologyPreview } from './OrderForm'
import { getOrderFormSchema, type OrderFormValues } from './OrderForm/validation'
import { mapOrderFormValuesToPostDto } from './utils'

type Props = {
  open: boolean
  onCancel: () => void
  onSuccess: () => void
}

const defaultValues: Partial<OrderFormValues> = {
  name: '',
  amount: 1,
  startDate: startOfDay(new Date()),
  technologyId: '',
}

const AddDialog = ({ open, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation()
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>()
  const { data: technologies, isLoading: isLoadingTechnologiesOptions } = useAllTechnologiesList()
  const technologyOptions = technologies?.map(({ id, name, revision, isComplete }) => ({
    id,
    name: isComplete
      ? getTechnologyNameWithRevision(name, revision)
      : getIncompleteTechnologyNameWithRevision(name, revision),
  }))
  const incompleteTechnologyIds = useMemo(
    () => technologies?.filter(({ isComplete }) => !isComplete).map(({ id }) => id) ?? [],
    [technologies],
  )
  const orderFormSchema = useMemo(
    () => getOrderFormSchema({ minAmount: 1, incompleteTechnologyIds }),
    [incompleteTechnologyIds],
  )
  const methods = useForm<OrderFormValues>({
    mode: 'all',
    resolver: zodResolver(orderFormSchema),
    defaultValues,
  })
  const {
    handleSubmit,
    setError: setFormError,
    formState: { isValid },
    watch,
  } = methods
  const { mutateAsync: submitOrder, isLoading: isSubmitting } = useAddOrder()
  const queryClient = useQueryClient()
  const technologyId = watch('technologyId')
  const isTechnologyComplete = technologies?.find(({ id }) => id === technologyId)?.isComplete

  const handleSubmitError = (error: unknown) => {
    const validationErrors = getValidationErrors(error)

    validationErrors?.forEach(({ PropertyName, Code }) => {
      if (Code === ErrorCodes.NOT_UNIQUE) queryClient.invalidateQueries('orders')
      if (PropertyName) {
        setFormError(PropertyName as FieldPath<OrderFormValues>, {
          message: getErrorMessageForCode(Code),
          type: 'onChange',
        })
      } else {
        setSubmitErrorMessage(getErrorMessageForCode(Code))
      }
    })

    if (!validationErrors?.length) {
      setSubmitErrorMessage(getErrorMessageForCode(getErrorCode(error)))
    }
  }

  const handleSubmitCallback = async (values: OrderFormValues) => {
    setSubmitErrorMessage(null)
    const mappedValues = mapOrderFormValuesToPostDto(values)
    await submitOrder(mappedValues, {
      onSuccess,
      onError: (error) => handleSubmitError(error),
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitCallback)}>
        <Dialog
          open={open}
          onClose={onCancel}
          PaperProps={{ sx: { minWidth: 'min(60rem, 90vw)', height: 'min(45rem, 85vh)' } }}
          disablePortal
        >
          <DialogTitle onClose={onCancel}>{t('labels.addOrder')}</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <OrderForm
                disableTechnology={isLoadingTechnologiesOptions}
                technologyOptions={technologyOptions ?? []}
                isTechnologyComplete={isTechnologyComplete}
              />
              {!!technologyId && <TechnologyComponents technologyId={technologyId} />}
            </Stack>
          </DialogContent>
          <DialogActions actionsVariant='center' errorMessage={submitErrorMessage}>
            <Button onClick={onCancel} variant='text'>
              {t('labels.cancel')}
            </Button>
            <Button type='submit' disabled={!isValid || isSubmitting}>
              {t('labels.add')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  )
}

const TechnologyComponents = ({ technologyId }: { technologyId: string }) => {
  const { data, isLoading, isError } = useTechnology(technologyId)
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Stack justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Stack>
    )
  }

  if (isError)
    return (
      <Typography color='error' variant='caption' textAlign='center'>
        {t('messages.sorryThereWasAProblemWithYourRequest')}
      </Typography>
    )

  if (!data) return null

  return (
    <TechnologyPreview
      components={data.components.map(({ amount, name, technologyComponentMachines }) => ({
        amount,
        name,
        machines: technologyComponentMachines,
      }))}
    />
  )
}

export default AddDialog
