import React from 'react'

import AppNames from 'Consts/AppNames'
import { useUser as useUserContext } from 'Context/UserContext'

import AdminPanelLinks from './AdminPanelLinks'
import ForemanDashboardLinks from './ForemanDashboardLinks'
import ForemanPanelLinks from './ForemanPanelLinks'
import MaintenanceDashboardLinks from './MaintenanceDashboardLinks'
import MechanicPanelLinks from './MechanicPanelLinks'
import OperatorPanelLinks from './OperatorPanelLinks'
import PlannerPanelLinks from './PlannerPanelLinks'
import TechnologistPanelLinks from './TechnologistPanelLinks'

type Props = {
  collapsed: boolean
}

const AppLinks = ({ collapsed }: Props) => {
  const { selectedApp } = useUserContext()

  switch (selectedApp) {
    case AppNames.AdminPanel:
      return <AdminPanelLinks collapsed={collapsed} />
    case AppNames.OperatorPanel:
      return <OperatorPanelLinks collapsed={collapsed} />
    case AppNames.ForemanPanel:
      return <ForemanPanelLinks collapsed={collapsed} />
    case AppNames.ForemanDashboard:
      return <ForemanDashboardLinks collapsed={collapsed} />
    case AppNames.MechanicPanel:
      return <MechanicPanelLinks collapsed={collapsed} />
    case AppNames.MaintenanceDashboard:
      return <MaintenanceDashboardLinks collapsed={collapsed} />
    case AppNames.PlannerPanel:
      return <PlannerPanelLinks collapsed={collapsed} />
    case AppNames.TechnologistPanel:
      return <TechnologistPanelLinks collapsed={collapsed} />
    default:
      return null
  }
}

export default AppLinks
