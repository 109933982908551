import Axios, { AxiosInstance } from 'axios'
import { nanoid } from 'nanoid'
import qs from 'qs'

import { LocalStorageKeys } from 'Consts/LocalStorageKeys'
import { getErrorCode, getErrorMessage } from 'Helpers/ApiErrorCodeGetter'
import ErrorTracker from 'Lib/error-tracker'
import { AnyType } from 'Types'

import Config from '../config'

export const { isCancel } = Axios

const handleError = (error: AnyType) => {
  const workstation = localStorage.getItem('selectedMachine')
  const clientId = localStorage.getItem(LocalStorageKeys.CLIENT_ID)

  if (isCancel(error)) {
    // The request was canceled by user, rethrow to handle cancelation in component
    throw error
  } else if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    const errorMessage = getErrorMessage(error)
    const errorCode = getErrorCode(error)
    const statusCode = error.response.status

    ErrorTracker.captureMessage(errorMessage, {
      tags: {
        type: 'Api Error',
        correlationId: error.config.headers[ApiHeaders.X_CORRELATION_ID],
        'response.status': statusCode,
      },
      extra: {
        errorMessage,
        errorCode,
        errorId: error.response.data?.ErrorId,
        method: error.config.method,
        params: error.config.params,
        url: error.config.url,
        statusCode,
        clientId,
        workstation,
      },
    })
    throw error
  } else if (error.request) {
    // The request was made but no response was received
    ErrorTracker.captureMessage('The request was made but no response was received', {
      tags: {
        type: 'Api Error',
        correlationId: error.config.headers[ApiHeaders.X_CORRELATION_ID],
      },
      extra: {
        errorMessage: error.message,
        method: error.config.method,
        params: error.config.params,
        url: error.config.url,
        timeout: error.config.timeout,
        clientId,
        workstation,
      },
    })
    throw error
  } else {
    // Something happened in setting up the request that triggered an Error
    ErrorTracker.captureException(new Error('Something happened in setting up the request that triggered an Error'), {
      tags: {
        type: 'Api Error',
      },
      extra: {
        error: error?.toJSON(),
        clientId,
        workstation,
      },
    })
    throw error
  }
}

export const ApiHeaders = {
  X_CORRELATION_ID: 'X-Correlation-ID',
  ORIGIN_APP: 'origin-app',
  LANGUAGE_CODE: 'language-code',
}

const attachDefaultInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(async (configuration) => {
    return {
      ...configuration,
      headers: {
        ...configuration.headers,
        [ApiHeaders.X_CORRELATION_ID]: nanoid(),
      },
    }
  })

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      handleError(error)
      throw error
    },
  )
}

export const createAxiosInstance = () => {
  const axiosInstance = Axios.create({
    baseURL: Config.API_DEFAULT_URL,
    timeout: Config.API_TIMEOUT,
    withCredentials: true,
    paramsSerializer: {
      serialize(p) {
        return qs.stringify(p, { arrayFormat: 'repeat' })
      },
    },
  })

  attachDefaultInterceptors(axiosInstance)

  return axiosInstance
}
