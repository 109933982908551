import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'

type Props = {
  onClick: () => void
  children: React.ReactNode
  disabled?: boolean
}

const FieldArrayAddItemButton = ({ children, onClick, disabled }: Props) => {
  return (
    <Box>
      <Button variant='text' color='primary' onClick={onClick} disabled={disabled}>
        <AddIcon sx={{ mr: 1 }} /> <span>{children}</span>
      </Button>
    </Box>
  )
}

export default FieldArrayAddItemButton
