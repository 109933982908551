import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Construction as ConstructionIcon,
  Dashboard as DashboardIcon,
  History as HistoryIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Notifications as NotificationsIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'

import { useMachineContext } from 'Context/MachineContext'
import { useNavigationContext } from 'Context/NavigationContext'
import { useNotificationContext } from 'Context/NotificationContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'

import PlanIcon from '../Icons/PlanIcon'
import NavLinks, { NavLinkItem } from '../NavLinks'

type Props = {
  collapsed: boolean
}

const OperatorPanelLinks = ({ collapsed }: Props) => {
  const { notificationsCount } = useNotificationContext()
  const { disableNavigation } = useNavigationContext()
  const { user } = useUserContext()
  const { variant, order } = useMachineContext()
  const { t } = useTranslation()

  const isSuperOperator = user?.permissions.has('SuperOperator')
  const disableLinks = !user || !variant || !order?.name || disableNavigation

  const navItems: (NavLinkItem | undefined)[] = [
    {
      link: paths.operatorNotifications,
      Icon: NotificationsIcon,
      label: t('labels.notifications'),
      badge: notificationsCount,
      border: true,
    },
    {
      link: paths.operatorDashboard,
      Icon: DashboardIcon,
      label: t('labels.dashboard'),
    },
    {
      link: paths.operatorFiles,
      Icon: InsertDriveFileIcon,
      label: t('labels.files'),
    },
    {
      link: paths.operatorRetool,
      Icon: ConstructionIcon,
      label: t('labels.retool'),
    },
    {
      link: paths.operatorWorkstationHistory,
      Icon: HistoryIcon,
      label: t('labels.workstationHistory'),
    },
    isSuperOperator
      ? {
          link: paths.operatorSettings,
          Icon: SettingsIcon,
          label: t('labels.settings'),
        }
      : undefined,
    {
      link: paths.operatorProductionFlow,
      Icon: PlanIcon,
      label: t('labels.productionFlow'),
    },
    {
      link: paths.operatorMultiOperator,
      Icon: PeopleIcon,
      label: t('labels.multiOperator'),
    },
  ].filter(Boolean)

  return <NavLinks items={navItems as NavLinkItem[]} collapsed={collapsed} disabled={disableLinks} />
}

export default OperatorPanelLinks
