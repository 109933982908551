const Permissions = {
  AdminPanel: 'AdminPanel',
  ForemanDashboard: 'ForemanDashboard',
  ForemanPanel: 'ForemanPanel',
  MechanicPanel: 'MechanicPanel',
  OperatorPanel: 'OperatorPanel',
  PlannerPanel: 'PlannerPanel',
  SuperOperator: 'SuperOperator',
  MaintenanceDashboard: 'URDashboard',
  TechnologistPanel: 'TechnologistPanel',
} as const

type PermissionKeys = keyof typeof Permissions
export type PermissionName = (typeof Permissions)[PermissionKeys]

export const PermissionTranslationKeys = {
  [Permissions.AdminPanel as string]: 'values.adminPanel',
  [Permissions.ForemanDashboard as string]: 'values.foremanDashboard',
  [Permissions.ForemanPanel as string]: 'values.foremanPanel',
  [Permissions.MechanicPanel as string]: 'values.mechanicPanel',
  [Permissions.OperatorPanel as string]: 'values.operatorPanel',
  [Permissions.PlannerPanel as string]: 'values.plannerPanel',
  [Permissions.MaintenanceDashboard as string]: 'values.maintenanceDashboard',
  [Permissions.TechnologistPanel as string]: 'values.technologistPanel',
} as const

export default Permissions
