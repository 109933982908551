import React from 'react'
import { Stack } from '@mui/material'

type Props = {
  children: React.ReactNode
}

const FieldArraySection = ({ children }: Props) => {
  return (
    <Stack spacing={2} useFlexGap>
      {children}
    </Stack>
  )
}

export default FieldArraySection
