import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Box } from '@mui/material'

import Tooltip from 'Components/Tooltip'

const FieldLabelInfoWrapper: React.FC<{ tooltip?: string }> = ({ children, tooltip }) => (
  <Box display='flex' alignItems='center'>
    {children}
    {tooltip && (
      <Tooltip title={tooltip}>
        <InfoIcon color='action' fontSize='small' />
      </Tooltip>
    )}
  </Box>
)

export default FieldLabelInfoWrapper
