import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

const REFRESH_INTERVAL = 60 * 60 * 1000
const INACTIVE_MIN = 10 * 60 * 1000

const AutoRefreshHandler = () => {
  const [lastRefreshedTime] = useState(+new Date())
  const [isActive, setIsActive] = useState(true)

  useIdleTimer({
    timeout: INACTIVE_MIN,
    throttle: 5 * 1000,
    onIdle: () => {
      setIsActive(false)
    },
    onActive: () => {
      setIsActive(true)
    },
  })

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (!isActive) {
      const scheduledReloadDelay = Math.max(lastRefreshedTime + REFRESH_INTERVAL - Date.now(), 0)

      timeout = setTimeout(() => {
        window.location.reload()
      }, scheduledReloadDelay)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [lastRefreshedTime, isActive])

  return null
}

export default AutoRefreshHandler
