import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import HubMethods from 'Consts/HubMethods'
import { useMachineContext } from 'Context/MachineContext'
import { useSignalRContext } from 'Context/SignalRContext'
import paths from 'Routes/paths'

const PairedMachineHandler = () => {
  const { hubConnection } = useSignalRContext()
  const history = useHistory()
  const { setMachine } = useMachineContext()

  useEffect(() => {
    const handleMachineDisconnect = () => {
      setMachine(undefined)

      history.push(paths.workstationDisconnected)
    }

    hubConnection?.on(HubMethods.DisconnectPairedMachine, handleMachineDisconnect)

    return () => hubConnection?.off(HubMethods.DisconnectPairedMachine, handleMachineDisconnect)
  }, [hubConnection])

  return null
}

export default PairedMachineHandler
