import { Paper as MuiPaper } from '@mui/material'
import { styled } from '@mui/material/styles'

const PaperCard = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 632,
  maxWidth: 632,
  padding: theme.spacing(5),
  gap: theme.spacing(4),
  [theme.breakpoints.up('xxl')]: {
    width: 948,
    maxWidth: 948,
    gap: theme.spacing(8),
  },
}))

export default PaperCard
