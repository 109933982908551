import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { errorCodes } from 'Consts/Errors'
import getErrorCode from 'Helpers/ApiErrorCodeGetter'
import useDeleteOrder from 'Hooks/orders/useDeleteOrder'
import useOrderQuery from 'Hooks/orders/useOrderQuery'
import RemoveDialog from 'Layout/Modals/RemoveDialog'

type Props = {
  open: boolean
  onCancel: () => void
  onSuccess: () => void
  id: number
}

const DeleteDialog: React.FC<Props> = ({ open = false, onCancel, onSuccess, id }) => {
  const { data: order, isLoading: isLoadingOrder, isError: isOrderError } = useOrderQuery(id)
  const { mutate: deleteOrder, isLoading: isDeleting } = useDeleteOrder()
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string | null>(null)
  const [notFoundMessage, setNotFoundMessage] = useState<string | null>(null)
  const { t } = useTranslation()

  const handleDelete = () => {
    deleteOrder(id, {
      onSuccess,
      onError: (error: unknown) => {
        if (getErrorCode(error) === errorCodes.entityNotFound) {
          setNotFoundMessage(t('messages.orderNotExist'))
        } else {
          setDeleteErrorMessage(t('messages.sorryThereWasAProblemWithYourRequest'))
        }
      },
    })
  }

  return (
    <RemoveDialog
      open={open}
      onCancel={onCancel}
      onConfirm={handleDelete}
      title={t('labels.confirmTheRemovalOfTheOrder')}
      confirmButtonLabel={t('labels.deleteOrder')}
      isItemLoading={isLoadingOrder}
      notFoundMessage={isOrderError ? t('messages.orderNotExist') : notFoundMessage}
      item={order}
      isDeleting={isDeleting}
      deleteErrorMessage={deleteErrorMessage}
    />
  )
}

export default DeleteDialog
