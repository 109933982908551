import React, { createContext, useContext, useMemo, useState } from 'react'

interface NavigationContext {
  disableNavigation: boolean
  setDisableNavigation: React.Dispatch<React.SetStateAction<boolean>>
}

const NavigationContext = createContext<NavigationContext | undefined>(undefined)

export const useNavigationContext = (): NavigationContext => {
  const context = useContext(NavigationContext)

  if (context === undefined) {
    throw new Error('useNavigationContext must be used within a NavigationContextProvider')
  }

  return context
}

export const NavigationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [disableNavigation, setDisableNavigation] = useState(false)

  const value: NavigationContext = useMemo(() => ({ disableNavigation, setDisableNavigation }), [disableNavigation])

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}
