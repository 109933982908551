import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoOutlined } from '@mui/icons-material'
import { Button, Dialog, Typography } from '@mui/material'

import { DialogActions, DialogContent, DialogTitle, DialogTitleWithIcon } from 'Components/Dialog'

interface UpgradeDialogProps {
  open: boolean
  onSubmit: () => void
}

export const UpgradeDialog: React.FC<UpgradeDialogProps> = ({ open, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          minWidth: { sx: '39.5rem', xxl: '50rem' },
        },
      }}
    >
      <DialogTitle disableTypography>
        <DialogTitleWithIcon Icon={InfoOutlined} IconProps={{ color: 'info', sx: { fontSize: 65 } }}>
          <Typography component='span' fontWeight='medium' fontSize='1.5rem' align='center'>
            {t('messages.newVersionOfTheApplicationIsAvailable')}
          </Typography>
        </DialogTitleWithIcon>
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={{ xs: '1rem', xxl: '1.5rem' }} align='center'>
          {t('messages.closeOtherBrowserTabsAndUpdateToContinueUsingIt')}
        </Typography>
      </DialogContent>
      <DialogActions actionsVariant='center'>
        <Button onClick={onSubmit} size='large'>
          {t('labels.downloadUpdate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpgradeDialog
