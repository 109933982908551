import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, styled } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import MuiTableRow, { TableRowProps } from '@mui/material/TableRow'
import { flexRender, getCoreRowModel, Row, TableOptions, useReactTable } from '@tanstack/react-table'

import ZeroState from 'Components/ZeroState'
import { AnyType } from 'Types'

import TableHeaderCell from './TableHeaderCell'

type Props<T extends Record<string, unknown>> = {
  data: T[]
  columns: TableOptions<T>['columns']
  state?: TableOptions<T>['state']
  renderRow?: (row: Row<T>, index: number) => AnyType
  renderNoDataMessage?: () => React.ReactNode
  isLoading?: boolean
}

const TableRow = styled(MuiTableRow, {
  shouldForwardProp: (name) => !(name === 'even' || name === 'showBorder'),
})<{ even: boolean; showBorder?: boolean }>(({ theme, even, showBorder = false }) => ({
  backgroundColor: even ? theme.palette.background.default : theme.palette.background.paper,
  ...(!showBorder && {
    '& > *': {
      borderBottom: 'none !important',
    },
  }),
}))

export const ReactTableRow = <T extends Record<string, unknown>>({
  row,
  rowIndex,
  showBorder,
  even,
  selected,
  onClick,
  sx,
}: {
  row: Row<T>
  rowIndex: number
  showBorder?: boolean
  even?: boolean
  selected?: boolean
  onClick?: () => void
  sx?: TableRowProps['sx']
}) => (
  <TableRow
    hover
    even={typeof even === 'boolean' ? even : rowIndex % 2 === 0}
    showBorder={showBorder}
    tabIndex={-1}
    onClick={onClick}
    selected={selected}
    sx={sx}
  >
    {row.getVisibleCells().map((cell) => {
      return (
        <TableCell key={cell.id} component='div' sx={{ width: cell.column.getSize() }}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      )
    })}
  </TableRow>
)

export const ReactTable = <T extends Record<string, unknown>>({
  columns,
  data,
  state,
  isLoading,
  renderRow,
  renderNoDataMessage,
}: Props<T>) => {
  const { getHeaderGroups, getRowModel } = useReactTable<T>({
    columns,
    data,
    state,
    getCoreRowModel: getCoreRowModel(),
  })
  const { t } = useTranslation()

  const rows = getRowModel().rows

  return (
    <Paper sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ height: '100%', maxHeight: '100%', borderRadius: 4 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            {getHeaderGroups().map((headerGroup) => (
              <MuiTableRow key={headerGroup.id} hover={false} selected={false}>
                {headerGroup.headers.map((header, index) => (
                  <TableHeaderCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{ width: header.getSize(), zIndex: headerGroup.headers.length - index + 1 }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHeaderCell>
                ))}
              </MuiTableRow>
            ))}
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                }}
              >
                <CircularProgress />
              </Box>
            ) : rows.length ? (
              rows.map((row, index) => {
                if (renderRow) {
                  return renderRow(row, index)
                }

                return <ReactTableRow key={row.id} row={row} rowIndex={index} />
              })
            ) : renderNoDataMessage ? (
              renderNoDataMessage()
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                }}
              >
                <ZeroState primaryMessage={t('messages.noDataToBeDisplayed')} />
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ReactTable
