import React from 'react'
import { alpha, Badge, ButtonBase, styled, Typography } from '@mui/material'

const propsToNotForward = ['border', 'selected']

const SideNavItemContainer = styled(ButtonBase, {
  shouldForwardProp: (prop) => !propsToNotForward.includes(prop as string),
})<{ selected?: boolean; disabled?: boolean; border?: 'bottom' | 'top' }>(({ theme, selected, disabled, border }) => ({
  display: 'flex',
  width: '100%',
  height: '64px',
  minHeight: '64px',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexShrink: 0,
  color: 'inherit',
  ...(border === 'top' && {
    borderTop: `1px solid ${theme.palette.primary.contrastText}`,
  }),
  ...(border === 'bottom' && {
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  }),
  ...(selected && {
    background: alpha(theme.palette.primary.contrastText, theme.palette.action.selectedOpacity),
  }),
  ...(disabled && {
    background: alpha(theme.palette.primary.contrastText, theme.palette.action.disabledOpacity),
  }),
  '&:hover': {
    background: alpha(theme.palette.primary.contrastText, theme.palette.action.hoverOpacity),
  },
}))

const SideNavItem = ({
  label,
  Icon,
  badge,
  collapsed,
  onClick,
  disabled,
  selected,
  border,
}: {
  Icon: React.ElementType
  label?: string
  badge?: number | boolean
  collapsed?: boolean
  onClick?: () => void
  disabled?: boolean
  selected?: boolean
  border?: 'bottom' | 'top'
}) => {
  return (
    <SideNavItemContainer onClick={onClick} disabled={disabled} selected={selected} border={border}>
      {badge ? (
        <Badge
          badgeContent={badge}
          color='secondary'
          max={9}
          overlap='circular'
          variant={typeof badge === 'boolean' ? 'dot' : 'standard'}
        >
          <Icon fontSize='small' />
        </Badge>
      ) : (
        <Icon fontSize='small' />
      )}
      {!collapsed && !!label && (
        <Typography
          color='inherit'
          textAlign='left'
          sx={{ flex: '1 0 0', whiteSpace: 'initial', fontSize: '14px !important' }}
          component='span'
        >
          {label}
        </Typography>
      )}
    </SideNavItemContainer>
  )
}

export default SideNavItem
