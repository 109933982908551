import React, { ElementType } from 'react'
import { useTheme } from '@mui/material/styles'
import MuiTypography, { TypographyProps } from '@mui/material/Typography'

interface Props extends TypographyProps {
  children: React.ReactNode
  weight?: 'light' | 'regular' | 'medium' | 'bold'
  textColor?: 'primary' | 'secondary' | 'green' | 'blue' | 'red' | 'orange'
  component?: ElementType
}

const Typography = ({ children, style, weight = 'regular', textColor, ...props }: Props) => {
  const { palette } = useTheme()

  const weights = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  }

  const textColors = {
    primary: palette.text.primary,
    secondary: palette.text.secondary,
    green: palette.success.main,
    blue: palette.info.main,
    red: palette.error.main,
    orange: palette.warning.main,
  }

  return (
    <MuiTypography
      {...props}
      style={{
        fontWeight: weights[weight],
        color: textColor && textColors[textColor],
        ...style,
      }}
    >
      {children}
    </MuiTypography>
  )
}

export default Typography
