import { getI18n } from 'react-i18next'

export function getTechnologyNameWithRevision(technologyName: string, technologyRevision: number) {
  if (technologyRevision) return `${technologyName} (v${technologyRevision})`

  return technologyName
}

export function getIncompleteTechnologyNameWithRevision(technologyName: string, technologyRevision: number) {
  return `${getTechnologyNameWithRevision(technologyName, technologyRevision)} (${getI18n().t('incomplete', {
    context: 'technology',
  })})`
}

export const getIsTechnologyComponentCompletable = ({
  machines,
}: {
  machines: { isActive: boolean; isDeleted: boolean }[]
}) => {
  return machines.some(({ isActive, isDeleted }) => isActive && !isDeleted)
}
