import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import AuthManager from 'Auth/AuthManager'
import { LocalStorageKeys } from 'Consts/LocalStorageKeys'
import { useDialogsContext } from 'Context/DialogsContext'
import { useUser as useUserContext } from 'Context/UserContext'
import paths from 'Routes/paths'

const useLogout = () => {
  const { user } = useUserContext()
  const { setRemoveCardDialogOpen } = useDialogsContext()
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const history = useHistory()

  const handleLogout = () => {
    if (user?.loginMethod === 'rfid') {
      setRemoveCardDialogOpen(true)
    } else {
      setIsLoggingOut(true)

      if (!isLoggingOut) {
        AuthManager.logout()
          .then(() => {
            setIsLoggingOut(false)
            history.push(paths.login)
            localStorage.removeItem(LocalStorageKeys.REDIRECT_PATH)
          })
          .catch(() => setIsLoggingOut(false))
      }
    }
  }

  return {
    isLoggedIn: !!user,
    handleLogout,
  }
}

export default useLogout
