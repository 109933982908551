import React from 'react'
import { Box, Typography } from '@mui/material'

type Props = {
  title: string
  subtitle?: string
}

const FieldArrayHeader = ({ title, subtitle }: Props) => {
  return (
    <Box>
      <Typography variant='subtitle2' fontWeight='bold'>
        {title}
      </Typography>
      {!!subtitle && <Typography>{subtitle}</Typography>}
    </Box>
  )
}

export default FieldArrayHeader
