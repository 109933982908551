import React from 'react'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

import theme from './theme'

function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
